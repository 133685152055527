import { TEXT_STYLE_ORANGE } from "../../../../../../utils/textStyles";
import { countProperties } from "../../../../../../utils/utils";
import Button from "../../../../../main/buttons/common/Button";
import ProductionStatusSectionItem from "./ProductionStatusSectionItem";

export default class ProductionStatusSection extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, width, height, category, data, targetScene) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.width = width;
        this.height = height;
        this.category = category;
        this.itemsData = data;
        this.targetScene = targetScene;

        this.create(width, height);
    }

    create(w, h) {
        this.add(this.cellBg = this.scene.add.image(0, 0, 'UI', 'store_cell.png').setDisplaySize(w, h).setOrigin(0.5, 0.5));

        this.add(this.button = new Button(this.scene, w * -0.185, h * -0.365, [], 'table_button', h * 0.3, h * 0.3, 'green'));
        this.add(this.buildingName = this.scene.add.text(w * -0.47, h * -0.33, this.category).setOrigin(0, 1).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.17));

        this.addItems(this.itemsData);
    }

    addItems(data) {
        this.cells = [];
        let dist = 0;

        if (countProperties(data) > 5) {
            delete data[Object.keys(data)[0]];
        }

        for (let key in data) {
            let cell = new ProductionStatusSectionItem(this.scene, this.width * -0.4595 + dist, this.height * 0.183, [], this.cellBg, key);
            this.cells.push(cell);

            dist += this.width * 0.1958;
        }

        this.add(this.cells);
    }

    update(data) {
        this.cells.forEach(cell => {
            cell.update(data);
        });
    }
}
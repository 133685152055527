import Lightning from "../common/Lightning";
import BattleUnit from "./BattleUnit";

export default class BattleIrradiated extends BattleUnit {

    setUnitData(unitData, linkedIcon) {
        super.setUnitData(unitData, linkedIcon);
        const config = this.getData('config');

        config['attack_frames'] = [4];
        config['weakness'] = [];
    }

    shoot(x, y, dir, target) {
        const w = this.unitSprite.displayWidth;

        const positions = {
            'right': { x: w * 0.3, y: w * -0.15 },
            'left': { x: w * -0.3, y: w * -0.15 },
            'down-r': { x: w * 0.2, y: w * -0.05 },
            'down-l': { x: w * -0.2, y: w * -0.05 },
            'up-r': { x: w * 0.25, y: w * -0.25 },
            'up-l': { x: w * -0.25, y: w * -0.25 },
            'up': { x: 0, y: w * -0.3 },
            'down': { x: 0, y: w * 0.2 },
        };

        const lightning = new Lightning(this.scene, x + positions[dir].x, y + positions[dir].y, 'lightning_strike');
        lightning.create(x, y, target, 1000);
    }
}
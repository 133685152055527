import InfoTables from "../../../../scenes/ui/InfoTables";
import Button from "../common/Button";

export default class FinishBattleButton extends Button {

    click() {
        super.click();
        const data = this.scene.game.registry.get('battle_data');
        const sceneKey = data['data']['data']['scene'];
        const battleScene = this.scene.scene.get(sceneKey);
        const enemies = battleScene.data.get('enemies');
        const extraRewards = battleScene.data.get('extra_rewards');
        const army = data['army'];

        console.log(data);
        // battleScene.anims.pauseAll();
        
        InfoTables.openInfoTable('battle_win', {
            army: army,
            enemies: enemies,
            extra_rewards: extraRewards
        });
    }
}
import HuntingTaskTable from '../tables/tasks/HuntingTaskTable.js';
import ResourceTaskTable from '../tables/tasks/ResourceTaskTable.js';
import SearchTaskTable from '../tables/tasks/SearchTaskTable.js';
import InfoTableContainer from './common/InfoTableContainer.js';


export default class TaskTableContainer extends InfoTableContainer {

    addInfoTables() {
        this.tableLeft = new ResourceTaskTable(this.scene, 0, 0, [], this.w, this.h, 'resource_task');
        this.tableCenter = new HuntingTaskTable(this.scene, 0, 0, [], this.w, this.h, 'hunting_task');
        this.tableRight = new SearchTaskTable(this.scene, 0, 0, [], this.w, this.h, 'search_task');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}

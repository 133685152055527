import BuildingTableContainer from './common/BuildingTableContainer.js';
import HospitalTable from '../tables/buildings/HospitalTable.js';
import HospitalHealingTable from '../tables/buildings/hospital/HospitalHealingTable.js';


export default class HospitalTableContainer extends BuildingTableContainer {

    addInfoTables() {
        this.tableLeft = new HospitalHealingTable(this.scene, 0, 0, [], this.w, this.h, 'hospital_characters');
        this.tableCenter = new HospitalHealingTable(this.scene, 0, 0, [], this.w, this.h, 'hospital_army');
        this.tableRight = new HospitalTable(this.scene, 0, 0, [], this.w, this.h, 'hospital');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}

import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN, TEXT_STYLE_RED } from "../utils/textStyles.js";

export default class UIController {

    // config = {
    //     container: ..., 
    //     itemsData: ..., 
    //     x: ..., 
    //     y: ..., 
    //     iconSize: ...,
    //     fontSize: ...,
    //     space: ...,
    // }
    static arrangeItems(config) {
        const container = config.container;
        const itemsData = config.itemsData;
        const x = config.x;
        const y = config.y;
        const iconSize = config.iconSize;
        const fontSize = config.fontSize;
        const space = config.space;

        const scene = container.scene;
        const iconArray = [];
        const textArray = [];
        let dist = 0;

        for (let key in itemsData) {
            let icon = scene.add.sprite(x + dist, y, 'items_icons', `${key}.png`).setDisplaySize(iconSize, iconSize).setOrigin(1, 0.5);
            container.add(icon);
            iconArray.push(icon);
            textArray.push(scene.add.text(x + dist, y, itemsData[key].toString(), TEXT_STYLE_GREEN).setOrigin(-0.2, 0.5).setFontSize(fontSize));

            dist += space;
        }

        container.itemsIcons = iconArray;
        container.itemsText = textArray;
        container.add(textArray);
    }

    static updateItemsText(items, itemsData, textArray, takeGlobalValue = false) {
        for (let i = 0; i < textArray.length; i++) {
            let key = Object.keys(itemsData)[i];
            let value = itemsData[key];
            let text = takeGlobalValue ? items[key] : value;

            textArray[i].setText(text);

            if (items[key] < value) {
                textArray[i].setStyle(TEXT_STYLE_RED);
            } else {
                textArray[i].setStyle(TEXT_STYLE_GREEN);
            }
        }
    }

}


export class ScrollManager {
    constructor(scene, panelConfig, sizerConfig, fixWidth = true) {
        this.scene = scene;
        this.panelConfig = panelConfig;
        this.sizerConfig = sizerConfig;
        this.scrollPanel = null;
        this.sizer = null;
        this.fixWidth = fixWidth;
        this.items = []; // Нужно для циклов, чтобы перебирать элементы

        this.createScrollPanel();
        this.createMask();
    }

    createScrollPanel() {
        if (this.fixWidth) {
            this.sizer = this.scene.rexUI.add.fixWidthSizer(this.sizerConfig);
        } else {
            this.sizer = this.scene.rexUI.add.sizer(this.sizerConfig);
        }

        this.sizer.setOrigin(0, 0);

        this.defaultPanelConfig = {
            // background: this.scene.rexUI.add.roundRectangle(0, 0, 0, 0, 0, 0x000000, 0.5),

            panel: {
                child: this.sizer,
                mask: false,
            },

            scroller: {
                threshold: 0,
            },

            mouseWheelScroller: {
                focus: true,
                speed: 0.3
            },
        };

        // Конфигурация панели для данного экземпляра ScrollManager
        this.panelConfig = { ...this.panelConfig, ...this.defaultPanelConfig };

        this.scrollPanel = this.scene.rexUI.add.scrollablePanel(this.panelConfig);
        this.scene.input.topOnly = false;

        this.scrollPanel.setChildrenInteractive();
    }

    sort() {
        this.sizer.layout();
        this.scrollPanel.layout();
    }

    addItem(item) {
        this.items.push(item);
        this.sizer.add(item);
        this.sizer.layout();
        this.scrollPanel.layout();
    }

    addMany(items) {
        items.forEach(item => {
            this.items.push(item);
            this.sizer.add(item);
        });
        this.sizer.layout();
        this.scrollPanel.layout();
    }

    getItem(index) {
        return this.sizer.children[index];
    }

    getAll() {
        return this.items;
    }

    removeItems() {
        this.sizer.removeAll(true);
    }

    deselectAll() {
        this.sizer.children.forEach(function (child) {
            child.deselect();
        });
    }

    createMask() {
        const scene = this.scene;
        scene.maskGraphics = scene.maskGraphics ?? this.scene.add.graphics();
        
        const panel = this.scrollPanel;
        const shape = this.scene.add.rectangle(panel.x, panel.y, panel.minWidth, panel.minHeight, 0x000000, 0);

        // Применяем маску к графическому объекту
        this.scrollPanel.setMask(shape.createGeometryMask());

        this.updateMask(panel, shape);
        this.scene.scale.on('resize', () => this.updateMask(panel, shape));
    }

    updateMask(panel, shape) {
        if (panel.parentContainer) {
            const parent = panel.parentContainer;
            shape.setPosition(parent.x + panel.x + window.innerWidth / 2, parent.y + panel.y + window.innerHeight * 0.52);
        }
        
        shape.setPosition(panel.x + window.innerWidth / 2, panel.y + window.innerHeight * 0.52);
    }
}


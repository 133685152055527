import OreMiningStatsTable from "../tables/production/OreMiningStatsTable.js";
import ProductionStatusTable from "../tables/production/ProductionStatusTable.js";
import StoreTable from "../tables/store/StoreTable.js";
import InfoTableContainer from "./common/InfoTableContainer.js";


export default class ProductionStatsTableContainer extends InfoTableContainer {

    addInfoTables() {
        this.tableLeft = new OreMiningStatsTable(this.scene, 0, 0, [], this.w, this.h, 'ore_mining');
        this.tableCenter = new ProductionStatusTable(this.scene, 0, 0, [], this.w, this.h, 'production_status');
        this.tableRight = new StoreTable(this.scene, 0, 0, [], this.w, this.h, 'main_store');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}
import UIController, { ScrollManager } from '../../../../../managers/UIController.js';
import { TEXT_STYLE_BLUE, TEXT_STYLE_ORANGE, TEXT_STYLE_GREEN } from '../../../../../utils/textStyles.js';
import SingleInfoTable from '../common/SingleInfoTable.js';
import Button from '../../../../main/buttons/common/Button.js';
import apiClient from '../../../../../services/apiClient.js';
import ProductionStatusSection from './components/ProductionStatusSection.js';


export default class ProductionStatusTable extends SingleInfoTable {

    addInfo(w, h) {
        this.arrangeCells(w, h);
    }

    async arrangeCells(w, h) {
        const scrollW = w * 0.65;
        const scrollH = h * 0.69;
        const spaceTop = h * 0.03;

        this.scroll = new ScrollManager(this.scene, {
            x: 0,
            y: h * -0.033,
            width: scrollW,
            height: scrollH,

            scrollMode: 0,

            space: {
                panel: {
                    top: spaceTop,
                    bottom: spaceTop,
                }
            }
        }, {
            space: {
                line: spaceTop,
            },
        });

        this.addSections(w, h);
        this.add(this.scroll.scrollPanel);
    }

    async update(category) {
        super.update(category);

        if (!this.sectionsAdded) {
            const data = await apiClient.items.store();
            console.log(data);

            this.itemsData = data['data'];
            this.addInfo(this.width, this.height);
        }

        this.updateInfo();
    }

    addSections(w, h) {
        const data = this.itemsData;

        for (let key in data['items']) {
            if (key != 'spaceship') {
                let curScene = '';

                for (let sceneKey in data['buildings']) {
                    if (data['buildings'][sceneKey][key]) {
                        curScene = sceneKey;
                    }
                }

                let section = new ProductionStatusSection(this.scene, 0, 0, [], w * 0.647, w * 0.073, data['buildings'][curScene][key]['name'], data['items'][key], curScene);
                this.scroll.addItem(section);
            }
        }

        this.sectionsAdded = true;
    }

    async updateInfo() {
        const data = await apiClient.processes.getAll();

        for (let item of this.scroll.items) {
            item.update(data);
        }
    }
}

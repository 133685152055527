export function countProperties(obj) {
    return Object.keys(obj).length;
}

export function multiplyObjectValues(obj, multiplier) {
    let newObj = {};

    for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            newObj[prop] = obj[prop] * multiplier;
        }
    }

    return newObj;
}

export function setImageSizeByWidth(image, width) {
    const displayW = width;
    const aspectRatio = image.height / image.width;
    image.setDisplaySize(displayW, displayW * aspectRatio);
}

export function setImageSizeByHeight(image, height) {
    const displayH = height;
    const aspectRatio = image.width / image.height;
    image.setDisplaySize(displayH * aspectRatio, displayH);
}

export function getDistance(x1, y1, x2, y2) {
    let dx = x1 - x2;
    let dy = y1 - y2;
    return Math.sqrt(dx * dx + dy * dy).toFixed();
}

export function isPointInMatrix(matrix, x, y) {
    if (x >= 0 && y >= 0 && x < matrix[0].length && y < matrix.length) {
        return true;
    }
    return false;
}

export function getRandomArrayElement(array) {
    return array[Math.floor(Math.random() * array.length)];
}

export function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function callWithProbability(probability, callback) {
    const randomNum = Math.random() * 100;

    if (randomNum <= probability) {
        callback();
    }
}

export function getObjectValue(obj, targetKey) {
    for (let key in obj) {
        if (key === targetKey) {
            return obj[key];
        }
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            let result = getObjectValue(obj[key], targetKey);
            if (result !== undefined) {
                return result;
            }
        }
    }
    return undefined;
}

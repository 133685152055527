export default class GameMap extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, texture, width, height) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene.mapW = width;
        this.scene.mapH = height;
        this.scene = scene;
        this.width = width;
        this.height = height;

        // Карта, разделенная на части
        const map = scene.make.tilemap({ key: texture + '_map' });
        const tileset = map.addTilesetImage('map', texture + '_tiles');

        // Слой карты, который будем перемещать
        this.add(this.layer = map.createLayer('map_layer', tileset));
        this.layer.setDisplaySize(width, height).setPosition(x, y);
        this.layer.setInteractive();

        // Размеры контейнера карты
        this.setSize(this.layer.displayWidth * 2, this.layer.displayHeight * 2);

        // Перетаскивание карты
        this.handleMoves();
    }

    handleMoves() {
        const layer = this.layer;
        const scene = this.scene;

        scene.input.on('pointerdown', (pointer) => {
            const pX = pointer.x - this.x;
            const pY = pointer.y - this.y;

            scene.input.off('pointermove').on('pointermove', (pointer) => {
                scene.input.setDefaultCursor('url(assets/UI/ui/cursors/drag.png), pointer');
                scene.input.off('pointerup').on('pointerup', handlePointerRelease);

                layer.x = pointer.x - pX;
                layer.y = pointer.y - pY;
                this.x = pointer.x - pX;
                this.y = pointer.y - pY;

                if (this.x >= 0) {
                    this.x = 0;
                    layer.x = 0;
                }
                if (this.x <= window.innerWidth - this.width / 2) {
                    this.x = window.innerWidth - this.width / 2;
                    layer.x = window.innerWidth - this.width / 2;
                }
                if (this.y >= 0) {
                    this.y = 0;
                    layer.y = 0;
                }
                if (this.y <= window.innerHeight - this.height / 2) {
                    this.y = window.innerHeight - this.height / 2;
                    layer.y = window.innerHeight - this.height / 2;
                }
            });

            scene.input.once('pointerup', this.click, this);
            scene.input.once('gameout', handlePointerRelease);
        });

        const handlePointerRelease = () => {
            scene.input.off('pointermove');
            scene.input.setDefaultCursor('url(assets/UI/ui/cursors/default.png), pointer');
        }
    }

    click(pointer) {
        this.clickX = pointer.x;
        this.clickY = pointer.y;
        this.emit('click', this);
    }
}
const animalData = {
    'zazulic': {
        category: 'animal',
        xp_cost: 2,
        size: 0.017,
        name: 'zazulic',
        health: 150,
        max_health: 150,
        armor: 10,
        damage: 25,
        close_damage: 0,
        accuracy: 90,
        speed: 12,
        range: 1,
    },
    'zazulic_mini': {
        xp_cost: 1,
        category: 'animal',
        size: 0.015,
        name: 'zazulic_mini',
        health: 75,
        max_health: 75,
        armor: 10,
        damage: 15,
        close_damage: 0,
        accuracy: 90,
        speed: 12,
        range: 1,
    },
    'zazulic_soldier': {
        xp_cost: 3,
        category: 'animal',
        size: 0.019,
        name: 'zazulic_soldier',
        health: 250,
        max_health: 250,
        armor: 25,
        damage: 50,
        close_damage: 0,
        accuracy: 90,
        speed: 12,
        range: 1,
    },
    'zazulic_sand': {
        xp_cost: 3,
        category: 'animal',
        size: 0.018,
        name: 'zazulic_sand',
        health: 250,
        max_health: 250,
        armor: 25,
        damage: 50,
        close_damage: 0,
        accuracy: 90,
        speed: 12,
        range: 1,
    },
    'zazulic_sand_soldier': {
        xp_cost: 4,
        category: 'animal',
        size: 0.019,
        name: 'zazulic_sand_soldier',
        health: 250,
        max_health: 250,
        armor: 25,
        damage: 50,
        close_damage: 0,
        accuracy: 90,
        speed: 12,
        range: 1,
    },
    
    'pichmog': {
        xp_cost: 5,
        category: 'animal',
        size: 0.017,
        name: 'pichmog',
        health: 500,
        max_health: 500,
        armor: 50,
        damage: 100,
        close_damage: 0,
        accuracy: 90,
        speed: 5,
        range: 1,
    },
    
    'vugda': {
        xp_cost: 7,
        category: 'animal',
        size: 0.016,
        name: 'vugda',
        health: 300,
        max_health: 300,
        armor: 50,
        damage: 50,
        close_damage: 0,
        accuracy: 50,
        speed: 12,
        range: 7,
    },
    'vugda_mini': {
        xp_cost: 4,
        category: 'animal',
        size: 0.013,
        name: 'vugda_mini',
        health: 150,
        max_health: 150,
        armor: 20,
        damage: 20,
        close_damage: 0,
        accuracy: 30,
        speed: 12,
        range: 7,
    },
    'vugda_female': {
        xp_cost: 6,
        category: 'animal',
        size: 0.015,
        name: 'vugda_female',
        health: 250,
        max_health: 250,
        armor: 45,
        damage: 45,
        close_damage: 0,
        accuracy: 45,
        speed: 12,
        range: 7,
    },
    
    'beles': {
        xp_cost: 10,
        category: 'animal',
        size: 0.02,
        name: 'beles',
        health: 400,
        max_health: 400,
        armor: 60,
        damage: 100,
        close_damage: 0,
        accuracy: 90,
        speed: 17,
        range: 1,
    },
    'beles_mini': {
        xp_cost: 6,
        category: 'animal',
        size: 0.016,
        name: 'beles_mini',
        health: 200,
        max_health: 200,
        armor: 30,
        damage: 50,
        close_damage: 0,
        accuracy: 90,
        speed: 17,
        range: 1,
    },
    'beles_female': {
        xp_cost: 8,
        category: 'animal',
        size: 0.018,
        name: 'beles_female',
        health: 350,
        max_health: 350,
        armor: 50,
        damage: 80,
        close_damage: 0,
        accuracy: 90,
        speed: 17,
        range: 1,
    },
    
    'zoris': {
        category: 'animal',
        name: 'zoris',
        health: 3000,
        max_health: 3000,
        armor: 60,
        damage: 250,
        close_damage: 0,
        accuracy: 90,
        speed: 0,
        range: 3,
    },
    
    'mutant': {
        xp_cost: 15,
        size: 0.017,
    },
    
    'Chlapidol': {
        xp_cost: 25,
        size: 0.017,
    },
    
    'irradiated': {
        xp_cost: 20,
        size: 0.017,
    },
}

export default animalData;
import MapScene from '../core/mapScene.js';

export default class SiliconQuarry extends MapScene {
    constructor() {
        super('Silicon_Quarry');
    }

    loadAssets(data) {
        // // Оружейный завод
        // this.load.image('weapons_factory', './scenes/Silicon_Quarry/buildings/weapons_factory/1.png');
        // // Обломки
        // this.load.image('wreckage', './scenes/Silicon_Quarry/buildings/wreckage.png');
        
        super.loadAssets(data);
    }
}

import loadAssets from "../../../../../../../../../managers/AssetManager";
import { TEXT_STYLE_BLUE } from "../../../../../../../../../utils/textStyles";
import UnitMiniTable from "./UnitMiniTable";

export default class HeroMiniTable extends UnitMiniTable {

    async create(w, h) {
        await super.create(w, h);

        this.add(this.name = this.scene.add.text(w / 2, h * 0.05, '-')
            .setOrigin(0.5, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.07));
        
        this.unitImage.x = w * 0.53;

        this.bg.setTexture('hero_mini_table');
    }

    async loadData() {
        await loadAssets(this.scene, 'image', 'hero_mini_table',
            `UI/info_tables/common/units/mini_tables/hero_mini_table.png`);
    }

    updateContent(unitData, data) {
        super.updateContent(unitData, data);

        this.unitImage.setTexture('full_body_hero', `${data['key']}.png`);

        this.name.setText(unitData['name']);
        this.setUnitSize();
    }
}
import MoveTo from 'phaser3-rex-plugins/plugins/moveto.js';

export default class Bullet extends Phaser.GameObjects.Sprite {
    constructor(scene, x, y, texture, frame) {
        super(scene, x, y, texture, frame);
        scene.add.existing(this);

        this.scene = scene;
        this.textureKey = texture;
        this.frameKey = frame;

        this.moveToPlugin = new MoveTo(this, { speed: 3000 });
    }

    create(x, y, targetX, targetY) {
        const h = window.screen.height;
        let rotation = Math.atan((targetY - y) / (targetX - x));

        if (this.frameKey == 'balls_bullet.png') {
            rotation = Math.atan((targetY - y) / (targetX - x)) + Math.PI / 2;
        }

        this.setDisplaySize(h * 0.02, h * 0.01).setRotation(rotation);

        if (this.textureKey == 'flying_net') {
            this.setDisplaySize(h * 0.02, h * 0.02);
        }

        this.scene.map.addAt(this, 1);
    }

    move(x, y) {
        this.moveToPlugin.moveTo(x, y);
        this.moveToPlugin.once('complete', () => this.destroy());
    }
}
import SceneManager from "../../../managers/SceneManager";
import InfoTables from "../../../scenes/ui/InfoTables";
import apiClient from "../../../services/apiClient";
import MapObject from "./MapObject";

export class SceneObject extends MapObject {
    constructor(scene, x, y, children, texture, width, height, data) {
        super(scene, x, y, children, texture, width, height, data);

        this.targetScene = data['scene'];
    }

    create() {
        this.type = 'scene';
        super.create();
    }

    async checkAvailable() {
        const data = await apiClient.buildings.checkAvailable({ name: this.texture });

        if (data['status'] != 'success') {
            return;
        }
        return data['available'];
    }

    async click() {
        // const isAvailable = await this.checkAvailable();

        // if (!isAvailable) {
        //     InfoTables.showWarningText('Уничтожьте врагов на пути к объекту');
        //     return;
        // }

        super.click();
        // UI.playSound('click', 'scene');
        SceneManager.loadScene(this.scene, this.targetScene);
    }

    // Scene_Sound() {
    //     switch(this.scene_name) {
    //         case 'Krychon_Cave':
    //             game.stones_click.play();
    //             SetMusic(game.Cave_Music);
    //             break;
    //         case 'Clan_Hall':
    //             game.portal_click.play({volume: 1});
    //             break;
    //         case 'Spaceship':
    //             game.spaceship_click.play({volume: 1});
    //             break;
    //         default:
    //             game.stones_click.play();
    //             break;
    //     }
    // }

    addLink() {
        super.addLink();
        this.setLinkColor();
    }

    setLinkColor() {
        switch (this.name) {

            case 'Пещера Крыхона':
                this.link.link.setTint(0xffd59c);
                this.link.name.setStyle({ color: '#fff49f' });
                break;

            case 'Портал':
                this.link.link.setTint(0xe67cff);
                this.link.name.setStyle({ color: '#f8c1ff' });
                break;

            case 'Северная граница':
            case 'Восточная граница':
            case 'Кремниевый карьер':
            case 'Нора рогоноры':
                if (this.scene.scene.key != 'East_Barrier') {
                    this.link.flip();
                }
                this.link.link.setTint(0x9cff9c);
                break;

            default:
                this.link.link.setTint(0x9cff9c);
                break;
        }
    }
}
import GameScene from '../core/gameScene.js';
import ArmyBattleUI from '../../modules/ui/battle/ArmyBattleUI.js';
import PlayerBattleUI from '../../modules/ui/battle/PlayerBattleUI.js';
import BattleSettingsContainer from '../../modules/ui/battle/BattleSettingsContainer.js';
import AudioManager from '../../managers/AudioManager.js';
import AnimationManager from '../../managers/AnimationManager.js';


export default class BattleUI extends GameScene {
    constructor() {
        super({ key: 'BattleUI' });
        BattleUI.instance = this;
    }

    preload() {
        super.preload();
        this.scene.bringToTop();

        const battleData = this.game.registry.get('battle_data');
        this.battleScene = battleData['data']['data']['scene'];

        // Данные боя
        this.data.set('battle_data', battleData);
        // ---------/

        this.loadAnimations();
    }

    loadAnimations() {
        const army = this.data.get('battle_data')['army'];

        // Загрузка необходимых для боя анимаций
        for (let key in army) {
            army[key].units.forEach(unit => {

                let data = unit.unitData;
                let category = data['category'];

                switch (category) {
                    case 'commander':
                    case 'character':
                        AnimationManager.load(this, 'units', 'character', 256, { name: data['name'] });
                        this.load.image('flying_net', 'anims/effects/net.png');
                        this.load.image('cover_net', 'anims/effects/cover_net.png');
                        this.load.image('burner_flame', 'anims/effects/flame.png');
                        break;
                    case 'soldier':
                        AnimationManager.load(this, 'units', 'soldier', 256, { rank: data['rank'] });
                        break;
                    case 'robot':
                        AnimationManager.load(this, 'units', 'character', 256, { name: data['name'] });
                        break;
                    case 'mutant':
                        AnimationManager.load(this, 'units', 'mutant', 256, { extraAnims: ['eat'] });
                        break;
                    case 'irradiated':
                        AnimationManager.load(this, 'units', 'irradiated', 256);
                        AnimationManager.load(this, 'effects', 'lightning_strike', 256);
                        break;
                }
            });
        }
    }

    create() {
        super.create();
        this.scene.sleep('UI');
        this.scene.launch(this.battleScene);
        this.scene.bringToTop(this.battleScene);

        AudioManager.setMusic('battle');

        this.addUI();
        this.setContentPosition();

        this.scale.on('resize', () => {
            this.setContentPosition();
        });

        // Создание анимаций для юнитов
        this.createAnimations();
    }

    update() {
        BattleUI.settings.update();
    }

    addUI() {
        // Интерфейс армии (нижний)
        BattleUI.mainArmyUI = new ArmyBattleUI(this, 0, 0, [], 'main');
        BattleUI.extraArmyUI = new ArmyBattleUI(this, 0, 0, [], 'extra');

        BattleUI.mainArmyUI.setLinkedUI(BattleUI.extraArmyUI);
        BattleUI.extraArmyUI.setLinkedUI(BattleUI.mainArmyUI);

        // Интерфейс игрока (верхний)
        BattleUI.playerUI = new PlayerBattleUI(this, 0, 0, []);
        BattleUI.enemyUI = new PlayerBattleUI(this, 0, 0, [], 'enemy');

        // Кнопки настроек
        BattleUI.settings = new BattleSettingsContainer(this, 0, 0, []);
    }

    setContentPosition() {
        const windowW = window.innerWidth;
        const windowH = window.innerHeight;

        BattleUI.mainArmyUI.setPosition(0, windowH);
        BattleUI.extraArmyUI.setPosition(windowW, windowH);

        BattleUI.playerUI.setPosition(0, 0);
        BattleUI.playerUI.update();
        BattleUI.enemyUI.setPosition(windowW, 0);
        BattleUI.enemyUI.update();

        BattleUI.settings.setPosition(windowW / 2, windowH * 0.01);
    }

    createAnimations() {
        const army = this.data.get('battle_data')['army'];

        for (let key in army) {
            army[key].units.forEach(unit => {

                let data = unit.unitData;
                let category = data['category'];

                switch (category) {
                    case 'commander':
                    case 'character':
                        switch (data['name']) {
                            case 'main_hero':
                                AnimationManager.create(this, 'main_hero', 'idle', 8, 10, 2, -1);
                                AnimationManager.create(this, 'main_hero', 'walk', 8, 10, 10, -1);
                                AnimationManager.create(this, 'main_hero', 'attack', 8, 10, 5, -1);
                                AnimationManager.create(this, 'main_hero', 'death', 8, 10, 5, false);
                                AnimationManager.create(this, 'main_hero', 'close_fight_1', 8, 10, 7, -1);
                                AnimationManager.create(this, 'main_hero', 'close_fight_2', 8, 10, 7, -1);
                                AnimationManager.create(this, 'main_hero', 'close_fight_3', 8, 10, 7, -1);
                                break;
                            case 'TechNastya':
                                AnimationManager.create(this, 'TechNastya', 'idle', 8, 10, 2, -1);
                                AnimationManager.create(this, 'TechNastya', 'walk', 8, 10, 10, -1);
                                AnimationManager.create(this, 'TechNastya', 'attack', 8, 10, 7, -1);
                                AnimationManager.create(this, 'TechNastya', 'death', 8, 10, 7, false);
                                AnimationManager.create(this, 'TechNastya', 'open', 8, 10, 3, -1);
                                AnimationManager.create(this, 'TechNastya', 'extract', 8, 10, 7, -1);
                                AnimationManager.create(this, 'TechNastya', 'search', 8, 10, 4, false);
                                AnimationManager.create(this, 'TechNastya', 'lift', 8, 10, 7, false);
                                break;
                            case 'Chlapidol':
                                AnimationManager.create(this, 'Chlapidol', 'idle', 8, 10, 2, -1);
                                AnimationManager.create(this, 'Chlapidol', 'walk', 8, 10, 10, -1);
                                AnimationManager.create(this, 'Chlapidol', 'attack', 8, 10, 7, -1);
                                AnimationManager.create(this, 'Chlapidol', 'death', 8, 10, 7, false);
                                AnimationManager.create(this, 'Chlapidol', 'butcher', 8, 10, 7, -1);
                                break;
                            case 'Shtryzik':
                                AnimationManager.create(this, 'Shtryzik', 'idle', 8, 10, 2, -1);
                                AnimationManager.create(this, 'Shtryzik', 'walk', 8, 10, 10, -1);
                                AnimationManager.create(this, 'Shtryzik', 'attack', 8, 10, 7, -1);
                                AnimationManager.create(this, 'Shtryzik', 'death', 8, 10, 7, false);
                                AnimationManager.create(this, 'Shtryzik', 'mine', 8, 10, 7, -1);
                                break;
                        }
                        break;
                    case 'soldier':
                        let rank = data['rank'];
                        let rate = 5;
                        switch (rank) {
                            case 3.1:
                            case 3.2:
                                rate = 8;
                                break;
                            case 5.1:
                            case 5.2:
                                rate = 12;
                                break;
                            case 7.1:
                            case 7.2:
                                rate = 50;
                                break;
                        }
                        AnimationManager.create(this, `soldier_${rank}`, 'idle', 4, 10, 2, -1);
                        AnimationManager.create(this, `soldier_${rank}`, 'walk', 8, 10, 10, -1);
                        AnimationManager.create(this, `soldier_${rank}`, 'attack', 8, Number.isInteger(rank) ? 5 : 10, rate, -1);
                        AnimationManager.create(this, `soldier_${rank}`, 'death', 8, 10, 5, false);
                        break;
                    case 'mutant':
                        AnimationManager.create(this, 'mutant', 'idle', 8, 10, 5, -1);
                        AnimationManager.create(this, 'mutant', 'walk', 8, 10, 9, -1);
                        AnimationManager.create(this, 'mutant', 'attack', 8, 10, 4, -1);
                        AnimationManager.create(this, 'mutant', 'death', 8, 10, 7, false);
                        AnimationManager.create(this, 'mutant', 'close_fight', 8, 10, 8, -1);
                        AnimationManager.create(this, 'mutant', 'eat', 8, 10, 8, -1);
                        break;
                    case 'irradiated':
                        AnimationManager.create(this, 'irradiated', 'idle', 8, 10, 5, -1);
                        AnimationManager.create(this, 'irradiated', 'walk', 8, 10, 10, -1);
                        AnimationManager.create(this, 'irradiated', 'attack', 8, 10, 5, -1);
                        AnimationManager.create(this, 'irradiated', 'death', 8, 10, 5, false);
                        AnimationManager.createSingleAnimation(this, 'lightning_strike', 'lightning_strike', { start: 0, end: 9 }, 10, -1);
                        break;
                    case 'robot':
                        AnimationManager.create(this, 'Phaser', 'idle', 4, 20, 6, -1); // should be 18 frames
                        AnimationManager.create(this, 'Phaser', 'walk', 8, 10, 10, -1);
                        AnimationManager.create(this, 'Phaser', 'attack', 8, 10, 5, -1);
                        AnimationManager.create(this, 'Phaser', 'death', 8, 10, 5, false);
                        break;
                }
            });
        }
    }
}

import { ScrollManager } from "../../../../managers/UIController";

export default class InfoTableMainScroll extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tableW, tableH, populateCallback, options = {}) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.tableW = tableW;
        this.tableH = tableH;
        this.populateCallback = populateCallback;
        this.options = options;

        this.create(this.tableW, this.tableH);
    }

    create(w, h) {
        const scrollW = this.options.width || w * 0.65;
        const scrollH = this.options.height || h * 0.1;
        const sideSpace = this.options.space || h * 0.03;

        this.scroll = new ScrollManager(this.scene, {
            x: this.options.x || 0,
            y: this.options.y || h * 0.372,
            width: scrollW,
            height: scrollH,

            scrollMode: this.options.mode || 'horizontal',
        }, {
            orientation: 0,
            space: {
                top: this.options.top || 0,
                bottom: this.options.bottom || 0,
                item: sideSpace,
                line: sideSpace,
            },
        }, this.options.fixWidth || false);

        this.add(this.scroll.scrollPanel);

        if (this.options.type && this.options.type == 'custom') {
            return;
        }

        const mask = this.scene.infoTableMainScrollMask.createBitmapMask();
        this.scroll.scrollPanel.setMask(mask);
    }

    sort() {
        this.scroll.sort();
    }

    updateContent(data) {
        this.clear();
        if (typeof this.populateCallback === 'function') {
            this.populateCallback(data);
        }
    }

    addItem(item) {
        this.scroll.addItem(item);
    }

    addMany(array) {
        this.scroll.addMany(array);
    }

    getItem(index) {
        return this.scroll.getItem(index);
    }

    getAll() {
        return this.scroll.getAll();
    }

    deselectAll() {
        this.scroll.deselectAll();
    }

    clear() {
        this.scroll.removeItems();
    }
}
import TimeManager from "../../../../../../managers/TimeManager";
import UIController from "../../../../../../managers/UIController";
import InfoTables from "../../../../../../scenes/ui/InfoTables";
import { updateUI } from "../../../../../../scenes/ui/UI";
import apiClient from "../../../../../../services/apiClient";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN } from "../../../../../../utils/textStyles";
import ActionButton from "../../../../../main/buttons/common/ActionButton";


export default class BuildingConstructionCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, width, height, buildingName, buildingData, constructionData) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.width = width;
        this.height = height;

        this.buildingName = buildingName;
        this.buildingData = buildingData;
        this.constructionData = constructionData;

        this.resText = [];

        this.create(width, height);
    }

    create(w, h) {
        this.add(this.cell = this.scene.add.image(0, 0, 'UI', 'prod_cell.png').setDisplaySize(w, h));
        this.add(this.button = new ActionButton(this.scene, w * 0.432, 0, [], 'table_button', h * 0.55, h * 0.55, 'green', this.construct.bind(this)));

        this.addInfo(w, h);
    }

    addInfo(w, h) {
        this.add(this.itemIcon = this.scene.add.sprite(w * -0.38, 0, 'items_icons', `${this.buildingName}.png`)
            .setDisplaySize(h * 0.5, h * 0.5));

        UIController.arrangeItems({
            container: this,
            itemsData: this.buildingData['material'],
            x: w * -0.19,
            y: h * 0.22,
            iconSize: h * 0.3,
            fontSize: h * 0.17,
            space: w * 0.2,
        });

        this.add(this.timeText = this.scene.add.text(w * 0.34, h * -0.18, '3:00').setOrigin(1, 0.5));
        this.updateText();
    }

    updateText() {
        const fontSize = this.height * 0.19;

        let items = this.scene.game.registry.get('items');
        UIController.updateItemsText(items, this.buildingData['material'], this.itemsText);

        const duration = this.buildingData['building_time'];
        this.timeText.setText(TimeManager.formatFullTime(duration));
        this.timeText.setStyle(this.isProducing ? TEXT_STYLE_GREEN : TEXT_STYLE_BLUE).setFontSize(fontSize);
    }

    deactivate() {
        this.setAlpha(0.3);
        this.button.disable();
    }

    activate() {
        this.setAlpha(1);
        this.button.makeInteractive();
    }

    async construct() {
        const building = this.scene.game.registry.get('curBuilding');

        if (building.getProcessFromStack(this.buildingName)) {
            this.finishByMorion();
            return;
        };

        console.log(this.buildingName, this.buildingData);

        const data = await apiClient.processes.start({
            category: 'building_construction',
            name: this.buildingName,
            building_name: this.buildingName,
            item_amount: 1
        });
        console.log(data);

        if (data['msg'] == '200') {
            updateUI(this.scene);
            this.startBuilding(data['data']);
            // building.startBuilding(data['data'], null);
        } else {
            InfoTables.showWarningText(data['msg']);
        }
    }

    // data - информация о процессе производства
    startBuilding(data) {
        this.isProducing = true;

        this.button.makeInteractive();
        this.timeText.setStyle(TEXT_STYLE_GREEN);

        TimeManager.createTimer(this.scene, this.buildingName, data['duration'],
            this.finishBuilding.bind(this),
            (remainingTime) => {
                this.timeText.setText(TimeManager.formatFullTime(remainingTime));
            }
        );

        const building = this.scene.game.registry.get('curBuilding');
        building.addProcessToStack(data);
    }

    async finishBuilding() {
        const data = await apiClient.processes.finish({ category: 'building_construction', name: this.buildingName });
        console.log(data);
        console.log('process finished by timer');
        this.reset();
    }

    async finishByMorion() {
        const data = await apiClient.processes.finishByMorion({
            category: 'building_construction',
            name: this.buildingName,
        });

        if (data['status'] != 'success') {
            return;
        }

        InfoTables.showWarningText(data['message']);

        const building = this.scene.game.registry.get('curBuilding');
        const processIcon = building.getProcessFromStack(this.buildingName);
        building.removeProcessFromStack(processIcon);
        this.reset();
    }

    reset() {
        this.isProducing = false;
        this.button.makeInteractive();
        this.updateText();
        updateUI(this.scene);
    }

    checkProcessExists(processes) {
        const process = processes[this.buildingName];
        const name = this.buildingName;
        const data = (this.buildingData['type'] == 'building') ? this.constructionData['buildings'] : this.constructionData['barriers'];

        if (process) {
            if (process['finished'] == true) {
                console.log('process finished by update');
            } else {
                this.startBuilding(process);
            }
        } else {
            let condition;
            const building = data.find(building => building['name'] == name);

            switch (this.buildingData['type']) {
                case 'building':
                    condition = building['level'] > 0;
                    break;
                case 'barrier':
                    condition = building['opened'] == true;
                    break;
            }

            if (condition) {
                this.showAsBuilt();
            }
        }
    }

    showAsBuilt() {
        this.timeText.setText('Построено').setColor('#9B9B9B');
        this.button.disable();
        this.cell.setAlpha(0.7);
    }

    update() {
        const building = this.scene.game.registry.get('curBuilding');
        this.deactivate();

        if (building.level >= this.buildingData['required_building_level']) {
            this.activate();
        }
    }
}
import TimeManager from '../../../managers/TimeManager.js';
import InfoTables from '../../../scenes/ui/InfoTables.js';
import { updateUI } from '../../../scenes/ui/UI.js';
import apiClient from '../../../services/apiClient.js';
import Button from './common/Button.js';

export default class UpgradeBuildingButton extends Button {
    constructor(scene, x, y, children, texture, width, height, color) {
        super(scene, x, y, children, texture, width, height, color);
    }

    async click() {
        super.click();

        const building = this.scene.game.registry.get('curBuilding');
        const table = InfoTables.tables.get(building.texture);
        const sceneName = building.scene.scene.key;
        
        if (building.getProcessFromStack(building.texture)) {
            this.openConfirmationTable();
            return;
        };

        const data = await apiClient.processes.start({
            category: 'building', 
            name: building.texture, 
            scene: sceneName,
            building_name: building.texture,
            item_amount: 1
        });
        console.log(data);

        if (data['msg'] == '200') {
            updateUI(this.scene);
            building.startBuilding(data['data'], table.tableRight.info);
        } else {
            InfoTables.showWarningText(data['msg']);
        }
    }

    openConfirmationTable() {
        const building = this.scene.game.registry.get('curBuilding');
        const morionAmount = Math.ceil(TimeManager.getRemaining(building.texture) / 60000);

        InfoTables.openConfirmationTable({
            title: 'Строительство',
            text: `Завершить строительство за ${morionAmount} мориона?`,
            button: 'Завершить'
        }, this.finishByMorion.bind(this));
    }

    async finishByMorion() {
        const building = this.scene.game.registry.get('curBuilding');

        const data = await apiClient.processes.finishByMorion({
            category: 'building',
            name: building.texture,
        });

        if (data['status'] != 'success') {
            return;
        }

        InfoTables.showWarningText(data['message']);
        this.scene.game.registry.set('items', data['items']);
        updateUI(this.scene);

        if (data['message'] == 'Не достаточно мориона') {
            return;
        }

        const processIcon = building.getProcessFromStack(building.texture);
        building.removeProcessFromStack(processIcon);
        building.upgrade();
    }
}
import DailyBonusTable from "../tables/daily/DailyBonusTable.js";
import InfoTableContainer from "./common/InfoTableContainer";


export default class DailyBonusTableContainer extends InfoTableContainer {

    addInfoTables() {
        this.tableLeft = new DailyBonusTable(this.scene, 0, 0, [], this.w, this.h, 'daily_bonus', 'modules');
        this.tableCenter = new DailyBonusTable(this.scene, 0, 0, [], this.w, this.h, 'daily_bonus', 'resources');
        this.tableRight = new DailyBonusTable(this.scene, 0, 0, [], this.w, this.h, 'daily_bonus', 'keys');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}
import FactoryTable from "./FactoryTable.js";


export default class TitanFoundryTable extends FactoryTable {

    create() {
        this.item = 'titan';
        this.ore = 'titan_ore';
        this.storeItems = ['titan', 'titan_tile', 'titan_armor', 'cutter', 'shaft', 'turbine'];
        this.targetScene = 'Titan_Mine';
        super.create();
    }
}
import { ChangeSceneButton } from "../../../../../main/buttons/ChangeSceneButton";
import { StoreSectionItem } from "../../store/StoreTable";
import OreRateInfo from "./OreRateInfo";

export default class FactoryStore extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, width, height, ore, items, targetScene) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.ore = ore;
        this.items = items;
        this.targetScene = targetScene;

        this.create(width, height);
    }

    create(w, h) {
        this.add(this.image = this.scene.add.image(0, 0, '').setDisplaySize(w * 0.65, w * 0.07358).setAlpha(0));

        this.add(this.oreRate = new OreRateInfo(this.scene, w * 0.115, h * 0.031, [], this.image, this.ore));
        this.add(this.button = new ChangeSceneButton(this.scene, w * 0.29, h * 0.12, [], 'table_button', h * 0.065, h * 0.065, 'green', this.targetScene));

        const pos = [
            {x: w * 0.078, y: h * 0.22},
            {x: w * 0.208, y: h * 0.22},
            {x: w * 0.078, y: h * 0.318},
            {x: w * 0.208, y: h * 0.318},
            {x: w * 0.078, y: h * 0.416},
            {x: w * 0.208, y: h * 0.416},
        ];
        this.cells = [];

        this.items.forEach((key, index) => {
            let cell = new StoreSectionItem(this.scene, pos[index].x, pos[index].y, [], this.image, key);
            this.cells.push(cell);
        });
        this.add(this.cells);
    }

    update() {
        const items = this.scene.game.registry.get('items');
        this.cells.forEach((cell, index) => cell.update(items[this.items[index]]));
        this.oreRate.update();
    }
}
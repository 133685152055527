import { TEXT_STYLE_BLUE } from "../../../utils/textStyles";
import InteractiveObject from "../InteractiveObject";

export default class ItemIcon extends InteractiveObject {
    constructor(scene, x, y, children, texture, width, height) {
        super(scene, x, y, children, texture, width, height);

        this.selected = false;
    }

    create() {
        super.create();
        this.setSize(this.width * 1.5, this.height);

        this.add(this.amountText = this.scene.add.text(this.width * 0.2, 0, '-').setOrigin(0, 0.5)
            .setStyle(TEXT_STYLE_BLUE).setFontSize(this.height * 0.3));
    }

    setIconData(data) {
        this.setData('name', data['name']);
        this.setData('amount', data['amount']);

        this.obj.setFrame(`${data['name']}.png`).setDisplaySize(this.height, this.height).setOrigin(0.8, 0.5);
        this.updateAmount(data['amount']);
    }

    setClickCallback(callback) {
        this.clickCallback = callback;
    }

    pointerOver() {
        super.pointerOver();
        this.obj.setTint(0x999999);
    }

    pointerOut() {
        super.pointerOut();
        this.obj.clearTint();
    }

    click() {
        super.click();
        this.clickCallback();
    }

    resize(w, h) {
        this.obj.setDisplaySize(w, h);
        this.setSize(w * 1.5, h);
        this.amountText && this.amountText.setPosition(w * 0.2, h).setFontSize(h * 0.3);
    }

    updateAmount(amount) {
        this.amountText && this.amountText.setText(amount);
        this.setData('amount', amount);
    }

    select() {

    }

    deselect() {
        
    }
}
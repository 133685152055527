import InfoTables from "../../../scenes/ui/InfoTables";
import apiClient from "../../../services/apiClient";
import MapObject from "./MapObject";

export default class MapItem extends MapObject {

    create() {
        super.create(this.objectData);
        this.obj.setTexture('items_icons', this.texture + '.png');

        const s = window.screen.height * this.height;
        this.obj.setDisplaySize(s, s).setOrigin(0.5, 0.5);
        this.setPosition(this.scene.mapW * this.startX, this.scene.mapH * this.startY);

        this.addAt(this.shadow = this.scene.add.image(0, 0, 'UI', 'shadow.png')
            .setOrigin(0.5, 0.3).setDisplaySize(s * 1.2, s * 0.6).setAlpha(0.6), 0);
    }

    async checkAvailable() {
        const data = await apiClient.buildings.checkAvailable({
            scene: this.scene.scene.key,
            name: this.texture
        });

        if (data['status'] != 'success') {
            return;
        }
        return data['available'];
    }

    async click() {
        const isAvailable = await this.checkAvailable();

        if (!isAvailable) {
            InfoTables.showWarningText('Уничтожьте врагов поблизости');
            return;
        }

        super.click();
        this.scene.input.off('pointermove');

        const data = await apiClient.items.collect({
            scene: this.scene.scene.key,
            name: this.texture
        });

        InfoTables.showWarningText(`${this.name} +1`);
        this.destroy();
    }
}
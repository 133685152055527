import InfoTables from "../../../../../../scenes/ui/InfoTables";
import apiClient from "../../../../../../services/apiClient";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN } from "../../../../../../utils/textStyles";
import UnitIcon from "../../../../../main/icons/UnitIcon";

export default class LookoutSectionItem extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th, index) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.tw = tw;
        this.th = th;
        this.index = index;

        this.create(tw, th);
    }

    create(w, h) {
        const size = h * 0.1;

        this.add(this.cell = new UnitIcon(this.scene, 0, 0, [], 'unit_icons', size, size, ''));
        this.darkCover = this.cell.icon;
        this.frame = this.cell.obj;

        this.darkCover.setOrigin(0, 0.5).setTintFill(0x000000).setAlpha(0.5);
        this.frame.setOrigin(0, 0.5);

        this.cell.setClickCallback(() => this.onClick());

        this.add(this.lockIcon = this.scene.add.image(size * 0.43, 0, 'UI', 'lock.png').setDisplaySize(size * 0.27, size * 0.27).setOrigin(0.5, 1));
        this.add(this.openLevelText = this.scene.add.text(size * 0.43, size * 0.05, `${this.index * 10} ур.`)
            .setOrigin(0.5, 0).setStyle(TEXT_STYLE_BLUE).setFontSize(size * 0.17));

        this.add(this.xpText = this.scene.add.text(h * 0.11, 0, '+200').setOrigin(0, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(size * 0.2));

        this.setButtonEvents();
    }

    update(data) {
        this.clear();
        this.lock();
        if (data['opened'] > this.index) {
            this.open();
        }
    }

    lock() {
        this.isOpened = false;

        this.cell.disable();
        this.darkCover.setAlpha(0.7);
        this.lockIcon.setAlpha(1);
        this.openLevelText.setAlpha(1);
    }

    onClick() {
        if (this.isPrepared && this.isEmpty) {
            const icon = this.scene.game.registry.get('selectedLookout');
            icon && this.setupLookout(icon);
            this.scene.game.registry.set('selectedLookout', null);
        } else {
            InfoTables.showWarningText('Выберите солдата');
        }
    }

    open() {
        this.isOpened = true;
        this.isEmpty = true;

        this.cell.makeInteractive();
        this.darkCover.setAlpha(0.5);
        this.lockIcon.setAlpha(0.001);
        this.openLevelText.setAlpha(0.001);

        if (this.isPrepared) {
            this.frame.setAlpha(1);
            this.frame.setTint(0x79ff84);
        }
    }

    setButtonEvents() {
        this.cell.on('hover', () => {
            this.frame.setTint(0x00e2ff);
        });

        this.cell.on('out', () => {
            this.frame.setTint(0x79ff84);
        });
    }

    prepare() {
        this.isPrepared = true;
        this.cell.select();
        this.frame.setTint(0x79ff84);
    }

    clear() {
        this.isEmpty = true;
        this.unit && this.unit.destroy();
        this.xpText.setText('-').setStyle(TEXT_STYLE_BLUE);
    }

    reset() {
        this.isPrepared = false;
        this.cell.deselect();
        this.frame.setAlpha(0.001);
        this.frame.setTint(0x00e2ff);
    }

    async setupLookout(unitData) {
        const data = await apiClient.units.setupLookout({
            unit_id: unitData['id'],
            area: this.parentContainer.area
        });

        if (data['status'] != 'success') {
            return;
        }

        this.addUnit(unitData, data['lookout']);

        const table = this.scene.game.registry.get('opened_table').tableLeft;
        table.resetCells();
        table.updateMainScroll(data['units']['units']);
    }

    addUnit(unitData, lookoutData) {
        this.isEmpty = false;

        this.xpText.setText('+' + lookoutData['xp_increase'] || '0').setStyle(TEXT_STYLE_GREEN);

        this.add(this.unit = new UnitIcon(this.scene, this.th * 0.05, 0, [], 'button', this.th * 0.1, this.th * 0.1));
        this.unit.setUnitData(unitData);

        this.unit.setClickCallback(async () => {
            const data = await apiClient.units.removeLookout({ unit_id: unitData['id'] });

            if (data['status'] != 'success') {
                return;
            }

            this.clear();
            const table = this.scene.game.registry.get('opened_table').tableLeft;
            table.updateMainScroll(data['units']['units']);
        });
    }
}
import InteractiveObject from "../../../../../main/InteractiveObject";


export class DailyBonusCell extends InteractiveObject {
    constructor(scene, x, y, children, texture, width, height, item) {
        super(scene, x, y, children, texture, width, height);

        this.active = false;
    }

    create() {
        super.create();
        this.obj.setFrame('skill_cell.png')
            .setDisplaySize(this.width * 0.05, this.width * 0.05 / 1.16)
            .setOrigin(0.5, 0.5);
    }

    makeInteractive() {
        this.obj.setInteractive(this.scene.input.makePixelPerfect());
        super.makeInteractive();
    }

    pointerOver() {
        super.pointerOver();
        this.obj.setTint(0xbdbdbd);
        this.obj.setAlpha(0.8);
    }

    pointerOut() {
        super.pointerOut()
        this.obj.clearTint();
        this.obj.setAlpha(1);
    }

    click() {
        super.click();
        this.active = true;
        this.destroy();
    }
}
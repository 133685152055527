import AudioManager from "../../../managers/AudioManager";
import Button from "../buttons/common/Button";

export default class MachineBodyIcon extends Button {
    constructor(scene, x, y, children, texture, width, height, color) {
        super(scene, x, y, children, texture, width, height, color);

        this.isSelected = false;
    }

    create() {
        super.create();

        const w = this.width;
        const h = this.height;

        this.add(this.icon = this.scene.add.sprite(0, 0, 'machine_body_icons', 'miner_body.png'));
        this.obj.setTexture('UI').setFrame('table_button.png');
        this.bringToTop(this.obj);

        this.resize(w, h);
    }

    setIconData(data, index) {
        this.iconData = data;
        this.index = index;
        this.setIconTexture();
    }

    setIconTexture() {
        const frame = this.iconData['name'];
        this.icon.setTexture('machine_body_icons', `${frame}.png`);
    }

    setClickCallback(callback) {
        this.clickCallback = callback;
    }

    click() {
        AudioManager.playSound('click', 'icon');
        this.clickCallback();
    }

    pointerOut() {
        super.pointerOut();
        this.obj.setAlpha(this.isSelected ? 1 : 0.001);
    }

    select() {
        this.isSelected = true;
        this.obj.setAlpha(1);
    }

    deselect() {
        this.isSelected = false;
        this.obj.setAlpha(0.001);
    }

    activate() {
        this.disable();
        this.obj.setTint(0x14ff00);
        this.setState('active');
    }

    resize(w, h) {
        this.icon.setDisplaySize(w, h);
        this.obj.setDisplaySize(w, h);
    }
}
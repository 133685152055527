export default class JournalPage extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, category) {
        super(scene, x, y, children);
        scene.add.existing(this);
        
        this.scene = scene;
        this.category = category;

        this.create();
    }

    create() {
        const fromX = window.screen.width * -0.21;
        const fromY = window.screen.height * 0;
        const toX = window.screen.width * -0.21;
        const toY = window.screen.height * 0.67;

        this.add(this.scene.add.line(0, 0, fromX, fromY, toX, toY, 0x87e9ff));
    }

    setVisible(visible) {
        super.setVisible(visible);
        if (this.info.rewards) {
            this.info.rewards.setVisible(visible);
        }
    }
}
import AudioManager from "../../../../managers/AudioManager";
import BattleObstacle from "./BattleObstacle";

export default class BattleCrystall extends BattleObstacle {

    setUnitData(unitData, linkedIcon) {
        super.setUnitData(unitData, linkedIcon);
        this.getData('config')['XP_cost'] = 2;
    }

    die() {
        super.die();
        AudioManager.playSound('death', 'zazulic_nest');
    }
}
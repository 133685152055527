import Button from "../common/Button";

export default class UnitCategoryButton extends Button {
    constructor(scene, x, y, children, texture, width, height, color, clickCallback) {
        super(scene, x, y, children, texture, width, height, color);

        this.clickCallback = clickCallback;
    }

    click() {
        super.click();
        this.clickCallback();
    }
}
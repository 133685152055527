import MapMachine from '../../modules/scene/objects/MapMachine.js';
import MapScene from '../core/mapScene.js';

export default class MainBase extends MapScene {
    constructor() {
        super('MainBase');
    }

    addObjects(info) {
        super.addObjects(info);

        const items = this.game.registry.get('items');
        if (items['builder'] == 0) {
            this.map.add(new MapMachine(this, 0.2, 0.23, [], 'builder', 0.07, 0.07, {
                scene: 'MainBase',
                name: 'builder',
                status: 'broken',
            }));
        }
    }
}
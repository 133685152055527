const shopData = {
    'containers': {
        content: [
            {title: 'Контейнеры батарей', content: [
                {item: 'container_batteries_1', amount: 1, currency: 'morion', cost: 25},
                {item: 'container_batteries_2', amount: 1, currency: 'morion', cost: 50},
                {item: 'container_batteries_3', amount: 1, currency: 'morion', cost: 75},
            ], items: ['battery_units', 'battery_air', 'battery_tech', 'battery_robots', 'battery_military']},

            {title: 'Контейнеры кристаллов', content: [
                {item: 'container_crystalls_1', amount: 1, currency: 'morion', cost: 35},
                {item: 'container_crystalls_2', amount: 1, currency: 'morion', cost: 70},
                {item: 'container_crystalls_3', amount: 1, currency: 'morion', cost: 105},
            ], items: ['crystall', 'blue_crystall', 'red_crystall', 'yellow_crystall', 'purple_crystall', 'brown_crystall']},

            {title: 'Контейнеры железа', content: [
                {item: 'container_iron_1', amount: 1, currency: 'morion', cost: 35},
                {item: 'container_iron_2', amount: 1, currency: 'morion', cost: 70},
                {item: 'container_iron_3', amount: 1, currency: 'morion', cost: 105},
            ], items: ['steel', 'iron_tile', 'iron_pipe', 'iron_bar', 'door', 'iron_armor']},

            {title: 'Контейнеры кремния', content: [
                {item: 'container_silicon_1', amount: 1, currency: 'morion', cost: 39},
                {item: 'container_silicon_2', amount: 1, currency: 'morion', cost: 79},
                {item: 'container_silicon_3', amount: 1, currency: 'morion', cost: 119},
            ], items: ['silicon', 'silicon_plate', 'glass', 'silicon_glass', 'lense', 'prism']},

            {title: 'Контейнеры титана', content: [
                {item: 'container_titan_1', amount: 1, currency: 'morion', cost: 49},
                {item: 'container_titan_2', amount: 1, currency: 'morion', cost: 99},
                {item: 'container_titan_3', amount: 1, currency: 'morion', cost: 149},
            ], items: ['titan', 'titan_tile', 'titan_armor', 'cutter', 'shaft', 'turbine']},

            {title: 'Контейнеры модулей', content: [
                {item: 'container_modules_1', amount: 1, currency: 'morion', cost: 99},
                {item: 'container_modules_2', amount: 1, currency: 'morion', cost: 199},
                {item: 'container_modules_3', amount: 1, currency: 'morion', cost: 299},
            ], items: ['soldier_module_health_1', 'soldier_module_armor_1', 'soldier_module_damage_1', 'soldier_module_accuracy_1']},

            {title: 'Контейнеры генетики', content: [
                {item: 'container_genetic_1', amount: 1, currency: 'morion', cost: 199},
                {item: 'container_genetic_2', amount: 1, currency: 'morion', cost: 299},
                {item: 'container_genetic_3', amount: 1, currency: 'morion', cost: 399},
            ], items: ['injector_health', 'injector_damage', 'injector_accuracy', 'mutagen_health', 'mutagen_damage', 'mutagen_accuracy', 'blood', 'claw', 'bone_brain', 'eye', 'brain']},

            {title: 'Контейнеры исследований', content: [
                {item: 'container_research_1', amount: 1, currency: 'morion', cost: 199},
                {item: 'container_research_2', amount: 1, currency: 'morion', cost: 299},
                {item: 'container_research_3', amount: 1, currency: 'morion', cost: 399},
            ], items: ['crystall_health', 'crystall_damage', 'crystall_accuracy', 'energy_cell_blue', 'energy_cell_red', 'energy_cell_yellow', 'energy_cell_purple', 'energy_cell_brown']},

            {title: 'Контейнеры запчастей', content: [
                {item: 'container_devices_1', amount: 1, currency: 'morion', cost: 199},
                {item: 'container_devices_2', amount: 1, currency: 'morion', cost: 299},
                {item: 'container_devices_3', amount: 1, currency: 'morion', cost: 399},
            ], items: ['machine_wheel', 'machine_arm', 'machine_bucket', 'machine_claw', 'machine_jack', 'machine_engine']},

            {title: 'Контейнеры роботов', content: [
                {item: 'container_robots_1', amount: 1, currency: 'morion', cost: 399},
                {item: 'container_robots_2', amount: 1, currency: 'morion', cost: 599},
                {item: 'container_robots_3', amount: 1, currency: 'morion', cost: 799},
            ], items: ['chip', 'processor', 'ram', 'robo_eye', 'robo_drive']},

            {title: 'Контейнеры ключей', content: [
                {item: 'container_keys_1', amount: 1, currency: 'morion', cost: 99},
                {item: 'container_keys_2', amount: 1, currency: 'morion', cost: 149},
                {item: 'container_keys_3', amount: 1, currency: 'morion', cost: 199},
            ], items: ['key_container_batteries', 'key_container_crystalls', 'key_container_iron', 'key_container_silicon', 'key_container_titan', 'key_container_modules', 'key_container_genetic', 'key_container_research', 'key_container_devices', 'key_container_robots', 'key_container_keys']},
        ],
        rewards: [
            'container_batteries_1', 
            'container_crystalls_1', 
            'container_iron_1', 
            'container_silicon_1', 
            'container_titan_1', 
            'container_modules_1', 
            'container_genetic_1', 
            'container_research_1', 
            'container_devices_1', 
            'container_robots_1', 
            'container_morion_4', 
        ]
    },
    'keys': {
        content: [
            {title: 'Ключи к контейнерам батарей', content: [
                {item: 'key_container_batteries', amount: 1, currency: 'morion', cost: 10},
                {item: 'key_container_batteries', amount: 3, currency: 'morion', cost: 20},
                {item: 'key_container_batteries', amount: 5, currency: 'morion', cost: 30},
            ], items: ['container_batteries_1', 'container_batteries_2', 'container_batteries_3']},

            {title: 'Ключи к контейнерам кристаллов', content: [
                {item: 'key_container_crystalls', amount: 1, currency: 'morion', cost: 10},
                {item: 'key_container_crystalls', amount: 3, currency: 'morion', cost: 20},
                {item: 'key_container_crystalls', amount: 5, currency: 'morion', cost: 30},
            ], items: ['container_crystalls_1', 'container_crystalls_2', 'container_crystalls_3']},

            {title: 'Ключи к контейнерам железа', content: [
                {item: 'key_container_iron', amount: 1, currency: 'morion', cost: 15},
                {item: 'key_container_iron', amount: 3, currency: 'morion', cost: 30},
                {item: 'key_container_iron', amount: 5, currency: 'morion', cost: 45},
            ], items: ['container_iron_1', 'container_iron_2', 'container_iron_3']},

            {title: 'Ключи к контейнерам кремния', content: [
                {item: 'key_container_silicon', amount: 1, currency: 'morion', cost: 15},
                {item: 'key_container_silicon', amount: 3, currency: 'morion', cost: 30},
                {item: 'key_container_silicon', amount: 5, currency: 'morion', cost: 45},
            ], items: ['container_silicon_1', 'container_silicon_2', 'container_silicon_3']},

            {title: 'Ключи к контейнерам титана', content: [
                {item: 'key_container_titan', amount: 1, currency: 'morion', cost: 20},
                {item: 'key_container_titan', amount: 3, currency: 'morion', cost: 40},
                {item: 'key_container_titan', amount: 5, currency: 'morion', cost: 60},
            ], items: ['container_titan_1', 'container_titan_2', 'container_titan_3']},

            {title: 'Ключи к контейнерам модулей', content: [
                {item: 'key_container_modules', amount: 1, currency: 'morion', cost: 30},
                {item: 'key_container_modules', amount: 3, currency: 'morion', cost: 60},
                {item: 'key_container_modules', amount: 5, currency: 'morion', cost: 90},
            ], items: ['container_modules_1', 'container_modules_2', 'container_modules_3']},

            {title: 'Ключи к контейнерам генетики', content: [
                {item: 'key_container_genetic', amount: 1, currency: 'morion', cost: 40},
                {item: 'key_container_genetic', amount: 3, currency: 'morion', cost: 80},
                {item: 'key_container_genetic', amount: 5, currency: 'morion', cost: 120},
            ], items: ['container_genetic_1', 'container_genetic_2', 'container_genetic_3']},

            {title: 'Ключи к контейнерам исследований', content: [
                {item: 'key_container_research', amount: 1, currency: 'morion', cost: 40},
                {item: 'key_container_research', amount: 3, currency: 'morion', cost: 80},
                {item: 'key_container_research', amount: 5, currency: 'morion', cost: 120},
            ], items: ['container_research_1', 'container_research_2', 'container_research_3']},

            {title: 'Ключи к контейнерам запчастей', content: [
                {item: 'key_container_devices', amount: 1, currency: 'morion', cost: 40},
                {item: 'key_container_devices', amount: 3, currency: 'morion', cost: 80},
                {item: 'key_container_devices', amount: 5, currency: 'morion', cost: 120},
            ], items: ['container_devices_1', 'container_devices_2', 'container_devices_3']},

            {title: 'Ключи к контейнерам роботов', content: [
                {item: 'key_container_robots', amount: 1, currency: 'morion', cost: 50},
                {item: 'key_container_robots', amount: 3, currency: 'morion', cost: 100},
                {item: 'key_container_robots', amount: 5, currency: 'morion', cost: 150},
            ], items: ['container_robots_1', 'container_robots_2', 'container_robots_3']},

            {title: 'Ключи к контейнерам ключей', content: [
                {item: 'key_container_keys', amount: 1, currency: 'morion', cost: 10},
                {item: 'key_container_keys', amount: 3, currency: 'morion', cost: 20},
                {item: 'key_container_keys', amount: 5, currency: 'morion', cost: 30},
            ], items: ['container_keys_1', 'container_keys_2', 'container_keys_3']},
        ],
        rewards: [
            'container_keys_1', 
            'container_keys_1', 
            'container_keys_1', 
            'container_keys_1', 
            'container_keys_1', 
            'container_keys_2', 
            'container_keys_2', 
            'container_keys_2', 
            'container_keys_3', 
            'container_keys_3', 
            'container_keys_3', 
        ]
    },
    'morion': {
        content: [
            {item: 'key_container_morion', amount: 2, currency: 'vote', cost: 1},
            {item: 'key_container_morion', amount: 5, currency: 'vote', cost: 3},
            {item: 'key_container_morion', amount: 7, currency: 'vote', cost: 5},
            {item: 'container_morion_1', amount: 25, currency: 'vote', cost: 3},
            {item: 'container_morion_2', amount: 50, currency: 'vote', cost: 5},
            {item: 'container_morion_3', amount: 100, currency: 'vote', cost: 9},
            {item: 'container_morion_4', amount: 250, currency: 'vote', cost: 21},
            {item: 'container_morion_5', amount: 500, currency: 'vote', cost: 39},
            {item: 'container_morion_6', amount: 1000, currency: 'vote', cost: 69},
        ],
        rewards: [
            'key_container_morion', 
            'container_morion_1', 
            'container_morion_1', 
            'container_morion_1', 
            'container_morion_1', 
            'container_morion_2', 
            'container_morion_2', 
            'container_morion_2', 
            'container_morion_3', 
            'container_morion_3', 
            'container_morion_3', 
        ]
    },
    'epic': {
        content: [
            {item: 'embryo', amount: 1, currency: 'vote', cost: 99},
            {item: 'mutagen_clone', amount: 1, currency: 'vote', cost: 39},
            {item: 'battery_irradiated', amount: 1, currency: 'vote', cost: 39},
            {item: 'robo_brain', amount: 1, currency: 'vote', cost: 99},
            {item: 'machine_brain', amount: 1, currency: 'vote', cost: 59},
            {item: 'medkit', amount: 10, currency: 'vote', cost: 9},
            {item: 'blood', amount: 100, currency: 'vote', cost: 9},
            {item: 'soldier_module_speed_1', amount: 1, currency: 'vote', cost: 49},
            {item: 'soldier_module_range_1', amount: 1, currency: 'vote', cost: 59},
        ],
        rewards: [
            'container_batteries_1', 
            'container_crystalls_1', 
            'container_iron_1', 
            'container_silicon_1', 
            'container_titan_1', 
            'container_modules_1', 
            'container_genetic_1', 
            'container_research_1', 
            'container_devices_1', 
            'container_robots_1', 
            'container_morion_4', 
        ]
    },
    'promo': {
        content: [],
        rewards: [
            'container_morion_4', 
            'container_morion_4', 
            'container_morion_4', 
            'container_morion_4', 
            'container_morion_4', 
            'container_morion_5', 
            'container_morion_5', 
            'container_morion_5', 
            'container_morion_6', 
            'container_morion_6', 
            'container_morion_6', 
        ]
    }
}

export default shopData;
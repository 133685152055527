import BattleUnit from "../BattleUnit";

export default class BattleCharacter extends BattleUnit {

    setUnitData(unitData, linkedIcon) {
        super.setUnitData(unitData, linkedIcon);
        const config = this.getData('config');
        
        config['weakness'] = [];
    }
}
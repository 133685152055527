export default async function loadAssets(scene, type, key, path, pathJSON) {
    let retryCount = 2;

    return new Promise((resolve, reject) => {
        if (scene.textures.exists(key)) {
            resolve(scene.textures.get(key)); // Возвращаем существующую текстуру из кэша
        } else {
            switch (type) {
                case 'image':
                    scene.load.image(key, path);
                    break;
                case 'atlas':
                    scene.load.atlas(key, path, pathJSON);
                    break;
            }

            scene.load.off('loaderror').on('loaderror', () => {
                if (retryCount > 0) {
                    retryCount -= 1; 
                } else {
                    reject(new Error(`Failed to load texture ${key}`));
                }
            });

            scene.load.once('complete', () => {
                const texture = scene.textures.get(key);
                if (texture) {
                    resolve(texture); // Возвращаем загруженную текстуру
                } else {
                    reject(new Error(`Failed to load texture ${key}`)); // Отклоняем промис в случае ошибки загрузки
                }
            });

            scene.load.start();
        }
    });
}

import loadAssets from '../../../../../../managers/AssetManager.js';
import { ScrollManager } from '../../../../../../managers/UIController.js';
import SingleInfoTable from '../../common/SingleInfoTable.js';
import SoldierEvolutionTree from './evolution/SoldierEvolutionTree.js';
import HeroMiniTable from './evolution/common/mini_tables/HeroMiniTable.js';
import SoldierMiniTable from './evolution/common/mini_tables/SoldierMiniTable.js';


export default class ArmyUnitsEvolutionTable extends SingleInfoTable {

    async create() {
        super.create();
        const w = this.width;
        const h = this.height;
        this.unitCategory = this.texture.replace('_evolution', '');

        // Мини-табличка с информацией о юните
        this.add(this.unitMiniTable = new SoldierMiniTable(this.scene, w * 0.1625, h * 0.16, [], w * 0.253, h * 0.29));
        this.add(this.heroMiniTable = new HeroMiniTable(this.scene, w * 0.1625, h * 0.16, [], w * 0.253, h * 0.29));

        switch (this.unitCategory) {
            case 'soldier':
                this.evolutionTree = new SoldierEvolutionTree(this.scene, 0, 0, [], this.width, this.height);
                break;
        }
        this.add(this.evolutionTree);
    }

    makeEvolutionScroll(w, h) {
        const scrollW = w * 0.7;
        const scrollH = h * 0.6;

        this.evolutionScroll = new ScrollManager(this.scene, {
            x: 0,
            y: h * -0.032,
            width: scrollW,
            height: scrollH,

            scrollMode: 'horizontal',
        }, {});

        this.add(this.evolutionScroll.scrollPanel);

        const mask = this.scene.infoTableMainScrollMask.createBitmapMask();
        this.evolutionScroll.scrollPanel.setMask(mask);

        this.remove(this.evolutionTree);
        this.evolutionScroll.addItem(this.evolutionTree);
    }

    // Принимаем скроллинг из центральной таблички
    addUnitScroll(scroll) {
        this.add(this.unitScroll = scroll);
    }

    // Убираем скроллинг из этой таблички и добавляем в центральную
    removeUnitScroll() {
        this.remove(this.unitScroll);
    }

    async update(category) {
        super.update(category);
        await this.loadData(this.unitCategory);

        this.evolutionTree.update();

        if (!this.evolutionScroll) {
            this.makeEvolutionScroll(this.width, this.height);
        }
    }

    async loadData(category) {
        const texture = 'evolution_' + category;

        await loadAssets(this.scene, 'image', texture,
            `UI/info_tables/common/units/evolution/${category}.png`);
    }

    updateInfo(unitData) {
        this.evolutionTree.updateContent(unitData);
    }
}




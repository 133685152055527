import SingleInfoTable from "../common/SingleInfoTable";
import BuildingInfo from "./BuildingInfo";

export default class BuildingTable extends SingleInfoTable {
    
    create() {
        super.create();
        this.add(this.info = new BuildingInfo(this.scene, 0, 0, [], this.width, this.height));
    }

    updateInfo(level = this.level) {
        this.level = level;
        this.info.updateContent(level);
    }
}
import JournalStoryInfo from "../info/JournalStoryInfo";
import JournalScrollStory from "../scroll/JournalScrollStory";
import JournalPage from "./JournalPage";

export default class JournalStoryPage extends JournalPage {

    create() {
        super.create();

        this.add(this.info = new JournalStoryInfo(this.scene, window.screen.width * -0.19, window.screen.height * -0.33, []));
        this.add(this.scroll = new JournalScrollStory(this.scene, 0, 0, []));
    }
}
import AnimationManager from "../../../../managers/AnimationManager";
import InfoTables from "../../../../scenes/ui/InfoTables";
import { getRandomArrayElement } from "../../../../utils/utils";
import MapObject from "../MapObject";

export class MapUnit extends MapObject {
    constructor(scene, x, y, children, texture, width, height, data, Id) {
        super(scene, x, y, children, texture, width, height, data);

        this.Id = Id;
        this.setData('data', data);

        this.link.setAmount(data['amount']);
        this.isAvailable = false;

        this.setUnitSize();
        this.playAnimation();
    }

    create() {
        this.type = 'unit';
        super.create();

        const x = this.obj.displayWidth * 1.1;

        this.addAt(this.circle = this.scene.add.sprite(0, x * 0.2, 'battle_ui', 'highlight.png')
            .setDisplaySize(x, x * 0.6).setTint(0xFF5656).setAlpha(0), 0);

        const dist = window.screen.height * 0.015;
        const arrowSize = window.screen.height * 0.03;

        this.add(this.arrows = this.scene.add.container().setAlpha(0));

        for (let i = 0; i < 3; i++) {
            this.arrows.add(this.scene.add.sprite(0, x * -0.6 - i * dist, 'UI', 'arrow.png')
                .setDisplaySize(arrowSize, arrowSize * 1.2).setRotation(Math.PI / 2).setTint(0xFF5656));
        }

        this.setAvailable(false);
    }

    setUnitSize() {
        const w = window.screen.width / 2;
        this.obj.setDisplaySize(w * this.width, w * this.height).setOrigin(0.5, 0.5);
    }

    addLink() {
        this.linkX = 0;
        this.linkY = 0;
        super.addLink();
    }

    async click() {
        super.click();

        if (this.isAvailable) {
            InfoTables.openInfoTable('army', {
                unit: this,
                data: this.getData('data')
            });
        } else {
            InfoTables.showWarningText('Победите предыдущих врагов, чтобы сразиться с этим');
        }
    }

    setAvailable(isAvailable) {
        this.isAvailable = isAvailable;
        this.circle.setAlpha(isAvailable);
        this.arrows.setAlpha(isAvailable);

        if (!isAvailable) return;

        const tween = this.scene.tweens.add({
            targets: this.arrows,
            y: this.obj.displayWidth * 0.1,
            duration: 700,
            loop: -1,
            yoyo: true,
        });
    }

    createAnimations(name) {
        const scene = this.scene;

        switch (name) {
            case 'zazulic':
            case 'zazulic_soldier':
            case 'zazulic_mini':
            case 'zazulic_sand':
            case 'zazulic_sand_soldier':
            case 'zazulic_sand_mini':
                AnimationManager.create(scene, name, 'huff', 4, 18, 6, -1);
                AnimationManager.create(scene, name, 'dig', 8, 10, 6, -1);
                break;
            case 'pichmog':
                AnimationManager.create(scene, name, 'wake', 4, 5, 6, false);
                break;
            case 'vugda':
            case 'vugda_mini':
            case 'vugda_female':
                AnimationManager.create(scene, name, 'idle', 4, 20, 6, -1);
                break;
            case 'beles':
            case 'beles_mini':
            case 'beles_female':
                AnimationManager.create(scene, name, 'idle', 4, 20, 5, -1);
                break;
            case 'mutant':
                AnimationManager.create(scene, name, 'idle', 8, 10, 5, -1);
                break;
            case 'irradiated':
                AnimationManager.create(scene, name, 'idle', 8, 10, 5, -1);
                break;
            case 'Chlapidol':
                AnimationManager.create(scene, name, 'idle', 8, 10, 2, -1);
                break;
        }

        this.setUnitSize();
        this.playAnimation();
    }

    playAnimation() {
        const data = this.getData('data');
        const name = data['unit'];
        const category = data['category'];

        let action = 'idle';
        if (name.includes('zazulic')) {
            action = ['dig', 'huff'];
        }

        if (Array.isArray(action)) {
            action = getRandomArrayElement(action);
        }

        const dirs = ['down-r', 'down-l', 'up-r', 'up-l'];
        const dir = getRandomArrayElement(dirs);

        let animation = `${category}_${action}_${dir}`;

        if (['commander', 'character', 'animal'].includes(category)) {
            animation = `${name}_${action}_${dir}`;
        }

        this.obj.anims.play(animation, true);
    }
};
import Button from "./common/Button";

export class InfoTableButton extends Button {
    constructor(scene, x, y, children, texture, width, height, color, table, action) {
        super(scene, x, y, children, texture, width, height, color);

        this.table = table;
        this.action = action;
    }

    click() {
        super.click();

        switch (this.action) {
            case 'close':
                this.table.close();
                break;
            case 'right':
                this.table.rightButtonClick();
                break;
            case 'left':
                this.table.leftButtonClick();
                break;
        }
    }
}
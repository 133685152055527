import Bullet from "../../common/Bullet";
import BattleUnit from "../BattleUnit";

export default class BattleVugda extends BattleUnit {

    setUnitData(unitData, linkedIcon) {
        super.setUnitData(unitData, linkedIcon);
        const config = this.getData('config');

        config['XP_cost'] = unitData['xp_cost'];
        config['attack_frames'] = [2, 8];
    }

    shoot(x, y, dir, target) {
        const w = this.unitSprite.displayWidth;
        let positions;

        if (this.unitSprite.anims.currentFrame.index == 2) {
            positions = {
                'right': { x: w * 0.324, y: 0 },
                'left': { x: w * -0.324, y: 0 },
                'down-r': { x: w * 0.21, y: w * 0.09 },
                'down-l': { x: w * -0.21, y: w * 0.09 },
                'up-r': { x: w * 0.266, y: w * -0.148 },
                'up-l': { x: w * -0.266, y: w * -0.148 },
                'up': { x: w * 0.05, y: w * -0.227 },
                'down': { x: w * -0.055, y: w * 0.11 },
            }
        } else {
            positions = {
                'right': { x: w * 0.336, y: w * -0.133 },
                'left': { x: w * -0.336, y: w * -0.133 },
                'down-r': { x: w * 0.316, y: 0 },
                'down-l': { x: w * -0.316, y: 0 },
                'up-r': { x: w * 0.195, y: w * -0.1875 },
                'up-l': { x: w * -0.195, y: w * -0.1875 },
                'up': { x: w * -0.11, y: w * -0.25 },
                'down': { x: w * 0.055, y: w * 0.11 },
            }
        }

        const bullet = new Bullet(this.scene, x + positions[dir].x, y + positions[dir].y, 'UI', 'spike.png');
        bullet.create(x, y, target.x + target.unitSprite.displayWidth / 2, target.y + target.unitSprite.displayWidth / 2);
        bullet.move(target.x, target.y);
    }
}
import UIController from "../../../managers/UIController";
import TimeManager from "../../../managers/TimeManager";
import { TEXT_STYLE_BLUE, TEXT_STYLE_ORANGE } from "../../../utils/textStyles";
import ExploreWorldAreaButton from "../../main/buttons/ExploreWorldAreaButton";

export default class ExplorationTable extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, data, morionFinish) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.areaData = data;
        this.morionFinish = morionFinish;

        this.create(window.screen.width);
    }

    create(w) {
        this.add(this.table = this.scene.add.image(0, 0, 'table_explore').setOrigin(0, 1)
            .setDisplaySize(w * 0.24, w * 0.084).setInteractive());

        const tw = this.table.displayWidth;
        const th = this.table.displayHeight;

        // Название 
        this.add(this.name = this.scene.add.text(tw * 0.6, th * -0.77, this.areaData['name'])
            .setOrigin(0.5, 1).setStyle(TEXT_STYLE_ORANGE).setFontSize(w * 0.011));

        // Кнопка 
        this.add(this.button = new ExploreWorldAreaButton(this.scene, tw * 0.203, th * -0.684, [], 'table_button', tw * 0.12, tw * 0.12, 'green'));

        this.handleButtonClick();
        this.addText(tw, th);
    }

    handleButtonClick() {
        this.button.obj.on('pointerdown', () => {
            this.scene.input.off('pointerdown');
            this.button.obj.on('pointerup', () => {
                this.scene.map.handleMoves();
                this.scene.input.on('pointerdown', () => {
                    this.scene.input.off('pointerdown');
                    const object = this.scene.game.registry.get('selected_world_area');
                    object && object.destroyExploreTable();
                });
            });
        });
    }

    addText(w, h) {
        this.add(this.timeText = this.scene.add.text(w * 0.3, h * -0.62, 'Завершить?').setOrigin(0, 0).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.15));

        if (this.morionFinish) {
            const worldArea = this.scene.game.registry.get('selected_world_area');
            const morionAmount = Math.ceil(TimeManager.getRemaining(worldArea.targetScene) / 60000);

            this.areaData['exploration_materials'] = {
                'morion': morionAmount,
            }
        } else {
            this.updateText();
        }

        UIController.arrangeItems({
            container: this,
            itemsData: this.areaData["exploration_materials"],
            x: w * 0.22,
            y: h * -0.25,
            iconSize: h * 0.2,
            fontSize: h * 0.12,
            space: w * 0.185,
        });
    }

    updateText() {
        let items = this.scene.game.registry.get('items');
        UIController.updateItemsText(items, this.areaData["exploration_materials"], this.itemsText);
        this.timeText.setText(TimeManager.formatFullTime(this.areaData['exploration_time']));
    }
}

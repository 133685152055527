import BattleUnit from "../BattleUnit";

export default class BattleZazulic extends BattleUnit {

    setUnitData(unitData, linkedIcon) {
        super.setUnitData(unitData, linkedIcon);
        this.getData('config')['XP_cost'] = 2;
    }

    rest() {
        this.setState('idle');
        this.playAnimation(['huff', 'dig']);
    }
}
const heroData = {
    "berserk": {
        "name": 'Берсерк',
        "health": 500,
        "armor": 50,
        "damage": 150,
        "accuracy": 90,
        "speed": 8,
        "range": 1,
    },
    "stalker": {
        "name": 'Сталкер',
        "health": 500,
        "armor": 55,
        "damage": 140,
        "accuracy": 60,
        "speed": 8,
        "range": 7,
    },
    "ghost": {
        "name": 'Призрак',
        "health": 500,
        "armor": 45,
        "damage": 150,
        "accuracy": 70,
        "speed": 8,
        "range": 10,
    },
    "ripper": {
        "name": 'Потрошитель',
        "health": 750,
        "armor": 60,
        "damage": 120,
        "accuracy": 90,
        "speed": 7,
        "range": 1,
    },
    "chastener": {
        "name": 'Каратель',
        "health": 750,
        "armor": 65,
        "damage": 210,
        "accuracy": 70,
        "speed": 8,
        "range": 6,
    },
    "raven": {
        "name": 'Ворон',
        "health": 750,
        "armor": 55,
        "damage": 250,
        "accuracy": 80,
        "speed": 8,
        "range": 10,
    },
    "hangman": {
        "name": 'Палач',
        "health": 900,
        "armor": 75,
        "damage": 200,
        "accuracy": 90,
        "speed": 8,
        "range": 1,
    },
    "reaper": {
        "name": 'Жнец',
        "health": 900,
        "armor": 65,
        "damage": 350,
        "accuracy": 85,
        "speed": 8,
        "range": 10,
    },
    "peacemaker": {
        "name": 'Миротворец',
        "health": 900,
        "armor": 75,
        "damage": 200,
        "accuracy": 75,
        "speed": 7,
        "range": 9,
    },
    "hornet": {
        "name": 'Шершень',
        "health": 1500,
        "armor": 90,
        "damage": 500,
        "accuracy": 80,
        "speed": 6,
        "range": 12,
    },
    "typhoon": {
        "name": 'Тайфун',
        "health": 1500,
        "armor": 90,
        "damage": 300,
        "accuracy": 80,
        "speed": 6,
        "range": 10,
    },
    "demon": {
        "name": 'Демон',
        "health": 1500,
        "armor": 90,
        "damage": 750,
        "accuracy": 80,
        "speed": 6,
        "range": 10,
    }
}

export default heroData;

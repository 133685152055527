import { TEXT_STYLE_BLUE } from "../../../utils/textStyles";
import ObjectLink from "./ObjectLink";

export default class BuildingLink extends ObjectLink {
    constructor(scene, x, y, children, name, building) {
        super(scene, x, y, children, name);

        this.building = building;
    }

    create(w) {
        this.type = 'building';
        super.create(w);

        const lw = this.link.displayWidth;
        const lh = this.link.displayHeight;
        this.add(this.level = this.scene.add.text(lw * 0.205, lh * -0.68, '').setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(w * 0.011));
    }

    show() {
        super.show()
        this.level.setText(this.building.level.toString());
    }

    flip() {  
        super.flip();
        this.level.setPosition(this.link.displayWidth * -0.205, this.link.displayHeight * -0.68);
    }
};
import { CharacterEquipment } from '../characters/equipment/CharacterEquipment.js';
import { CharacterEquipmentInfo } from '../characters/equipment/CharacterEquipmentInfo.js';
import ArmyUnitsTable from '../army_units/ArmyUnitsTable.js';
import apiClient from '../../../../../../services/apiClient.js';
import loadAssets from '../../../../../../managers/AssetManager.js';
import { RobotPartInfo } from './RobotPartInfo.js';


export default class RobotTable extends ArmyUnitsTable {

    async update(category) {
        super.update(category);
        const data = await apiClient.units.robots.getAll();
        console.log(data);

        if (data['status'] != 'success') {
            return;
        }
        this.partsData = data['data']['parts'];
        this.partsInfo = data['data']['data']['parts'];

        await this.loadRobotParts(data);
        await this.loadData(this.unitCategory);
        this.updateUnitScroll(data['data']['robots']);
    }

    async loadRobotParts() {
        const texture = 'Phaser_parts';

        await loadAssets(this.scene, 'atlas', texture,
            `UI/info_tables/tables/robo_center/armor/Phaser.png`,
            `UI/info_tables/tables/robo_center/armor/Phaser.json`);
    }

    addContent(w, h) {
        super.addContent(w, h);

        this.add(this.equipment = new CharacterEquipment(this.scene, 0, 0, [], w, h, 'Phaser'));
        this.add(this.equipmentInfo = new RobotPartInfo(this.scene, 0, 0, [], w, h, 'Phaser'));
    }

    updateInfo(data) {
        super.updateInfo(data);
        const unitName = data['unit']['name'];

        this.name.setText(unitName);
        this.fullBodyImage.setTexture(`full_body_robot`).setFrame('Phaser.png');

        // Размеры картинки можно менять только после назначения текстуры
        this.setUnitSize();
        this.equipment.update(this.partsInfo[unitName], this.equipmentInfo);
        this.equipmentInfo.update(this.partsInfo[unitName], this.partsData[unitName]);
    }
}


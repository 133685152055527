export default class ArmyCharacterContainer extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, width, height) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.tw = width;
        this.th = height;
    }

    enoughSpace() {
        return !this.unit;
    }

    addUnit(unit, flip) {
        this.add(unit);
        this.unit = unit;

        unit.setPosition(0, 0);
        unit.enlarge(unit.unitData['category']);
        unit.resize(this.th * 0.18, this.th * 0.18);
        
        flip && unit.flip();
    }

    // Изменяем размеры иконки для боевого интерфейса
    resizeIconForBattle() {
        let size = Interface_Battle.main_hero_interface.height * 0.82;
        this.first.resizeIcon(size, size);
        this.first.Flip_Icon();
        this.first.makeInteractive();
    }

    removeUnit(unit) {
        this.remove(unit);
        unit.destroy();
        this.unit = null;
        this.parentContainer.updateInfo();
    }

    clear() {
        this.each(icon => {
            this.remove(icon);
            icon.destroy();
        });
    }
}
import { TILE_HEIGHT, TILE_WIDTH } from "../../../../utils/const";
import BattleUnitHPBar from "../units/components/BattleUnitHPBar";
import BattleObstacle from "./BattleObstacle";

export default class BattleDevice extends BattleObstacle {

    create() {
        const size = window.screen.height * this.size;
        const unitData = this.getData('unit');

        this.add(this.shadow = this.scene.add.image(0, 0, 'UI', 'shadow.png').setOrigin(0.5, 0.3).setDisplaySize(size * 1.2, size * 0.6).setAlpha(0.6));
        
        let texture = unitData['name'].includes('soldier_module') ? 'soldier_module_icons' : 'items_icons';

        this.add(this.unitSprite = this.scene.add.sprite(0, 0, texture, unitData['name'] + '.png').setOrigin(0.5, 0.6).setDisplaySize(size, size));
        // this.add(this.HPBar = new BattleUnitHPBar(this.scene, 0, size * -0.45, [], category, this.type));

        this.setPosition(
            TILE_WIDTH * (this.x - 0.5),
            TILE_HEIGHT * (this.y - 0.5)
        );
    }

    die() {
        super.die();
        this.destroy();
    }
}
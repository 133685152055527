import UnitMiniTable from "./UnitMiniTable";

export default class SoldierMiniTable extends UnitMiniTable {

    updateContent(unitData, data) {
        super.updateContent(unitData, data);

        this.unitImage.setTexture('full_body_soldier');

        if (!Number.isInteger(data['rank'])) {
            this.unitImage.setTexture('full_body_soldier_extra');
        }
        this.unitImage.setFrame(`${data['rank']}.png`);
    }
}
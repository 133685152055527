import { updateUI } from "../../../../scenes/ui/UI";
import InfoTables from "../../../../scenes/ui/InfoTables";
import apiClient from "../../../../services/apiClient";
import Button from "../common/Button";
import TimeManager from "../../../../managers/TimeManager";

export default class StartProductionButton extends Button {
    constructor(scene, x, y, children, texture, width, height, color, cell) {
        super(scene, x, y, children, texture, width, height, color);

        this.cell = cell;
    }

    async click() {
        super.click();
        if (this.cell.isProducing) {
            this.openConfirmationTable();
            return;
        };

        const name = this.cell.itemName;
        const amount = this.cell.amountToProduce;
        const building = this.scene.game.registry.get('curBuilding');

        const data = await apiClient.processes.start({
            category: 'production',
            name: name,
            item_amount: amount,
            building_name: building.texture
        });

        console.log(data);

        if (data['msg'] == '200') {
            this.cell.startProduction(data['data']);
            updateUI(this.scene);
        } else {
            InfoTables.showWarningText(data['msg']);
        }
    }

    openConfirmationTable() {
        const morionAmount = Math.ceil(TimeManager.getRemaining(this.cell.itemName) / 60000);

        InfoTables.openConfirmationTable({
            title: 'Производство',
            text: `Завершить производство за ${morionAmount} мориона?`,
            button: 'Завершить'
        }, this.cell.finishByMorion.bind(this.cell));
    }
}
import { ScrollManager } from "../../../../../../managers/UIController";
import BuildingConstructionCell from "./BuildingConstructionCell";


export default class BuildingConstructionContainer extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th, constructionData) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.tw = tw;
        this.th = th;
        this.constructionData = constructionData;
        this.buildingData = constructionData['data'];
        this.processes = constructionData['processes'];

        this.addContent(tw, th);
    }

    addContent(tw, th) {
        const scrollW = tw * 0.24;
        const scrollH = th * 0.59;
        const spaceTop = th * 0.01;

        this.scroll = new ScrollManager(this.scene, {
            x: tw * 0.18,
            y: th * 0.02,
            width: scrollW,
            height: scrollH,

            scrollMode: 0,

            space: {
                top: spaceTop,
                bottom: spaceTop,
            }
        }, {
            space: {
                line: spaceTop,
            },
        });

        this.add(this.scroll.scrollPanel);
        this.addCells();
    }

    addCells() {
        for (let key in this.buildingData) {
            let cell = new BuildingConstructionCell(this.scene, 0, 0, [], this.tw * 0.24, this.tw * 0.06063, key, this.buildingData[key], this.constructionData);
            this.scroll.addItem(cell);
        }
    }

    update(data) {
        this.buildingData = data['data'];
        this.processes = data['processes'];

        for (let item of this.scroll.items) {
            item.update();
            item.checkProcessExists(this.processes);
        }
    }
}
import { TILE_HEIGHT, TILE_WIDTH } from "../../../../../utils/const";
import { getDistance } from "../../../../../utils/utils";
import Bullet from "../../common/Bullet";
import BattleCharacter from "../characters/BattleCharacter";

export default class BattlePhaser extends BattleCharacter {

    setUnitData(unitData, linkedIcon) {
        super.setUnitData(unitData, linkedIcon);
        this.getData('config')['attack_frames'] = [2];
    }

    shoot(x, y, dir, target) {
        const w = this.unitSprite.displayWidth;

        const positions = {
            'right': { x: w * 0.227, y: w * -0.07 },
            'left': { x: w * -0.227, y: w * -0.07 },
            'down-r': { x: w * 0.09, y: 0 },
            'down-l': { x: w * -0.09, y: 0 },
            'up-r': { x: w * 0.227, y: w * -0.176 },
            'up-l': { x: w * -0.227, y: w * -0.176 },
            'up': { x: w * 0.09, y: w * -0.25 },
            'down': { x: w * -0.09, y: 0 },
        }

        const bullet = new Bullet(this.scene, x + positions[dir].x, y + positions[dir].y, 'UI', 'phaser_bullet.png');
        const localPos = target.unitSprite.displayWidth / 2;

        bullet.create(x, y, target.x + localPos, target.y + localPos);
        bullet.move(target.x, target.y);
    }

    checkForThreat() {
        const enemies = this.scene.data.get('enemies');
        const army = this.scene.data.get('army');

        this.TechNastya = army.find(unit => unit.getData('unit')['name'] == 'TechNastya');

        if (!this.TechNastya || this.TechNastya.state == 'dead') {
            this.findTarget();
            return;
        }

        let TechNastyaX = Math.floor(this.TechNastya.x / TILE_WIDTH);
        let TechNastyaY = Math.floor(this.TechNastya.y / TILE_HEIGHT);

        enemies.forEach(enemy => {
            let enemyX = Math.floor(enemy.x / TILE_WIDTH);
            let enemyY = Math.floor(enemy.y / TILE_HEIGHT);

            if (getDistance(TechNastyaX, TechNastyaY, enemyX, enemyY) <= 5) {
                this.target = enemy;
                this.findPathTo(this.target);
            }
        });

        if (!this.target) {
            this.checkDistToTechNastya();
        }
    }

    checkDistToTechNastya() {
        let x = Math.floor(this.x / TILE_WIDTH);
        let y = Math.floor(this.y / TILE_HEIGHT);
        let TechNastyaX = Math.floor(this.TechNastya.x / TILE_WIDTH);
        let TechNastyaY = Math.floor(this.TechNastya.y / TILE_HEIGHT);

        this.scene.easystar.findPath(x, y, TechNastyaX, TechNastyaY, (path) => {
            if (path && path.length > 3) {
                path.pop();
                path.pop();
                this.walk(path);
            } else {
                this.rest();
            }
        });
        this.scene.easystar.calculate();
    }

}
import InfoTables from "../../../../../../scenes/ui/InfoTables";
import apiClient from "../../../../../../services/apiClient";
import { CharacterEquipmentInfo } from "../characters/equipment/CharacterEquipmentInfo";


export class RobotPartInfo extends CharacterEquipmentInfo {

    async upgradeEquipment() {
        const data = await apiClient.units.robots.upgradePart({
            robot: this.unitName,
            name: this.curPart
        });

        if (data['status'] != 'success') {
            return;
        }

        this.parentContainer.updateUnitScroll(data['data']['robots']);
        this.update(data['data']['data']['parts'][this.unitName], data['data']['parts'][this.unitName]);

        InfoTables.showWarningText(data['message']);
    }
}
import InfoTables from "../../../scenes/ui/InfoTables";
import { TILE_HEIGHT, TILE_WIDTH } from "../../../utils/const";
import { setImageSizeByHeight } from "../../../utils/utils";
import BattleIrradiated from "../../scene/battle/units/BattleIrradiated";
import BattleMutant from "../../scene/battle/units/BattleMutant";
import BattleSoldier from "../../scene/battle/units/BattleSoldier";
import BattleUnit from "../../scene/battle/units/BattleUnit";
import BattleChlapidol from "../../scene/battle/units/characters/BattleChlapidol";
import BattleMainHero from "../../scene/battle/units/characters/BattleMainHero";
import BattleShtryzik from "../../scene/battle/units/characters/BattleShtryzik";
import BattleTechNastya from "../../scene/battle/units/characters/BattleTechNastya";
import BattlePhaser from "../../scene/battle/units/robots/BattlePhaser";
import MapGridCellHighlight from "./MapGridCellHighlight";

export default class ArmyBattleUI extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, type) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.type = type; // main, extra

        this.width = window.innerHeight * 0.164;
        this.height = window.innerHeight * 0.164;

        this.battleScene = scene.scene.get(scene.game.registry.get('battle_data')['data']['data']['scene']);

        this.create(this.width, this.height);
    }

    create(w, h) {
        const isMainArmy = this.type == 'main';
        const ratio = isMainArmy ? 1 : -1;
        const Xorigin = isMainArmy ? 0 : 1;

        this.add(this.UIImage = this.scene.add.sprite(0, 0, 'battle_ui', 'unit_frame.png').setOrigin(Xorigin, 1).setDisplaySize(w, h));
        this.UIImage.flipX = !isMainArmy;

        const army = this.scene.game.registry.get('battle_data')['army'];
        this.troop = isMainArmy ? army['main_troop'] : army['extra_troop'];
        this.character = isMainArmy ? army['commander'] : army['character'];

        this.add(this.troop.setPosition(w * 1.3 * ratio, h * -0.48));
        this.addAt(this.character.setPosition(w * 0.6 * ratio, h * -0.48), 0);
        this.character.unit.resize(h * 0.82, h * 0.82);

        this.addEquipmentUI(w, h, !isMainArmy, Xorigin);
        this.setUnitClickCallback();

        this.addLocks(w, h);
    }

    addLocks(w, h) {
        const ratio = (this.type == 'main') ? 1 : -1;
        const size = h * 0.17;

        this.add(this.scene.add.sprite(ratio * h * 0.265, h * -1.4, 'UI', 'lock.png').setDisplaySize(size, size));
        this.add(this.scene.add.sprite(ratio * h * 0.445, h * -1.71, 'UI', 'lock.png').setDisplaySize(size, size));2
        this.add(this.scene.add.sprite(ratio * h * 0.265, h * -2.02, 'UI', 'lock.png').setDisplaySize(size, size));
    }

    addEquipmentUI(w, h, flip, Xorigin) {
        this.add(this.equipmentFrame = this.scene.add.sprite(0, h * -1.2, 'battle_ui', 'equipment_frame.png').setOrigin(Xorigin, 1));
        this.equipmentFrame.flipX = flip;

        setImageSizeByHeight(this.equipmentFrame, h);
    }

    setLinkedUI(ui) {
        this.linkedUI = ui;
    }

    setUnitClickCallback() {
        const callback = (icon) => {
            const character = this.character.unit;
            const linkedCharacter = this.linkedUI.character.unit;
            const troop = this.troop;
            const linkedTroop = this.linkedUI.troop;

            const checkState = (icon) => {
                if (icon.state != 'active') icon.deselect();
            }

            checkState(character)
            troop.each(icon => checkState(icon));

            checkState(linkedCharacter)
            linkedTroop.each(icon => checkState(icon));

            this.selectUnit(icon);
        }

        this.character.unit.setClickCallback(() => callback(this.character.unit));
        this.troop.each(icon => icon.setClickCallback(() => callback(icon)));
    }

    selectUnit(icon) {
        icon.select();
        this.showHighlight();
        this.handleMapClick(icon);
    }

    handleMapClick(icon) {
        const battleScene = this.battleScene;
        const map = battleScene.map;

        map.off('click').on('click', () => {
            const toX = Math.floor((map.clickX - map.x) / TILE_WIDTH);
            const toY = Math.floor((map.clickY - map.y) / TILE_HEIGHT);

            if (battleScene.grid[toY][toX] != 0) {
                InfoTables.showWarningText('Сюда нельзя вывести войска. Выберите другое место');
                return;
            }

            this.deployUnit(toX, toY, icon);
            icon.activate();

            battleScene.highlight.destroy();
            battleScene.highlight = null;

            map.off('click');
        });
    }

    deployUnit(toX, toY, icon) {
        const battleScene = this.battleScene;
        const x = battleScene.deployX;
        const y = battleScene.deployY;
        const data = icon.unitData;

        let unit;

        switch (data['category']) {
            case 'commander':
            case 'character':
            case 'animal':
            case 'robot':
                switch (data['name']) {
                    case 'main_hero':
                        unit = new BattleMainHero(battleScene, x, y, [], 'player', 0.15);
                        break;
                    case 'TechNastya':
                        unit = new BattleTechNastya(battleScene, x, y, [], 'player', 0.17);
                        break;
                    case 'Phaser':
                        unit = new BattlePhaser(battleScene, x, y, [], 'player', 0.18);
                        break;
                    case 'Chlapidol':
                        unit = new BattleChlapidol(battleScene, x, y, [], 'player', 0.15);
                        break;
                    case 'Shtryzik':
                        unit = new BattleShtryzik(battleScene, x, y, [], 'player', 0.15);
                        break;
                    default:
                        unit = new BattleUnit(battleScene, x, y, [], 'player', 0.13);
                        break;
                }
                break;
            case 'soldier':
                unit = new BattleSoldier(battleScene, x, y, [], 'player', 0.13);
                break;
            case 'mutant':
                unit = new BattleMutant(battleScene, x, y, [], 'player', 0.16);
                break;
            case 'irradiated':
                unit = new BattleIrradiated(battleScene, x, y, [], 'player', 0.14);
                break;
        }

        unit.setUnitData(icon.unitData, icon);
        unit.setFirstDestination(toX, toY);
        icon.linkMapUnit(unit);

        battleScene.data.get('army').push(unit);
        battleScene.map.addAt(unit, 1);
    }

    showHighlight() {
        const battleScene = this.battleScene;
        const map = battleScene.map;

        if (!battleScene.highlight) {
            map.addAt(battleScene.highlight = new MapGridCellHighlight(battleScene, 100, 100, []), 100);
        }

        const highlight = battleScene.highlight;

        this.scene.input.on('pointermove', (pointer) => {
            let x = Math.floor((pointer.x - map.x) / TILE_WIDTH + 1);
            let y = Math.floor((pointer.y - map.y) / TILE_HEIGHT + 1);

            highlight.setPosition((x - 0.5) * TILE_WIDTH, (y - 0.5) * TILE_HEIGHT);
            // Задаем цвет подсветке
            if (battleScene.grid[y - 1][x - 1] == 0) {
                highlight.setColor('green'); // Зеленый
            } else {
                highlight.setColor('red'); // Красный
            }
        });
    }
}
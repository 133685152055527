import { TILE_HEIGHT, TILE_WIDTH } from "../../../../utils/const";
import { getRandomArrayElement } from "../../../../utils/utils";
import BattleUnit from "../units/BattleUnit";
import BattleUnitHPBar from "../units/components/BattleUnitHPBar";

export default class BattleObstacle extends BattleUnit {
    constructor(scene, x, y, children, type, size) {
        super(scene, x, y, children, type, size);
    }

    create() {
        const size = window.screen.height * this.size;
        const unitData = this.getData('unit');
        const category = unitData['category'];
        let frame;

        switch (unitData['name']) {
            case 'zazulic_nest':
                frame = getRandomArrayElement(['zazulic_nest_1', 'zazulic_nest_2', 'zazulic_nest_3', 'zazulic_nest_4', 'zazulic_nest_5']);
                break;
        }

        this.add(this.unitSprite = this.scene.add.sprite(0, 0, 'battle_obstacles', frame + '.png').setOrigin(0.5, 0.6).setDisplaySize(size, size));
        this.add(this.HPBar = new BattleUnitHPBar(this.scene, 0, size * -0.45, [], category, this.type));

        this.setSize(size, size); // Размеры контейнера для физики
        this.setPosition(
            TILE_WIDTH * (this.x - 0.5),
            TILE_HEIGHT * (this.y - 0.5)
        );
    }

    playAnimation(action) {
        let animation = `${this.getData('unit')['name']}_${action}`;
        this.unitSprite.anims.play(animation, true);
    }
}
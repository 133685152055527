import Button from "./Button";

export default class ActionButton extends Button {
    constructor(scene, x, y, children, texture, width, height, color, callback = () => {}) {
        super(scene, x, y, children, texture, width, height, color);
        
        this.callback = callback;
    }

    click() {
        super.click();
        this.callback();
    }
}
import InteractiveObject from "../../../../../../../main/InteractiveObject";

export default class EvolutionTreeIcon extends InteractiveObject {
    constructor(scene, x, y, children, texture, width, height, requiredRank, nextIcons, type, name) {
        super(scene, x, y, children, texture, width, height);

        this.nextIcons = nextIcons;
        this.requiredRank = requiredRank; 
        this.type = type || 'regular';
        this.name = name || '';
        
        this.available = false;
        this.opened = false;
    }

    create() {
        super.create();
        this.obj.setFrame('skill_cell.png')
            .setDisplaySize(this.width, this.height)
            .setOrigin(0.5, 0.5);
    }
    
    pointerOver() {
        super.pointerOver();
        if (this.available) this.obj.setAlpha(0.5);
    }

    pointerOut() {
        super.pointerOut();
        if (this.available) this.obj.setAlpha(0.7);
    }

    click() {
        super.click();
        if (this.available) {
            this.unlock();
        }
    }

    makeInteractive() {
        super.makeInteractive();
        this.obj.setAlpha(0.7);
    }

    makeAvailable() {
        this.obj.setTintFill(0xffc04d);
        this.available = true;
        this.makeInteractive();
    }

    unlock() {
        this.opened = true;
        this.obj.setAlpha(0);
        this.disable();
    }

    block() {
        this.obj.clearTint();
        this.obj.setAlpha(1);
        this.available = false;
    }
}
import ShopTable from "../tables/shop/ShopTable.js";
import InfoTableContainer from "./common/InfoTableContainer";


export default class ShopTableContainer extends InfoTableContainer {

    addInfoTables() {
        this.tableLeft = new ShopTable(this.scene, 0, 0, [], this.w, this.h, 'shop', 'epic');
        this.tableCenter = new ShopTable(this.scene, 0, 0, [], this.w, this.h, 'shop', 'morion');
        this.tableRight = new ShopTable(this.scene, 0, 0, [], this.w, this.h, 'shop', 'promo');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}
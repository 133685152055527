import InfoTables from '../../../../../../scenes/ui/InfoTables.js';
import apiClient from '../../../../../../services/apiClient.js';
import ActionButton from '../../../../../main/buttons/common/ActionButton.js';
import ItemIcon from '../../../../../main/icons/ItemIcon.js';
import InfoTableMainScroll from '../../../../common/scroll/InfoTableMainScroll.js';
import ArmyUnitsTable from './ArmyUnitsTable.js';
import ParameterCell from './components/ParameterCell.js';


export default class SoldiersTable extends ArmyUnitsTable {

    async create() {
        super.create();

        const w = this.width;
        const h = this.height;

        this.add(this.moduleScroll = new InfoTableMainScroll(this.scene, 0, 0, [], w, h, (data) => {

            for (let key in data) {
                if (!key.includes('soldier_module')) continue;

                let icon = new ItemIcon(this.scene, 0, 0, [], 'soldier_module_icons', h * 0.05, h * 0.05);
                icon.setIconData({ name: key, amount: data[key] });

                // Колбэк при клике на иконку
                icon.setClickCallback(() => {
                    this.applyModule(icon.getData('name'));
                });

                this.moduleScroll.addItem(icon);
            }

        }, {
            x: w * 0.193,
            y: h * -0.044,
            width: w * 0.27,
            height: h * 0.197,
            mode: 'vertical',
            space: h * 0.02,
            top: h * 0.01,
            bottom: h * 0.01,
            fixWidth: true,
            type: 'custom'
        }));
    }

    async update(category) {
        await super.update(category);

        let items = this.scene.game.registry.get('items');
        this.updateModuleScroll(items);
    }

    updateModuleScroll(data) {
        this.moduleScroll.updateContent(data);
    }

    addContent(w, h) {
        super.addContent(w, h);

        this.moduleCells = [
            new ParameterCell(this.scene, w * 0.092, h * -0.237, [], w, h, this, 'health'),
            new ParameterCell(this.scene, w * 0.133, h * -0.237, [], w, h, this, 'armor'),
            new ParameterCell(this.scene, w * 0.174, h * -0.237, [], w, h, this, 'damage'),
            new ParameterCell(this.scene, w * 0.215, h * -0.237, [], w, h, this, 'accuracy'),
            new ParameterCell(this.scene, w * 0.256, h * -0.237, [], w, h, this, 'speed'),
            new ParameterCell(this.scene, w * 0.297, h * -0.237, [], w, h, this, 'range'),
        ];
        this.add(this.moduleCells);

        this.addImplants(w, h);
    }

    addImplants(w, h) {
        let dist = 0;
        const x = w * 0.125;
        const y = h * 0.145;
        const size = h * 0.04;

        for (let i = 0; i < 3; i++) {
            this.add(this.scene.add.sprite(x + dist, y, 'UI', 'lock.png').setDisplaySize(size, size));
            dist += w * 0.0695;
        }
    }

    updateInfo(data) {
        super.updateInfo(data);
        if (!data) return;

        const modules = data['unit']['modules'];

        this.moduleCells.forEach(cell => cell.clear());

        for (let module of modules) {
            const cell = this.moduleCells.find(cell => cell.category == module.category);
            if (cell) {
                cell.addModule(module.name, this.selectedUnit.unitData['id']);
            }
        }
    }

    async applyModule(moduleName) {
        const data = await apiClient.units.soldiers.applyModule({
            module_name: moduleName,
            soldier_id: this.selectedUnit.unitData['id']
        });

        if (data['status'] === 'success') {
            this.scene.game.registry.set('items', data['items']);
            this.update();
        } else {
            InfoTables.showWarningText(data['message']);
        }
    }
}




import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN, TEXT_STYLE_ORANGE } from "../../../../../../utils/textStyles";

export default class OreRateInfo extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, cell, ore) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.cell = cell;
        this.ore = ore;

        this.create(this.cell.displayWidth, this.cell.displayHeight);
    }

    create(w, h) {
        this.add(this.amountText = this.scene.add.text(h * -0.07, h * 0.55, '0').setOrigin(0, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.17));
        this.add(this.maxText = this.scene.add.text(h * -0.12, h * 0.82, '0').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.17));
        this.add(this.rateText = this.scene.add.text(w * 0.175, h * 0.55, '0').setOrigin(1, 0.5).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.17));
    }

    update() {
        const items = this.scene.game.registry.get('items');
        this.amountText.setText(items[this.ore]);
    }

    // update(items) {
    //     this.amountText.setText(items[this.itemName]);
    //     this.maxText.setText(items[this.itemName + '_max']);

    //     let sceneName;
    //     switch (this.itemName) {
    //         case 'iron_ore': sceneName = 'Iron_Mine'; break;
    //         case 'silicon_ore': sceneName = 'Silicon_Quarry'; break;
    //         case 'titan_ore': sceneName = 'Titan_Mine'; break;
    //     }

    //     let totalRate = 0;
    //     let text = this.rateText;
    //     getWorkingMachines(sceneName).done( data => {
    //         let machines = $.parseJSON(data);

    //         machines.forEach( machine => {
    //             totalRate += +machine['rate'];
    //         });

    //         text.setText(totalRate.toString());
    //     });
    // }
}
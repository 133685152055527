import UIController from "../../../../../../../managers/UIController";
import InfoTables from "../../../../../../../scenes/ui/InfoTables";
import apiClient from "../../../../../../../services/apiClient";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN } from "../../../../../../../utils/textStyles";
import { multiplyObjectValues } from "../../../../../../../utils/utils";
import ActionButton from "../../../../../../main/buttons/common/ActionButton";


export class CharacterEquipmentInfo extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th, unitName) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.unitName = unitName;

        this.w = tw;
        this.h = th;

        this.addInfo(tw, th);
    }

    addInfo(w, h) {
        this.add(this.modImage = this.scene.add.image(w * 0.0234, h * -0.3472, 'character_equipment')
            .setOrigin(0, 0).setDisplaySize(h * 0.217, h * 0.217));

        const size = h * 0.04;
        const y = h * -0.24;

        this.add(this.scene.add.sprite(w * 0.192, y, 'UI', 'lock.png')
            .setOrigin(0.5, 0.5).setDisplaySize(size, size));
        this.add(this.scene.add.sprite(w * 0.262, y, 'UI', 'lock.png')
            .setOrigin(0.5, 0.5).setDisplaySize(size, size));

        this.add(this.lock = this.scene.add.sprite(w * 0.083, y, 'UI', 'lock.png')
            .setOrigin(0.5, 0.5).setDisplaySize(h * 0.08, h * 0.08).setVisible(false));

        this.add(this.modName = this.scene.add.text(w * 0.22, h * -0.29, 'Нет данных')
            .setOrigin(0.5, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(w * 0.013));

        this.add(this.modLevelText = this.scene.add.text(w * 0.054, h * -0.146, '')
            .setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(w * 0.014));

        this.add(this.upButton = new ActionButton(this.scene, w * 0.305, h * 0.128, [], 'table_button', h * 0.069, h * 0.07, 'green', this.upgradeEquipment.bind(this)));

        this.add(this.description = this.scene.add.text(w * 0.05, h * -0.11, '').setOrigin(0, 0).setStyle({
            fontFamily: 'Game_Font',
            fontSize: window.screen.height * 0.015,
            color: '#bbfcff',
            shadow: { fill: true, offsetX: 0, offsetY: 2, blur: 0 },
            align: 'justify',
            wordWrap: { width: w * 0.3 },
        }).setLineSpacing(h * 0.003));
    }

    update(data, stats) {
        console.log(data, stats);

        this.info = data;
        this.equipmentStats = stats;

        let firstKey;
        for (let key in data) {
            firstKey = key;
            break;
        }

        this.displayEquipmentInfo(this.curPart || firstKey);
    }

    displayEquipmentInfo(key) {
        if (!key) {
            this.showPlaceHolder();
            return;
        }

        const w = this.w;
        const h = this.h;

        this.curPart = key;
        const data = this.info[key];

        this.modName.setText(data['name']);
        this.description.setText(data['description']);

        this.modImage.setFrame(`${this.unitName}_${key}.png`);

        if (this.itemsText) {
            this.itemsIcons.forEach(t => t.destroy());
            this.itemsText.forEach(t => t.destroy());
        }

        const stats = this.equipmentStats[key];

        UIController.arrangeItems({
            container: this,
            itemsData: multiplyObjectValues(data["materials"], stats['level']),
            x: w * 0.082,
            y: h * 0.03,
            iconSize: h * 0.035,
            fontSize: h * 0.02,
            space: w * 0.055,
        });

        let items = this.scene.game.registry.get('items');
        UIController.updateItemsText(items, multiplyObjectValues(data["materials"], stats['level']), this.itemsText);
        this.modLevelText.setText(stats['level']);
        this.setParametersText(w, h, data['parameters'], stats);
    }

    // Обновляем текст параметров
    setParametersText(w, h, params, stats) {
        // Удаляем предыдущий текст
        if (this.paramsText && this.paramsText.length > 0) {
            this.remove(this.paramsText);
            this.paramsText.forEach(text => text.destroy());

            this.remove(this.parIcons);
            this.parIcons.forEach(icon => icon.destroy());
        }

        let fontSize = w * 0.01;
        let dist = 0;
        this.parIcons = [];
        this.paramsText = [];

        params.forEach(key => {
            this.parIcons.push(this.scene.add.sprite(w * 0.153 + dist, h * -0.17, 'parameters_icons', `${key}.png`).setDisplaySize(h * 0.04, h * 0.04).setOrigin(1, 0.5));

            let text = this.scene.add.text(w * 0.155 + dist, h * -0.17, stats[key] ?? '0');
            text.setStyle(TEXT_STYLE_GREEN).setFontSize(fontSize).setOrigin(0, 0.5);

            this.paramsText.push(text);
            dist += w * 0.055;
        })

        this.add(this.parIcons);
        this.add(this.paramsText);

        this.displayNewParams(w, h, params);
    }

    // Количество прибавляемых параметров
    displayNewParams(w, h, params) {
        if (this.newParamsText && this.newParamsText.length > 0) {
            this.remove(this.newParamsText);
            this.newParamsText.forEach(text => text.destroy());

            this.remove(this.newParIcons);
            this.newParIcons.forEach(icon => icon.destroy());
        }

        this.newParIcons = [];
        this.newParamsText = [];

        let fontSize = w * 0.009 + 'px';
        let dist = 0;

        params.forEach(key => {
            this.newParIcons.push(this.scene.add.sprite(w * 0.102 + dist, h * 0.085, 'parameters_icons', `${key}.png`).setDisplaySize(h * 0.035, h * 0.035).setOrigin(1, 0.5));

            let text = this.scene.add.text(w * 0.105 + dist, h * 0.085, '');
            text.setStyle(TEXT_STYLE_GREEN).setFontSize(fontSize).setOrigin(0, 0.5);

            switch (key) {
                case 'armor':
                    text.setText('+3');
                    break;
                case 'damage':
                    text.setText('+5');
                    break;
                case 'close_damage':
                    text.setText('+5');
                    break;
                case 'rocket_damage':
                    text.setText('+5');
                    break;
                case 'accuracy':
                    text.setText('+3');
                    break;
                case 'range':
                    if (parseInt(this.modLevelText) % 5 == 0) {
                        text.setText('+1');
                    } else {
                        text.setText('-');
                    }
                    break;
                case 'open_speed':
                    text.setText('+3');
                    break;
                case 'extract_speed':
                    text.setText('+3');
                    break;
                case 'find_speed':
                    text.setText('+3');
                    break;
                case 'fint_amount':
                    text.setText('+1');
                    break;
                default:
                    text.setText('+3');
                    break;
            }

            this.newParamsText.push(text);
            dist += w * 0.045;
        })

        this.add(this.newParIcons);
        this.add(this.newParamsText);
    }
    // Добавляем текст ресурсов
    addResText(w, h) {
        if (this.resText && this.resText.length > 0) {
            this.remove(this.resText);
            this.resText.forEach(text => text.destroy());

            this.remove(this.resIcons);
            this.resIcons.forEach(text => text.destroy());
        }

        // Иконки ресурсов
        this.resIcons = [];

        let dist = 0;
        this.resources.forEach(res => {
            this.resIcons.push(this.scene.add.sprite(w * 0.082 + dist, h * 0.03, 'items_icons', `${res['name']}.png`).setDisplaySize(h * 0.035, h * 0.035).setOrigin(1, 0.5));
            dist += w * 0.055;
        });
        this.add(this.resIcons);

        // Текст количества ресурсов
        dist = 0;
        this.resText = [];

        this.resources.forEach((res, index) => {
            let text = this.scene.add.text(w * 0.087 + dist, h * 0.03, '').setOrigin(0, 0.5);
            text.setText(this.resources[index].amount.toString());

            this.resText.push(text);
            dist += w * 0.055;
        })
        this.add(this.resText);

        updateResText(w * 0.01, this.resources, this.resText);
    }

    async upgradeEquipment() {
        const data = await apiClient.units.characters.upgradeEquipment({
            character: this.unitName,
            name: this.curPart
        });

        if (data['equipment']) {
            this.equipmentStats = data['equipment'];
            this.parentContainer.updateInfo(data);
            // this.displayEquipmentInfo(this.curPart);
        }
        InfoTables.showWarningText(data['message']);
    }

    showPlaceHolder() {
        this.modImage.setVisible(false);
        this.upButton.disable();
        this.lock.setVisible(true);
        this.description.setText('Будет доступно в одном из обновлений');
    }
}
import BuildingTableContainer from './common/BuildingTableContainer.js';
import FactoryProductionTable from '../tables/production/FactoryProductionTable.js';
import IronFoundryTable from '../tables/production/IronFoundryTable.js';


export default class IronFoundryTableContainer extends BuildingTableContainer {

    addInfoTables() {
        this.tableLeft = null;
        this.tableCenter = new FactoryProductionTable(this.scene, 0, 0, [], this.w, this.h, 'iron_foundry_production');
        this.tableRight = new IronFoundryTable(this.scene, 0, 0, [], this.w, this.h, 'iron_foundry');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}

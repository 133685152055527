import { TEXT_STYLE_ORANGE } from "../../../../../../utils/textStyles";
import ShopCell from "./ShopCell";

export default class ShopSection extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th, data) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.setData('data', data);

        this.create(tw, th);
    }

    create(w, h) {
        const data = this.getData('data');
        const items = data['items'];

        this.cells = [
            new ShopCell(this.scene, w * -0.23, 0, [], w, h, data['content'][0]),
            new ShopCell(this.scene, w * -0.01, 0, [], w, h, data['content'][1]),
            new ShopCell(this.scene, w * 0.21, 0, [], w, h, data['content'][2])
        ];
        this.add(this.cells);

        // Название секции
        this.add(this.title = this.scene.add.text(w * -0.32, h * -0.11, data['title'])
            .setOrigin(0, 1).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.025));

        // Иконки предметов
        let dist = w * 0.32;
        for (let i = items.length - 1; i >= 0; i--) {
            let texture = items[i].includes('soldier_module') ? 'soldier_module_icons' : 'items_icons';
            let size = h * 0.04;
            let origin = 1;

            if (items[i].includes('container') && !items[i].includes('key_container')) {
                texture = 'container_icons';
                size = h * 0.09;
                origin = 0.8;
            }

            this.add(this.scene.add.image(dist, h * -0.11, texture, `${items[i]}.png`).setOrigin(1, origin).setDisplaySize(size, size));
            dist -= (texture == 'container_icons') ? h * 0.09 : h * 0.06;
        }

        // Линия
        this.add(this.scene.add.line(0, 0, 0, h * -0.1, w * 0.64, h * -0.1, 0x87e9ff));
        this.setSize(w * 0.65, h * 0.26);
    }

    linkTable(table) {
        this.cells.forEach(cell => cell.linkTable(table));
    }
}
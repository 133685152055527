import { TEXT_STYLE_ORANGE } from "../../../utils/textStyles";

export default class WarningText extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.create(window.screen.height);
    }

    create(h) {
        this.bg = this.scene.add.image(0, 0, 'UI', 'warning_bg.png').setOrigin(0.5, 0.5)
            .setDisplaySize(h * 0.7, h * 0.05)
            .setAlpha(0);

        this.txt = this.scene.add.text(0, 0, '').setOrigin(0.5, 0.5)
            .setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.02)
            .setAlpha(0);

        this.add(this.bg);
        this.add(this.txt);
    }

    update() {
        this.bg.setPosition(window.innerWidth / 2, window.innerHeight * 0.12);
        this.txt.setPosition(window.innerWidth / 2, window.innerHeight * 0.12);
    }

    show(text) {
        const txt = this.txt;
        const bg = this.bg;
        const scene = this.scene;

        txt.setText(text).setAlpha(1);
        bg.setAlpha(1);

        if (scene.fade) {
            scene.fade.remove();
        }

        scene.fade = scene.tweens.add({
            targets: [txt, bg],
            delay: 2000,
            alpha: 0,
            ease: 'Linear',
            duration: 300,
            repeat: false,
        });
    }
}
import { getRandomArrayElement } from "../../../../../utils/utils";
import Bullet from "../../common/Bullet";
import BattleCharacter from "./BattleCharacter";

export default class BattleChlapidol extends BattleCharacter {

    setUnitData(unitData, linkedIcon) {
        super.setUnitData(unitData, linkedIcon);
        const config = this.getData('config');

        config['priority'] = 'animal';
        config['close_fight_anims'] = ['butcher'];
        config['skills'] = ['fear'];
    }

    shoot(x, y, dir, target) {
        const w = this.unitSprite.displayWidth;

        const positions = {
            'right': { x: w * 0.4, y: w * -0.2 },
            'left': { x: w * -0.4, y: w * -0.2 },
            'down-r': { x: w * 0.25, y: w * -0.1 },
            'down-l': { x: w * -0.25, y: w * -0.1 },
            'up-r': { x: w * 0.3, y: w * -0.3 },
            'up-l': { x: w * -0.3, y: w * -0.3 },
            'up': { x: 0, y: w * -0.4 },
            'down': { x: 0, y: 0 },
        }

        const bullet = new Bullet(this.scene, x + positions[dir].x, y + positions[dir].y, 'flying_net');
        const localPos = target.unitSprite.displayWidth / 2;

        bullet.create(x, y, target.x + localPos, target.y + localPos);
        bullet.move(target.x, target.y);
    }

    extraShootAction(target) {
        target.paralyse(1000 * 60 * 60);

        target.add(this.coverNet = this.scene.add.sprite(0, 0, 'cover_net').setOrigin(0.5, 0.5));
        this.coverNet.setDisplaySize(this.unitSprite.displayWidth * 0.6, this.unitSprite.displayWidth * 0.4);

        this.getData('unit')['range'] = 1;
    }

    onTargetDie(target) {
        super.onTargetDie(target);
        this.getData('unit')['range'] = 5;
        this.obtainOrgan();
    }

    obtainOrgan() {
        const organProbabilities = {
            'blood': 40,
            'bone_brain': 25,
            'claw': 20,
            'eye': 3,
            'brain': 1,
        };
        const array = [];

        for (let key in organProbabilities) {
            for (let i = 0; i < organProbabilities[key]; i++) {
                array.push(key);
            }
        }

        const organ = getRandomArrayElement(array);
        setTimeout(this.createPopText(` + 1`, organ), 700);

        const extraRewards = this.scene.data.get('extra_rewards');

        if (!extraRewards[organ]) {
            extraRewards[organ] = 1;
        } else {
            extraRewards[organ]++;
        }
    }
}
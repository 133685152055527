import BuildingTableContainer from './common/BuildingTableContainer.js';
import CharacterTable from '../tables/units/characters/CharacterTable.js';
import BuildingProductionTable from '../tables/production/BuildingProductionTable.js';


export default class TechCenterTableContainer extends BuildingTableContainer {

    addInfoTables() {
        const items = [
            'battery_units', 
            'battery_air', 
            'battery_tech', 
            'battery_robots', 
            'battery_military'
        ];

        this.tableLeft = null;
        // this.tableLeft = new TableSkillsMainHero(this.scene, 0, 0, [], this.w, this.h, 'TechNastya_skills');
        this.tableCenter = new CharacterTable(this.scene, 0, 0, [], this.w, this.h, 'TechNastya');
        this.tableRight = new BuildingProductionTable(this.scene, 0, 0, [], this.w, this.h, 'tech_center', items);

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}
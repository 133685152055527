import JournalScrollItem from "./JournalScrollItem";
import JournalSideScroll from "./JournalSideScroll";

export default class JournalScrollStory extends JournalSideScroll {

    addItems(storyData) {
        this.clear();
        
        const story = storyData['story'];
        const data = storyData['data']['story'];

        const active = [];
        const claimed = [];

        for (let index in story) {
            const mission = story[index];
            const status = mission['status'];

            if (status == 'active') {
                active.push(mission);
            }
            else if (status == 'claimed') {
                claimed.push(mission);
            }
            else if (status !== 'blocked') {
                this.addMission(mission, data);
            }
        }

        active.forEach(mission => this.addMission(mission, data));
        claimed.forEach(mission => this.addMission(mission, data));

        this.scroll.items[0].button.click();
    }

    addMission(mission, data) {
        const id = mission['mission_id'];
        const status = mission['status'];
        const missionData = data[id];

        let cell = new JournalScrollItem(this.scene, 0, 0, [], 'journal_character_icons', missionData['hero'], missionData['title'], id);
        this.scroll.addItem(cell);
        
        if (status == 'complete') {
            cell.complete();
        } else if (status == 'claimed') {
            cell.claim();
        }
    }
}
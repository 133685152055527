import InteractiveObject from "../../InteractiveObject";

export default class ArrowButton extends InteractiveObject {
    constructor(scene, x, y, children, texture, width, height, action, cell) {
        super(scene, x, y, children, texture, width, height);

        this.action = action;
        this.cell = cell;

        if (this.action == 'decrease') {
            this.obj.flipX = true;
        }
    }

    create() {
        super.create();
        this.obj.setFrame('arrow.png')
            .setDisplaySize(this.width, this.height)
            .setOrigin(0.5, 0.5);
    }

    makeInteractive() {
        super.makeInteractive();
        this.setAlpha(1);
    }

    pointerOver() {
        super.pointerOver();
        this.obj.setTint(0xbdbdbd);
    }

    pointerOut() {
        super.pointerOut()
        this.obj.clearTint();
    }

    click() {
        super.click();

        if (this.action == 'decrease') {
            if (this.cell.amountToProduce > 1) {
                this.cell.amountToProduce--;
            }
        } else {
            this.cell.amountToProduce++;
        }

        this.cell.updateText();
    }

    disable() {
        super.disable();
        this.setAlpha(0.5);
    }
}
import UIController, { ScrollManager } from '../../../../../managers/UIController.js';
import SingleInfoTable from '../common/SingleInfoTable.js';
import apiClient from '../../../../../services/apiClient.js';
import InfoTableMainScroll from '../../../common/scroll/InfoTableMainScroll.js';
import UnitIcon from '../../../../main/icons/UnitIcon.js';
import LookoutSection from './components/LookoutSection.js';


export default class LookoutTable extends SingleInfoTable {

    create() {
        super.create();
        const w = this.width;
        const h = this.height;

        this.add(this.mainScroll = new InfoTableMainScroll(this.scene, 0, 0, [], w, h, (data) => {

            for (let index in data) {
                if (data[index]['status'] == 'lookout') {
                    this.setupLookout(data[index]);
                    continue;
                }

                let icon = new UnitIcon(this.scene, 0, 0, [], 'button', h * 0.1, h * 0.1);
                icon.setUnitData(data[index], index);

                // Колбэк при клике на иконку юнита
                icon.setClickCallback(() => {
                    this.mainScroll.deselectAll();
                    icon.select();
                    this.selectedUnit = icon;
                    this.selectedUnitIndex = icon.index;
                    this.selectUnit(icon);
                });

                this.mainScroll.addItem(icon);
            }
        }));
    }

    addInfo(w, h) {
        this.arrangeCells(w, h);
    }

    async arrangeCells(w, h) {
        const scrollW = w * 0.65;
        const scrollH = h * 0.69;
        const spaceTop = h * 0.03;

        this.scroll = new ScrollManager(this.scene, {
            x: 0,
            y: h * -0.033,
            width: scrollW,
            height: scrollH,

            scrollMode: 0,

            space: {
                panel: {
                    top: spaceTop,
                    bottom: spaceTop * 3,
                }
            }
        }, {
            space: {
                line: spaceTop,
            },
        });

        this.addSections(w, h);
        this.add(this.scroll.scrollPanel);
    }

    async update(category) {
        await super.update(category);

        const data = await apiClient.units.getLookout();
        console.log(data);

        if (data['status'] != 'success') {
            return;
        }

        this.lookoutCellsData = data['data']['cells'];
        this.lookoutData = data['data']['lookouts'];
        this.lookouts = [];

        if (!this.sectionsAdded) {
            this.addInfo(this.width, this.height);
        }
        this.updateInfo(this.lookoutCellsData);
        this.updateMainScroll(data['data']['units']['units']);
    }

    addSections(w, h) {
        const data = this.lookoutCellsData;

        for (let key in data) {
            let section = new LookoutSection(this.scene, 0, 0, [], w, h, key, data[key]);
            this.scroll.addItem(section);
        }

        this.sectionsAdded = true;
    }

    async updateInfo(data) {
        for (let item of this.scroll.items) {
            item.update(data[item.area]);
        }
    }

    updateMainScroll(data) {
        this.mainScroll.updateContent(data);
    }

    resetCells() {
        const sections = this.scroll.getAll();
        sections.forEach(section => section.reset());
    }

    selectUnit(icon) {
        icon.select();
        this.scene.game.registry.set('selectedLookout', icon.unitData);

        const sections = this.scroll.getAll();

        sections.forEach(section => {
            section.reset();
            section.prepare();
        });
    }

    setupLookout(unitData) {
        if (this.lookouts.find(id => id == unitData['id'])) {
            return;
        }
        const lookout = this.lookoutData.find(lookout => lookout['unit_id'] == unitData['id']);
        if (!lookout) {
            return;
        }
        const area = lookout['area'];
        const sections = this.scroll.getAll();

        sections.forEach(section => {
            if (section.area == area) {
                let freeCell = section.cells.find(cell => cell.isEmpty);
                console.log(freeCell);

                if (freeCell) {
                    freeCell.addUnit(unitData, lookout);
                    this.lookouts.push(unitData['id']);
                }
            }
        })
    }
}

import WorldArea from '../../modules/scene/objects/WorldArea.js';
import apiClient from '../../services/apiClient.js';
import MapScene from '../core/mapScene.js';

export default class World extends MapScene {
    constructor() {
        super('World');
    }

    async loadAssets(data) {
        this.load.image('table_explore', 'UI/info_tables/common/table_explore.png');

        const response = await apiClient.locations.world.getData();
        console.log(response);
        this.areasData = response['data']['areas'];
        this.locationData = response['locations'];
        this.processData = response['processes'];

        for (let key in this.areasData) {
            this.load.image(`world_${key}`, `./scenes/World/areas/${key}.png`);
        }

        super.loadAssets(data);
    }

    createScene() {
        super.createScene();

        // Массив активных (выбранных) зон, которые всегда должны отображаться поверх других
        this.map.activeAreas = [];

        for (let key in this.areasData) {
            let d = this.areasData[key];
            d['scene'] = key;
            this.map.add(this.map[key] = new WorldArea(this, d['x'], d['y'], [], `world_${key}`, d['w'], d['h'], d));
        }

        const data = this.locationData;

        for (let id in data) {
            let area = this.map[data[id]['name']];
            if (area) {
                area.setObjectData(data[id]);
                console.log(this.processData);
                area.checkProcessExist(this.processData);
            }
        }
    }
}

import containerData from '../../../../../config/shop/containers.js';
import InfoTables from '../../../../../scenes/ui/InfoTables.js';
import apiClient from '../../../../../services/apiClient.js';
import { TEXT_STYLE_GREEN, TEXT_STYLE_ORANGE } from '../../../../../utils/textStyles.js';
import ItemIcon from '../../../../main/icons/ItemIcon.js';
import InfoTableMainScroll from '../../../common/scroll/InfoTableMainScroll.js';
import SingleInfoTable from '../common/SingleInfoTable.js';
import ContainerCell from './components/ContainerCell.js';


export default class OpenContainerTable extends SingleInfoTable {
    constructor(scene, x, y, children, width, height, texture) {
        super(scene, x, y, children, width, height, texture);
    }

    create() {
        super.create();
        const w = this.width;
        const h = this.height;

        this.addContainers(w, h);
        this.addHackTools(w, h);
        this.addKeys(w, h);

        this.containerCells = [
            this.cellOpen = new ContainerCell(this.scene, w * -0.268, h * -0.24, [], w, h, this, 'open'),
            this.cellHack = new ContainerCell(this.scene, w * 0.268, h * -0.24, [], w, h, this, 'hack'),
        ];
        this.add(this.containerCells);

        this.containerCells.forEach(cell => {
            cell.on('addcontainer', () => {
                this.containerCells.forEach(c => c.disable());
                cell.enable(cell.curContainer);
            });
        });

        this.addText(w, h);
    }

    addText(w, h) {
        this.add(this.containerName = this.scene.add.text(0, h * -0.34, '').setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.022));

        const text = 'Выберите контейнер, который хотите открыть или взломать';
        this.add(this.text = this.scene.add.text(0, h * 0.27, text).setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.022));
    }

    updateText(status, containerName = '') {
        const data = containerData[containerName];
        this.containerName.setText(data ? data['name'] : '');
        let text = '';

        switch (status) {
            case 'select':
                text = 'Установите контейнер в одну из ячеек вверху';
                break;
            case 'hack':
                text = 'Выберите набор для взлома контейнера';
                break;
            default:
                text = 'Выберите контейнер, который хотите открыть или взломать';
                break;
        }

        this.text.setText(text);
    }

    async update(category) {
        await super.update(category);
        this.reset();

        let items = this.scene.game.registry.get('items');
        this.updateScrolls(items);

        this.containerCells.forEach(cell => cell.updateInfo());
        this.checkHacking();
    }

    reset() {
        this.containerCells.forEach(cell => cell.clear());
        this.updateText();
    }

    updateScrolls(data) {
        this.mainScroll.updateContent(data);
        this.keyScroll.updateContent(data);
        this.hackToolScroll.updateContent(data);
    }

    addContainers(w, h) {
        this.add(this.mainScroll = new InfoTableMainScroll(this.scene, 0, 0, [], w, h, (data) => {

            for (let key in data) {
                if (!key.includes('container') || (data[key] <= 0) || key.includes('key_container')) {
                    continue;
                }

                let icon = new ItemIcon(this.scene, 0, 0, [], 'container_icons', h * 0.1, h * 0.1);
                icon.setIconData({ name: key, amount: data[key] });

                // Колбэк при клике на иконку
                icon.setClickCallback(() => {
                    this.selectContainer(icon);
                });

                this.mainScroll.addItem(icon);
            }

        }));
    }

    addKeys(w, h) {
        this.add(this.keyScroll = new InfoTableMainScroll(this.scene, 0, 0, [], w, h, (data) => {

            for (let key in data) {
                if (!key.includes('key_container')) {
                    continue;
                }

                let icon = new ItemIcon(this.scene, 0, 0, [], 'items_icons', h * 0.06, h * 0.06);
                icon.setIconData({ name: key, amount: data[key] });

                icon.setClickCallback(() => {
                    console.log('key click');
                });

                this.keyScroll.addItem(icon);
            }

        }, {
            x: w * -0.177,
            y: h * 0.138,
            width: w * 0.31,
            height: h * 0.187,
            mode: 'vertical',
            space: h * 0.02,
            top: h * 0.01,
            bottom: h * 0.01,
            fixWidth: true,
            type: 'custom'
        }));
    }

    addHackTools(w, h) {
        this.add(this.hackToolScroll = new InfoTableMainScroll(this.scene, 0, 0, [], w, h, (data) => {

            for (let key in data) {
                if (!key.includes('hack_tool')) {
                    continue;
                }

                let icon = new ItemIcon(this.scene, 0, 0, [], 'items_icons', h * 0.06, h * 0.06);
                icon.setIconData({ name: key, amount: data[key] });

                icon.setClickCallback(() => {
                    this.selectHackTool(icon);
                });

                this.hackToolScroll.addItem(icon);
            }

        }, {
            x: w * 0.185,
            y: h * 0.138,
            width: w * 0.31,
            height: h * 0.187,
            mode: 'vertical',
            space: h * 0.02,
            top: h * 0.01,
            bottom: h * 0.01,
            fixWidth: true,
            type: 'custom'
        }));
    }

    selectHackTool(icon) {
        if (!this.cellHack.isPlaced) {
            InfoTables.showWarningText('Выберите контейнер для взлома');
            return;
        }

        if (this.cellHack.hackToolSelected) {
            InfoTables.showWarningText('Снимите текущий набор взлома');
        } else if (this.cellHack.isOpened) {
            InfoTables.showWarningText('Сейчас нельзя выбрать предмет');
        } else {
            this.cellHack.selectHackTool(icon);
        }
    }

    selectContainer(container) {
        this.mainScroll.deselectAll();
        container.select();
        this.updateText('select', container.getData('name'));

        this.containerCells.forEach(cell => {
            if (!cell.isPlaced) {
                cell.previewContainer(container);
            }
        });
    }

    async checkHacking() {
        const processes = await apiClient.processes.getAll();
        this.cellHack.checkProcessExists(processes);
    }
}

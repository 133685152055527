const dailyBonusData = {
    'resources': {
        content: [],
        rewards: [
            'container_batteries_1', 
            'container_crystalls_1', 
            'container_iron_1', 
            'container_silicon_1', 
            'container_titan_1', 
            'container_modules_1', 
            'container_genetic_1', 
            'container_research_1', 
            'container_devices_1', 
            'container_robots_1', 
            'container_morion_1', 
        ]
    },
    'modules': {
        content: [],
        rewards: [
            'container_modules_1', 
            'container_modules_1', 
            'container_modules_1', 
            'container_modules_1', 
            'container_modules_1', 
            'container_modules_1', 
            'container_modules_1', 
            'container_modules_1', 
            'container_modules_1', 
            'container_modules_1', 
            'container_modules_1', 
        ]
    },
    'keys': {
        content: [],
        rewards: [
            'container_keys_1', 
            'container_keys_1', 
            'container_keys_1', 
            'container_keys_1', 
            'container_keys_1', 
            'container_keys_1', 
            'container_keys_1', 
            'container_keys_1', 
            'container_keys_1', 
            'container_keys_1', 
            'container_keys_1', 
        ]
    },
}

export default dailyBonusData;
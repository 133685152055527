import { ScrollManager } from "../../../../../managers/UIController";
import ProductionCell from "./components/ProductionCell";


export default class ProductionContainer extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th, prodData) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.tw = tw;
        this.th = th;
        this.itemsData = prodData['data'];
        this.processes = prodData['processes'];

        this.addContent(tw, th);
    }

    addContent(tw, th) {
        const scrollW = tw * 0.24;
        const scrollH = th * 0.59;
        const spaceTop = th * 0.01;

        this.scroll = new ScrollManager(this.scene, {
            x: tw * 0.18,
            y: th * 0.02,
            width: scrollW,
            height: scrollH,

            scrollMode: 0,

            space: {
                top: spaceTop,
                bottom: spaceTop,
            }
        }, {
            space: {
                line: spaceTop,
            },
        });

        this.add(this.scroll.scrollPanel);
        this.addProductionCells();
    }

    addProductionCells() {
        for (let key in this.itemsData) {
            if (key == 'clone') {
                continue;
            }
            let cell = new ProductionCell(this.scene, 0, 0, [], this.tw * 0.24, this.tw * 0.06063, key, this.itemsData[key]);
            this.scroll.addItem(cell);
        }
    }

    update(data) {
        this.itemsData = data['data'];
        this.processes = data['processes'];

        for (let item of this.scroll.items) {
            item.update();
            item.checkProcessExists(this.processes);
        }
    }
}
import BuildingTableContainer from './common/BuildingTableContainer.js';
import ResearchLabTable from '../tables/buildings/ResearchLabTable.js';
import IrradiatedTable from '../tables/units/army_units/IrradiatedTable.js';


export default class ResearchLabTableContainer extends BuildingTableContainer {

    addInfoTables() {
        this.tableLeft = null;
        this.tableCenter = new IrradiatedTable(this.scene, 0, 0, [], this.w, this.h, 'irradiated');
        this.tableRight = new ResearchLabTable(this.scene, 0, 0, [], this.w, this.h, 'research_lab');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}


import JournalCharacterInfo from "../info/JournalCharacterInfo";
import JournalScrollCharacters from "../scroll/JournalScrollCharacters";
import JournalPage from "./JournalPage";

export default class JournalCharacterPage extends JournalPage {

    create() {
        super.create();

        this.add(this.info = new JournalCharacterInfo(this.scene, window.screen.width * -0.19, window.screen.height * -0.33, []));
        this.add(this.scroll = new JournalScrollCharacters(this.scene, 0, 0, []));
    }
}
import BuildingTableContainer from './common/BuildingTableContainer.js';
import ArmyUnitsEvolutionTable from '../tables/units/army_units/ArmyUnitsEvolutionTable.js';
import BarraksTable from '../tables/buildings/BarraksTable.js';
import SoldiersTable from '../tables/units/army_units/SoldiersTable.js';


export default class BarraksTableContainer extends BuildingTableContainer {

    addInfoTables() {
        this.tableLeft = new ArmyUnitsEvolutionTable(this.scene, 0, 0, [], this.w, this.h, 'soldier_evolution');
        this.tableCenter = new SoldiersTable(this.scene, 0, 0, [], this.w, this.h, 'soldier');
        this.tableRight = new BarraksTable(this.scene, 0, 0, [], this.w, this.h, 'barraks');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}

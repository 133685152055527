import AudioManager from '../../../../managers/AudioManager';

export default class Lightning extends Phaser.GameObjects.Sprite {
    constructor(scene, x, y, texture, frame) {
        super(scene, x, y, texture, frame);
        scene.add.existing(this);

        this.scene = scene;
        this.textureKey = texture;
        this.frameKey = frame;
    }

    create(x, y, target, duration) {
        this.setOrigin(0, 0.5);

        this.anims.play(this.textureKey);

        this.update(x, y, target);
        this.scene.map.add(this);

        const tween = this.scene.tweens.add({
            targets: this,
            duration: duration,
            x: this.x,
            repeat: false,
            onUpdate: () => {
                if (target.state == 'dead') {
                    tween.remove();
                    this.destroy();
                }
                this.update(x, y, target);
            },
            onComplete: () => this.destroy(),
        });

        switch (this.texture) {
            case 'lightning_strike':
                AudioManager.playSound('attack', 'lightning');
                break;
            case 'burner_flame':
                AudioManager.playSound('attack', 'techNastya');
                break;
        }
    }

    update(x, y, target) {
        const spriteW = target.unitSprite.displayWidth;
        const sh = window.screen.height;

        let targetX = target.x;
        const dif = Math.floor(targetX - x);

        if (dif > 0) {
            targetX = target.x - spriteW / 2;
        } else
            if (dif < 0) {
                targetX = target.x + spriteW / 2;
            }

        const targetY = target.y + spriteW * 0.2;
        const dx = x - targetX;
        const dy = y - targetY;

        const dist = Math.floor(Math.sqrt(Math.abs(dx * dx + dy * dy)));

        let h = sh * 0.07;

        if (this.textureKey == 'burner_flame') {
            h = sh * 0.04;
        }
        this.setDisplaySize(dist, h);

        const angle = Math.atan((targetY - y) / Math.abs(targetX - x));
        this.setRotation((dif < 0) ? (Math.PI - angle) : angle);
    }
}
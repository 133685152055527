import UIController from "../../../managers/UIController";
import { TEXT_STYLE_BLUE } from "../../../utils/textStyles";
import ActionButton from "../../main/buttons/common/ActionButton";
import apiClient from "../../../services/apiClient";
import InfoTables from "../../../scenes/ui/InfoTables";
import machineData from "../../../config/machines/machineData";

export default class FixMachineTable extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, machine) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.machine = machine;
        this.machineInfo = this.machine.machineData;

        this.resText = [];
        this.create(window.screen.width);
    }

    create(w) {
        this.add(this.table = this.scene.add.image(0, 0, 'table_fix').setOrigin(0, 1)
            .setDisplaySize(w * 0.24, w * 0.084).setInteractive());

        const tw = this.table.displayWidth;
        const th = this.table.displayHeight;

        // Название здания
        this.add(this.name = this.scene.add.text(tw * 0.6, th * -0.77, machineData[this.machineInfo['name']]['name'])
            .setOrigin(0.5, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(w * 0.011));

        // Кнопка восстановить
        this.add(this.button = new ActionButton(this.scene, tw * 0.203, th * -0.684, [], 'table_button', tw * 0.12, tw * 0.12, 'green', this.fix.bind(this)));

        this.handleButtonClick();
        this.addText(tw, th);
    }

    handleButtonClick() {
        this.button.obj.on('pointerdown', () => {
            this.scene.input.off('pointerdown');
            this.button.obj.on('pointerup', () => {
                this.scene.map.handleMoves();
            });
        });
    }

    async fix() {
        const data = await apiClient.machines.fix({
            id: this.machineInfo['id'] || null,
            name: this.machineInfo['name']
        });

        if (data['status'] != 'success') {
            return;
        }

        InfoTables.showWarningText(data['message']);

        if (data['message'] == 'Не достаточно ресурсов') {
            return;
        }

        this.machine.machineData = data['machine'];
        this.machine.fix();
        this.destroy();
    }

    addText(w, h) {
        UIController.arrangeItems({
            container: this,
            itemsData: machineData[this.machineInfo["name"]]['fix_materials'],
            x: w * 0.22,
            y: h * -0.25,
            iconSize: h * 0.2,
            fontSize: h * 0.12,
            space: w * 0.185,
        });

        this.add(this.title = this.scene.add.text(w * 0.3, h * -0.62, 'Отремонтировать').setOrigin(0, 0).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.15));
        this.updateText();
    } 

    updateText() {
        let items = this.scene.game.registry.get('items');
        UIController.updateItemsText(items, machineData[this.machineInfo["name"]]['fix_materials'], this.itemsText);
    }
}

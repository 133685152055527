import InfoTables from "../../../../../../scenes/ui/InfoTables";
import apiClient from "../../../../../../services/apiClient";
import InteractiveObject from "../../../../../main/InteractiveObject";

export default class ShopReward extends InteractiveObject {
    constructor(scene, x, y, children, texture, width, height) {
        super(scene, x, y, children, texture, width, height);
    }

    create() {
        const h = this.height;
        this.addAt(this.shine = this.scene.add.image(0, 0, 'shine').setOrigin(0.5, 0.5).setDisplaySize(h * 1.1, h * 1.1), 0);
        super.create();

        let isKey = this.texture.includes('key_container');
        let texture = isKey ? 'items_icons' : 'container_icons';

        this.obj.setTexture(texture, `${this.texture}.png`).setDisplaySize(h, h).setOrigin(0.5, 0.5);
        this.add(this.cover = this.scene.add.sprite(0, 0, texture, `${this.texture}.png`)
            .setOrigin(0.5, 0.5).setDisplaySize(h, h)).setAlpha(0);

        this.setAlpha(1);
    }

    makeInteractive() {
        super.makeInteractive();
        this.obj.setAlpha(1);
        this.shine.setAlpha(1);
    }

    pointerOver() {
        super.pointerOver();
        this.obj.setTint(0xbdbdbd);
    }

    pointerOut() {
        super.pointerOut()
        this.obj.clearTint();
    }

    async click() {
        super.click();
        this.blinkOff();

        const data = await apiClient.shop.claimReward({
            type: 'shop_' + this.parentContainer.shopCategory,
            reward: this.texture
        });

        if (data['status'] != 'success') {
            return;
        }

        InfoTables.showWarningText('Награда получена!');
        this.scene.game.registry.set('items', data['items']);
        this.disable();
    }

    disable() {
        this.blinkOff();
        super.disable();
        this.obj.setAlpha(0.7);
        this.shine.setAlpha(0);
    }

    blink() {
        this.makeInteractive();
        this.blinkFX && this.blinkFX.remove();
        this.cover.setTint(0x18ff00).setAlpha(0);

        this.blinkFX = this.scene.tweens.add({
            targets: this.cover,
            alpha: 1,
            duration: 700,
            ease: 'Power1',
            repeat: -1,
            yoyo: true,
        });
    }

    blinkOff() {
        this.blinkFX && this.blinkFX.remove();
        this.cover.setAlpha(0);
        this.makeInteractive();
    }
}
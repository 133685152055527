import ItemIcon from "../../../../../main/icons/ItemIcon";

export default class InjectionCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.tw = tw;
        this.th = th;

        this.create(tw, th);
        this.isFree = true;
    }

    create(w, h) {
        this.add(this.frame = this.scene.add.sprite(0, 0, 'UI', 'table_button.png').setOrigin(0.5, 0.5));
        this.frame.setDisplaySize(h * 0.076, h * 0.076);

        this.frame.setRotation(Math.PI / 2);

        this.frame.setTint(0xffcb6a);
        this.frame.setAlpha(0.001);
    }

    addInjection(key, injection) {
        const x = this.frame.displayWidth / 2 - this.th * 0.053 / 2;
        const y = 0;

        this.add(this.icon = new ItemIcon(this.scene, x, y, [], 'items_icons', this.th * 0.05, this.th * 0.05));
        this.icon.setIconData({ name: key, amount: 0 });
        this.icon.setClickCallback(this.removeInjection.bind(this));

        this.icon.amountText.destroy(); // удаляем ненужный текст количества
        this.frame.setAlpha(1);
        this.injection = injection;

        this.isFree = false;
    }

    removeInjection() {
        this.remove(this.icon);
        this.icon.destroy();
        this.frame.setAlpha(0.001);
        this.isFree = true;

        this.icon = null;

        this.parentContainer.checkInjectAvailable();
    }
}
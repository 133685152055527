import AudioManager from "../../../managers/AudioManager";
import { setImageSizeByHeight } from "../../../utils/utils";
import FinishBattleButton from "../../main/buttons/battle/FinishBattleButton";
import FleeFromBattleButton from "../../main/buttons/battle/FleeFromBattleButton";
import { ToggleAudioButton, ToggleFullScreenButton, ToggleMusicButton } from "../../main/buttons/common/UIButtons";

export default class BattleSettingsContainer extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;

        this.create(window.innerHeight * 0.065);
    }

    create(h) {
        this.add(this.frame = this.scene.add.sprite(0, 0, 'battle_ui', 'settings.png').setOrigin(0.5, 0));
        setImageSizeByHeight(this.frame, h);

        const soundX = this.frame.displayWidth * -0.42;
        const musicX = this.frame.displayWidth * -0.24;
        const y = this.frame.displayHeight / 2;

        this.soundIcon = {
            'on': this.scene.add.sprite(soundX, y, 'UI', 'sound_on.png'),
            'off': this.scene.add.sprite(soundX, y, 'UI', 'sound_off.png')
        }
        this.musicIcon = {
            'on': this.scene.add.sprite(musicX, y, 'UI', 'music_on.png'),
            'off': this.scene.add.sprite(musicX, y, 'UI', 'music_off.png')
        }

        this.addButtons(h);
        this.placeContent();
    }

    addButtons(height) {
        const h = height * 0.58;
        const w = h / 1.16;

        const fw = this.frame.displayWidth;
        const y = this.frame.displayHeight / 2;

        this.add(new ToggleAudioButton(this.scene, fw * -0.43, y, [], 'button_up', w, h));
        this.add(new ToggleMusicButton(this.scene, fw * -0.24, y, [], 'button_up', w, h));
        this.add(this.fleeFromBattleButton = new FleeFromBattleButton(this.scene, fw * 0.24, y, [], 'button_up', w, h, 'red')); 
        this.add(new ToggleFullScreenButton(this.scene, fw * 0.43, y, [], 'button_up', w, h));
        
        // Кнопка завершения боя (в начале боя неактивна)
        this.add(this.finishBattleButton = new FinishBattleButton(this.scene, 0, y, [], 'button_up', height / 1.16, height, 'green'));
        this.finishBattleButton.disable();
    }

    placeContent() {
        const h = this.frame.displayHeight;
        const size = h * 0.32;

        for (let key in this.soundIcon) {
            this.soundIcon[key].setDisplaySize(size, size);
            this.add(this.soundIcon[key]);
        }

        for (let key in this.musicIcon) {
            this.musicIcon[key].setDisplaySize(size, size);
            this.add(this.musicIcon[key]);
        }
    }

    update() {
        this.soundIcon['on'].setAlpha(AudioManager.soundOn);
        this.soundIcon['off'].setAlpha(!AudioManager.soundOn);

        this.musicIcon['on'].setAlpha(AudioManager.musicOn);
        this.musicIcon['off'].setAlpha(!AudioManager.musicOn);
    }
}
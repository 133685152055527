import BattleDefeatTable from '../tables/army/BattleDefeatTable.js';
import InfoTableContainer from './common/InfoTableContainer.js';

export default class BattleDefeatTableContainer extends InfoTableContainer {

    addInfoTables() {
        this.tableLeft = null;
        this.tableCenter = new BattleDefeatTable(this.scene, 0, 0, [], this.w, this.h, 'battle_defeat');
        this.tableRight = null;

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}

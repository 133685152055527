const sceneNames = {
    "MainBase": "База \"Наследие\"",
    "Iron_Base": "Железные рудники",
    "Silicon_Quarry": "Кремниевый карьер",
    "Titan_Base": "Титановые копи",
    "Rocky_Coast": "Скалистый берег",
    "East_Barrier": "Восточная граница",
    "North_Barrier": "Северная граница",
    "Iron_Mine": "Шахта железа",
    "Titan_Mine": "Шахта титана",
    "Krychon_Cave": "Пещера Крыхона",
    "Zazulic_Nest": "Гнездо зазюликов",
}

export default sceneNames;
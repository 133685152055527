import BuildingTableContainer from './common/BuildingTableContainer.js';
import FactoryProductionTable from '../tables/production/FactoryProductionTable.js';
import TitanFoundryTable from '../tables/production/TitanFoundryTable.js';


export default class TitanFoundryTableContainer extends BuildingTableContainer {

    addInfoTables() {
        this.tableLeft = null;
        this.tableCenter = new FactoryProductionTable(this.scene, 0, 0, [], this.w, this.h, 'titan_foundry_production');
        this.tableRight = new TitanFoundryTable(this.scene, 0, 0, [], this.w, this.h, 'titan_foundry');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}

import BuildingTableContainer from './common/BuildingTableContainer.js';
import GeneticLabTable from '../tables/buildings/GeneticLabTable.js';
import MutantsTable from '../tables/units/army_units/MutantsTable.js';


export default class GeneticLabTableContainer extends BuildingTableContainer {

    addInfoTables() {
        this.tableLeft = null;
        this.tableCenter = new MutantsTable(this.scene, 0, 0, [], this.w, this.h, 'mutant');
        this.tableRight = new GeneticLabTable(this.scene, 0, 0, [], this.w, this.h, 'genetic_lab');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}


import { TEXT_STYLE_BLUE } from "../../../../../../utils/textStyles";
import AddParameterButton from "../../../../../main/buttons/characters/AddParameterButton";


export class CharacterPointsText extends Phaser.GameObjects.Text {
    constructor(scene, x, y, text, th) {
        super(scene, x, y, text);
        scene.add.existing(this);

        this.setStyle(TEXT_STYLE_BLUE).setFontSize(th * 0.019).setOrigin(0, 1);
    }

    update(text) {
        this.setText(text);
    }
}

export class CharacterParameterButtons extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.tw = tw;
        this.th = th;
        this.create(tw, th);
    }

    create(w, h) {

        this.buttons = [
            new AddParameterButton(this.scene, 0, 0, [], 'button_up', h * 0.038, h * 0.045, 'green', 'health'),
            new AddParameterButton(this.scene, 0, 0, [], 'button_up', h * 0.038, h * 0.045, 'green', 'armor'),
            new AddParameterButton(this.scene, 0, 0, [], 'button_up', h * 0.038, h * 0.045, 'green', 'damage'),
            new AddParameterButton(this.scene, 0, 0, [], 'button_up', h * 0.038, h * 0.045, 'green', 'close_damage'),
            new AddParameterButton(this.scene, 0, 0, [], 'button_up', h * 0.038, h * 0.045, 'green', 'accuracy'),
            new AddParameterButton(this.scene, 0, 0, [], 'button_up', h * 0.038, h * 0.045, 'green', 'speed'),
            new AddParameterButton(this.scene, 0, 0, [], 'button_up', h * 0.038, h * 0.045, 'green', 'range'),
        ];
        this.add(this.buttons);

        this.updateButtons();
    }

    updateButtons() {
        var dist = 0;
        this.buttons.forEach(b => {
            b.y = dist;
            dist += this.th * 0.0685;
        });
    }
}
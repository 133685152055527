import loadAssets from "../../../managers/AssetManager";
import TimeManager from "../../../managers/TimeManager";
import InfoTables from "../../../scenes/ui/InfoTables";
import MapObject from "./MapObject";
import FixObjectTable from "../link_tables/FixObjectTable";
import apiClient from "../../../services/apiClient";
import AudioManager from "../../../managers/AudioManager";
import UI from "../../../scenes/ui/UI";

export default class Building extends MapObject {
    constructor(scene, x, y, children, texture, width, height, data) {
        super(scene, x, y, children, texture, width, height, data);
    }

    create() {
        this.type = 'building';
        super.create();
    }

    setLevel(level) {
        this.level = level;
    }

    async checkAvailable() {
        const data = await apiClient.buildings.checkAvailable({
            scene: this.scene.scene.key,
            name: this.texture
        });

        if (data['status'] != 'success') {
            return;
        }
        return data['available'];
    }

    addLink() {
        super.addLink();

        if (['genetic_center', 'genetic_lab', 'barraks', 'generator_silicon', 'generator_cave'].includes(this.texture)) {
            this.link.flip();
            this.processLink.setFlipX(true).setOrigin(1, 1);
        }
    }

    pointerOver() {
        super.pointerOver();
        this.link.hide();
        if (!this.tableFix) {
            this.link.show(this.level);
        }
    }

    async click() {
        const isAvailable = await this.checkAvailable();

        if (!isAvailable) {
            InfoTables.showWarningText('Уничтожьте врагов на пути к строению');
            return;
        }

        super.click();
        this.link.setAlpha(0.001);
        this.scene.game.registry.set('curBuilding', this);

        switch (this.state) {
            case 'update':
                InfoTables.showWarningText('Будет доступно в одном из обновлений');
                break;

            case 'broken':
                if (this.tableFix) {
                    this.destroyFixTable();
                } else {
                    this.openFixTable();
                }

                this.scene.input.on('pointerdown', () => {
                    if (this.tableFix) this.destroyFixTable();
                });
                break;

            case 'fixing':
                if (this.tableFix) {
                    this.destroyFixTable();
                } else {
                    this.openFixTable(true);
                }

                this.scene.input.on('pointerdown', () => {
                    this.destroyFixTable();
                });
                InfoTables.showWarningText('Строение восстанавливается');
                break;

            case 'opened':
                InfoTables.openInfoTable(this.texture);
                AudioManager.playSound('click', 'building');
                break;
        }
    }

    openFixTable(finish = false) {
        this.scene.map.add(this.tableFix = new FixObjectTable(this.scene, this.x + this.link.x, this.y + this.link.y, [], finish));
    }

    destroyFixTable() {
        this.tableFix && this.tableFix.destroy();
        this.tableFix = null;
        this.scene.map.handleMoves();
    }

    addBuilder() {
        const size = window.screen.height * 0.15;
        const x = this.x;
        const y = this.y;
        const w = this.obj.displayWidth;
        const h = this.obj.displayHeight;

        let offsetX, offsetY;
        let flip = false;
        let small = false;

        switch (this.texture) {
            case 'com_center':
                offsetX = 0.75;
                offsetY = 1.08;
                break;
            case 'tech_center':
                offsetX = 0.8;
                offsetY = 1.03;
                break;
            case 'robo_center':
                offsetX = 0.48;
                offsetY = 1.1;
                break;
            case 'store':
                offsetX = 0.6;
                offsetY = 1.1;
                break;
            case 'barraks':
                offsetX = 0.26;
                offsetY = 0.4;
                small = true;
                break;
            case 'hospital':
                offsetX = 0.48;
                offsetY = 1.2;
                small = true;
                flip = true;
                break;
            case 'headquoters':
                offsetX = 0.5;
                offsetY = 1.7;
                break;
            case 'genetic_center':
                offsetX = 0.5;
                offsetY = 1;
                break;
            case 'genetic_lab':
                offsetX = 0.63;
                offsetY = 1.1;
                break;
            case 'iron_foundry':
                offsetX = 0.8;
                offsetY = 0.65;
                flip = true;
                break;
            case 'machine_factory':
                offsetX = 0.8;
                offsetY = 0.75;
                break;
            case 'silicon_factory':
                offsetX = 0.45;
                offsetY = 0.6;
                break;
            case 'titan_foundry':
                offsetX = 0.53;
                offsetY = 0.6;
                break;
            case 'research_center':
                offsetX = 0.4;
                offsetY = 0.8;
                flip = true;
                break;
        }

        this.scene.map.add(this.builder = this.scene.add.sprite(x + w * offsetX, y + h * offsetY, 'builder_anims')
            .setOrigin(0.5, 1));
        this.builder.flipX = flip;
        this.builder.anims.play(small ? 'builder_extract' : 'builder_build');
        this.builder.setDisplaySize(size, size);
    }

    deleteBuilder() {
        this.builder && this.builder.destroy();
    }

    startBuilding(data, table) {
        if (this.level == 0) this.setState('fixing');
        this.addBuilder();
        this.destroyFixTable();

        const timerScene = this.scene.scene.get('InfoTables');
        TimeManager.createTimer(timerScene, data['name'], data['duration'],
            this.finishBuilding.bind(this),
            (remainingTime) => {
                if (this.level > 0 && table) table.updateTimer(remainingTime);
            }
        );
        this.addProcessToStack(data);
    }

    async finishBuilding() {
        const data = await apiClient.processes.finish({ category: 'building', name: this.texture });
        console.log(data);
        this.upgrade();
    }

    upgrade() {
        this.setState('opened');
        this.setLevel(++this.level);
        this.updateInfo(this.level);
        this.deleteBuilder();
        this.destroyFixTable();

        if (this.texture == 'store') {
            UI.buttonStore.makeInteractive();
            UI.buttonProduction.makeInteractive();
        }
    }

    async updateInfo(level) {
        InfoTables.tables.get(this.texture).updateInfo(level);

        const sceneName = this.scene.scene.key;
        const texture = `${this.texture}_${level}`;

        await loadAssets(this.scene, 'image', texture, `../../assets/scenes/${sceneName}/buildings/${this.texture}/${level}.png`);
        this.obj.setTexture(texture);
    }

    addProcessToStack(data) {
        super.addProcessToStack(data);
        InfoTables.tables.get(this.texture).updateInfo(this.level);
    }
}
import BuildingTableContainer from './common/BuildingTableContainer.js';
import CharacterTable from '../tables/units/characters/CharacterTable.js';
import BuildingProductionTable from '../tables/production/BuildingProductionTable.js';


export default class ResearchCenterTableContainer extends BuildingTableContainer {
    
    addInfoTables() {
        this.tableLeft = null;
        // this.tableLeft = new TableSkillsMainHero(this.scene, 0, 0, [], this.w, this.h, 'Shtryzik_skills');
        this.tableCenter = new CharacterTable(this.scene, 0, 0, [], this.w, this.h, 'Shtryzik');
        this.tableRight = new BuildingProductionTable(this.scene, 0, 0, [], this.w, this.h, 'research_center');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}

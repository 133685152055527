import MapScene from '../core/mapScene.js';

export default class Underworld extends MapScene {
    constructor() {
        super('Underworld');
    }

    loadAssets(data) {
        
        
        super.loadAssets(data);
    }
}

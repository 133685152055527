import BattleUnit from "../BattleUnit";

export default class BattlePichmog extends BattleUnit {

    setUnitData(unitData, linkedIcon) {
        super.setUnitData(unitData, linkedIcon);
        this.getData('config')['XP_cost'] = unitData['xp_cost'];
    }

    rest() {
        this.setState('idle');
        // this.playAnimation(['wake']);
    }
}
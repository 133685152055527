import SceneManager from "../../../managers/SceneManager";
import TimeManager from "../../../managers/TimeManager";
import InfoTables from "../../../scenes/ui/InfoTables";
import apiClient from "../../../services/apiClient";
import ExplorationTable from "../link_tables/ExplorationTable";
import MapObject from "./MapObject";

export default class WorldArea extends MapObject {
    constructor(scene, x, y, children, texture, width, height, data) {
        super(scene, x, y, children, texture, width, height, data);

        this.targetScene = data['scene'];
        this.name = data['name'];
        this.nextAreas = data['next_areas'];
        this.explorationTime = data['exploration_time'];

        this.setData('data', data);
        this.setData('available', data['available']);
        this.setData('area.is_explored', false);
        this.selectable = false;
    }

    setObjectData(data) {
        this.setData('area', data);

        if (data['is_explored']) {
            this.explore();
        }
    }

    create() {
        this.type = 'world_area';
        super.create();

        this.obj.setTintFill(0x000000);
        this.obj.setAlpha(0.8);

        const x = this.obj.displayWidth / 2;
        const y = this.obj.displayHeight / 2;
        const w = window.screen.height * 0.07;
        const size = window.screen.height * 0.05;

        this.addAt(this.circle = this.scene.add.sprite(x, y, 'battle_ui', 'highlight.png')
            .setDisplaySize(w, w * 0.6).setTint(0x79ff84).setAlpha(0), 1);

        this.addAt(this.locationMark = this.scene.add.sprite(x, y, 'battle_ui', 'location_mark.png')
            .setDisplaySize(size, size).setTint(0x79ff84).setAlpha(0).setOrigin(0.5, 1), 1);
    }

    addLink() {
        this.linkX = this.obj.displayWidth / 2;
        this.linkY = this.obj.displayHeight / 2;
        super.addLink();
        this.link.setLinkColor(0xffd59c, '#fff49f');
    }

    pointerOver() {
        super.pointerOver();
        this.obj.setTintFill(0x000000);

        this.link.hide();
        if (!this.exploreTable) this.link.show();

        const map = this.scene.map;
        map.bringToTop(this);
        map.activeAreas.forEach(area => map.bringToTop(area));

        if (!this.getData('area.is_explored')) {
            this.obj.setAlpha(this.selectable ? 0.3 : 0.9);
        } else {
            this.obj.setAlpha(0.3);
        }
    }

    pointerOut() {
        super.pointerOut();
        this.obj.setTintFill(0x000000);

        if (!this.getData('area.is_explored')) {
            this.obj.setAlpha(this.selectable ? 0.5 : 0.8);
        } else {
            this.obj.setAlpha(0.001);
        }
    }

    click() {
        super.click();
        this.link.hide();
        this.scene.game.registry.set('selected_world_area', this);
        let morionFinish = false;

        if (!this.getData('available')) {
            InfoTables.showWarningText('Будет доступно в одном из обновлений');
            return;
        }
        if (this.getData('area.is_explored')) {
            SceneManager.loadScene(this.scene, this.targetScene);
            return;
        }
        if (!this.selectable) {
            InfoTables.showWarningText('Разведайте предыдущие зоны');
            return;
        }
        if (this.state == 'exploring') {
            morionFinish = true;
        }

        if (this.exploreTable) {
            this.destroyExploreTable();
        } else {
            this.scene.map.activeAreas.push(this);
            this.openExploreTable(morionFinish);
        }

        this.scene.input.on('pointerdown', () => {
            if (this.exploreTable) this.destroyExploreTable();
        });
    }

    openExploreTable(morionFinish) {
        this.add(this.exploreTable = new ExplorationTable(this.scene, this.link.x, this.link.y, [], this.getData('data'), morionFinish));
    }

    destroyExploreTable() {
        this.exploreTable && this.exploreTable.destroy();
        this.exploreTable = null;
        this.removeFromActiveAreas();
        this.removeAnimation();
        this.scene.map.handleMoves();
    }

    makeAvailable() {
        if (!this.getData('area.is_explored')) {
            this.selectable = true;
            this.obj.setAlpha(0.5);
            this.playAnimation();
        }
    }

    playAnimation() {
        this.circle.setAlpha(1);
        this.locationMark.setAlpha(1);

        this.anim = this.scene.tweens.add({
            targets: this.locationMark,
            y: this.circle.y - window.screen.height * 0.02,
            duration: 700,
            loop: -1,
            yoyo: true,
        });
    }

    removeAnimation() {
        this.circle.setAlpha(0);
        this.locationMark.setAlpha(0);
        this.anim && this.anim.remove();
    }

    removeFromActiveAreas() {
        const areas = this.scene.map.activeAreas;
        let index = areas.indexOf(this);

        if (index !== -1) {
            areas.splice(index, 1);
        }
    }

    startExploration(data) {
        this.setState('exploring');

        this.destroyExploreTable();
        this.addProcessToStack(data);
        this.scene.map.activeAreas.push(this);

        const timerScene = this.scene.scene.get('InfoTables');
        TimeManager.createTimer(timerScene, this.targetScene, data['duration'],
            this.finishExploration.bind(this), () => { }
        );
    }

    async finishExploration() {
        const data = await apiClient.processes.finish({ category: 'exploration', name: this.targetScene });
        console.log(data);
    }

    explore() {
        this.obj.setTint(0x000000);
        this.obj.setAlpha(0.001);

        this.setData('area.is_explored', true);

        this.removeFromActiveAreas();
        this.nextAreas.forEach(name => this.scene.map[name].makeAvailable());
        this.destroyExploreTable();
    }

    checkProcessExist(processes) {
        const process = processes[this.targetScene];

        if (process) {
            if (process['finished'] == true) {
                console.log('process finished by update');
            } else {
                this.startExploration(process);
            }
        }
    }
}
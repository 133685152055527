import BuildingTable from '../tables/buildings/BuildingTable.js';
import BuildingTableContainer from './common/BuildingTableContainer.js';
import StoreTable from '../tables/store/StoreTable.js';


export default class StoreTableContainer extends BuildingTableContainer {

    addInfoTables() {
        this.tableLeft = null;
        this.tableCenter = new StoreTable(this.scene, 0, 0, [], this.w, this.h, 'store_items');
        this.tableRight = new BuildingTable(this.scene, 0, 0, [], this.w, this.h, 'store');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}

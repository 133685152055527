import BuildingTableContainer from './common/BuildingTableContainer.js';
import BuildingProductionTable from '../tables/production/BuildingProductionTable.js';
import MachineConstructionTable from '../tables/construction/MachineConstructionTable.js';


export default class MachineFactoryTableContainer extends BuildingTableContainer {

    addInfoTables() {
        const items = [
            'machine_wheel', 
            'machine_arm', 
            'machine_bucket', 
            'machine_claw', 
            'machine_jack'
        ];

        this.tableLeft = null;
        this.tableCenter = new MachineConstructionTable(this.scene, 0, 0, [], this.w, this.h, 'machine_assembling');
        this.tableRight = new BuildingProductionTable(this.scene, 0, 0, [], this.w, this.h, 'machine_factory', items);

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}

import FinishedProcessesTable from "../tables/common/FinishedProcessesTable.js";
import InfoTableContainer from "./common/InfoTableContainer.js";


export default class ProcessesTableContainer extends InfoTableContainer {

    addInfoTables() {
        this.tableLeft = null;
        this.tableCenter = new FinishedProcessesTable(this.scene, 0, 0, [], this.w, this.h, 'placeholder');
        this.tableRight = null;

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}
import Button from '../../modules/main/buttons/common/Button.js';
import MapScene from '../core/mapScene.js';
import apiClient from '../../services/apiClient.js';
import loadAssets from '../../managers/AssetManager.js';

export default class Spaceship extends MapScene {
    constructor() {
        super('Spaceship');
    }

    async createScene() {
        super.createScene();

        const data = await apiClient.objects.spaceship();
        console.log(data);

        this.addSystemLinks(data);
    }

    addSystemLinks(data) {
        const w = this.mapW * 0.0465;

        for (let key in data) {
            let d = data[key];
            this.map.add(new SpaceshipSystemLink(this, this.mapW * d['link_x'], this.mapW * d['link_y'], [], 'table_button', w, w, 'blue', key, d['items']));
        }
    }
}


class SpaceshipSystemLink extends Button {
    constructor(scene, x, y, children, texture, width, height, color, name, data) {
        super(scene, x, y, children, texture, width, height, color);

        this.name = name;
        this.itemsData = data;
    }

    click() {
        super.click();
        this.showTable();
    }

    showTable() {
        const x = window.innerWidth / 2;
        const y = window.innerHeight * 0.52;

        this.table = new SpaceshipSystemTable(this.scene.scene.get('InfoTables'), x, y, [], this.name);
    }
}


class SpaceshipSystemTable extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, texture) {
        super(scene, x, y, children, texture);
        scene.add.existing(this);

        this.scene = scene;
        this.texture = texture;

        this.create(window.screen.width, window.screen.height);
    }

    async create(w, h) {
        const texture = 'table_spaceship_' + this.texture;
        await loadAssets(this.scene, 'image', texture, `../../../assets/scenes/Spaceship/systems/${this.texture}.png`);

        this.add(this.darkScreen = this.scene.add.rectangle(0, 0, w, h, 0x000000, 0.5).setOrigin(0.5, 0.5).setInteractive());
        this.add(this.table = this.scene.add.image(0, 0, texture).setOrigin(0.5, 0.5).setDisplaySize(h * 0.889, h * 0.5));

        this.darkScreen.on('pointerup', () => this.destroy());
        this.addItems();
    }

    addItems() {
        const w = this.table.displayWidth;
        const h = this.table.displayHeight;

        const items = this.scene.game.registry.get('items');

        let itemNames;
        switch (this.texture) {
            case 'control_system':
                itemNames = ['spaceship_control_1', 'spaceship_control_2', 'spaceship_control_3', 'spaceship_control_4', 'spaceship_control_5', 'spaceship_control_6'];
                break;
            case 'cover':
                itemNames = ['spaceship_cover', 'spaceship_cover', 'spaceship_cover', 'spaceship_cover', 'spaceship_cover', 'spaceship_cover'];
                break;
            case 'life_system':
                itemNames = ['spaceship_life_system_module', 'spaceship_life_system_module', 'spaceship_life_system_module', 'spaceship_life_system_module', 'spaceship_life_system_module', 'spaceship_life_system_module'];
                break;
            case 'gravy_system':
                itemNames = ['spaceship_gravy_system_1', 'spaceship_gravy_system_2', 'spaceship_gravy_system_3', 'spaceship_gravy_system_4', 'spaceship_gravy_system_5', 'spaceship_gravy_system_6'];
                break;
            case 'reactor':
                itemNames = ['spaceship_reactor_rod', 'spaceship_reactor_rod', 'spaceship_reactor_rod', 'spaceship_reactor_rod', 'spaceship_reactor_rod', 'spaceship_reactor_rod'];
                break;
            case 'engine':
                itemNames = ['spaceship_engine_1', 'spaceship_engine_2', 'spaceship_engine_3', 'spaceship_engine_4', 'spaceship_engine_5', 'spaceship_engine_6'];
                break;
            case 'power_system':
                itemNames = ['spaceship_power_system_1', 'spaceship_power_system_2', 'spaceship_power_system_3', 'spaceship_power_system_4', 'spaceship_power_system_5', 'spaceship_power_system_6'];
                break;
            case 'generator':
                itemNames = ['spaceship_generator_1', 'spaceship_generator_2', 'spaceship_generator_3', 'spaceship_generator_4', 'spaceship_generator_5', 'spaceship_generator_6'];
                break;
            case 'maneuver_engine':
                itemNames = ['spaceship_maneuver_engine', 'spaceship_maneuver_engine', 'spaceship_maneuver_engine', 'spaceship_maneuver_engine', 'spaceship_maneuver_engine', 'spaceship_maneuver_engine'];
                break;
        }

        const equal = (itemNames[0] == itemNames[5]) ? true : false;
        let cnt = items[itemNames[0]];

        for (let i = 0; i < 6; i++) {
            let pos = this.getItemPosition(i);

            let item = this.scene.add.sprite(0, 0, 'UI', 'skill_cell.png');
            item.setDisplaySize(w * 0.1, h * 0.15).setPosition(w * pos.x, h * pos.y).setOrigin(0.5, 0.5);

            this.add(item);

            if (equal && cnt > 0) {
                item.destroy();
                cnt--;
            } else if (!equal && items[itemNames[i]] > 0) {
                item.destroy();
            }
        }
    }

    // Позиция ячейки системы
    getItemPosition(i) {
        let x, y;
        switch (i) {
            case 0:
                x = -0.139;
                y = -0.357;
                break;
            case 1:
                x = 0.139;
                y = -0.357;
                break;
            case 2:
                x = 0.255;
                y = 0;
                break;
            case 3:
                x = 0.139;
                y = 0.355;
                break;
            case 4:
                x = -0.139;
                y = 0.355;
                break;
            case 5:
                x = -0.254;
                y = 0;
                break;
        }

        return { x: x, y: y };
    }
}

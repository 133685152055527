const mapMachineData = {
    "Krychon_Cave": {
        machineTexture: 'crystall_collector_anims',
        anims: ['up-r', 'down-r', 'up-half_l', 'down-r', 'up-l'],
        positions: [
            { x: 0.7, y: 0.28 },
            { x: 0.69, y: 0.31 },
            { x: 0.62, y: 0.29 },
            { x: 0.628, y: 0.317 },
            { x: 0.59, y: 0.3 },
        ], 
        ore: 'crystall'
    },
    "Iron_Mine": {
        machineTexture: 'miner_anims',
        anims: ['up-half_r', 'up-l', 'up-half_l', 'up-half_r', 'up-l'],
        positions: [
            { x: 0.54, y: 0.37 },
            { x: 0.39, y: 0.4 },
            { x: 0.74, y: 0.14 },
            { x: 0.42, y: 0.16 },
            { x: 0.27, y: 0.16 },
        ], 
        ore: 'iron_ore'
    },
    "Silicon_Quarry": {
        machineTexture: 'truck_anims',
        anims: ['up-l', 'up-l', 'up-half_r', 'up-half_l', 'up-l'],
        positions: [
            { x: 0.2, y: 0.17 },
            { x: 0.25, y: 0.15 },
            { x: 0.3, y: 0.15 },
            { x: 0.36, y: 0.153 },
            { x: 0.42, y: 0.15 },
        ], 
        ore: 'silicon_ore'
    },
    "Titan_Mine": {
        machineTexture: 'miner_anims',
        anims: ['left', 'up-l', 'up-l', 'up-half_l', 'up-r'],
        positions: [
            { x: 0.1, y: 0.3 },
            { x: 0.11, y: 0.225 },
            { x: 0.6, y: 0.13 },
            { x: 0.64, y: 0.12 },
            { x: 0.82, y: 0.115 },
        ], 
        ore: 'titan_ore'
    },
};

export default mapMachineData;
const barrierData = {
    "barraks_tower": "Казармы",
    "genetic_wall": "Зона генетики",
    "research_wall": "Зона исследований",
    "bio_wall": "Зона биологии",
    "cave_barrier": "Блокпост Крыхона",
    "south_barrier": "Южная граница",
    "east_barrier": "Восточная граница",
    "north_barrier": "Северная граница",
    "titan_mine_wall": "Шахта титана",
    "zazulic_nest_wall": "Пещера зазюликов",
}

export default barrierData;
import TimeManager from "../../../../../../managers/TimeManager";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN } from "../../../../../../utils/textStyles";

export default class ProductionStatusSectionItem extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, cell, itemName) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.cell = cell;
        this.itemName = itemName;

        this.create(this.cell.displayWidth, this.cell.displayHeight);
    }

    create(w, h) {
        this.add(this.icon = this.scene.add.image(0, 0, 'items_icons', `${this.itemName}.png`).setDisplaySize(h * 0.45, h * 0.45));
        this.add(this.amountText = this.scene.add.text(w * 0.05, h * -0.05, '-').setOrigin(0, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.17));
        this.add(this.timer = this.scene.add.text(w * 0.045, h * 0.05, '-').setOrigin(0, 0).setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.17));
    }

    update(processes) {
        const process = processes[this.itemName];

        if (process) {
            if (process['finished'] == true) {
                console.log('process finished by update');
            } else {
                this.startProduction(process);
            }
        }
    }

    startProduction(data) {
        this.amountText.setText(data['item_amount']);
        TimeManager.createTimer(this.scene, this.itemName, data['duration'],
            this.finishProduction.bind(this),
            (remainingTime) => {
                this.timer.setText(TimeManager.formatFullTime(remainingTime));
            }
        );
    }
    
    finishProduction() {
        this.timer.setText('-');
    }
}

export default class MapGridCellHighlight extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;

        this.create();
    }

    create() {
        this.add(this.hightlight = this.scene.add.sprite(0, 0, 'battle_ui', 'highlight.png').setOrigin(0.5, 0.5));
        this.add(this.mark = this.scene.add.sprite(0, 0, 'battle_ui', 'location_mark.png').setOrigin(0.5, 1));

        const w = this.scene.tileW * 0.9;
        const h = w * 0.6;
        this.hightlight.setDisplaySize(w, h);
        this.mark.setDisplaySize(h, h);

        this.startAnimation();
    }

    setColor(color) {
        if (color == 'green') {
            this.hightlight.setTint(0x84FF57);
            this.mark.setTint(0xa5ff83);

        } else if (color == 'red') {
            this.hightlight.setTint(0xFF5656);
            this.mark.setTint(0xff7d7d);
        }
    }

    startAnimation() {
        const tween = this.scene.tweens.add({
            targets: this.mark,
            y: this.mark.displayHeight * -0.15,
            duration: 700,
            loop: -1,
            yoyo: true,
        });
    }
}
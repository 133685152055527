import MailTable from "../tables/player/MailTable.js";
import PortalTable from "../tables/player/PortalTable.js";
import ProfileTable from "../tables/player/ProfileTable.js";
import InfoTableContainer from "./common/InfoTableContainer";


export default class SocialTableContainer extends InfoTableContainer {

    addInfoTables() {
        this.tableLeft = new ProfileTable(this.scene, 0, 0, [], this.w, this.h, 'profile');
        this.tableCenter = new PortalTable(this.scene, 0, 0, [], this.w, this.h, 'clan');
        this.tableRight = new MailTable(this.scene, 0, 0, [], this.w, this.h, 'mail');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}
import dailyBonusData from '../../../../../config/shop/dailyBonusData.js';
import { ScrollManager } from '../../../../../managers/UIController.js';
import SingleInfoTable from '../common/SingleInfoTable.js';
import DailyBonusCellContainer from './components/DailyBonusCellContainer.js';


export default class DailyBonusTable extends SingleInfoTable {
    constructor(scene, x, y, children, width, height, texture, category) {
        super(scene, x, y, children, width, height, texture);

        this.tableCategory = category;
        this.addRewards();
        this.addContent();
    }

    addContent() {
        const w = this.width;
        const h = this.height;
        
        const scrollW = w * 0.65;
        const scrollH = h * 0.69;
        const spaceTop = h * 0.03;

        this.scroll = new ScrollManager(this.scene, {
            x: 0,
            y: h * -0.03,
            width: scrollW,
            height: scrollH,

            scrollMode: 0,

            space: {
                panel: {
                    top: h * 0.05,
                    bottom: spaceTop,
                }
            }
        }, {
            space: {
                line: spaceTop,
            },
        });

        this.add(this.scroll.scrollPanel);
        const data = dailyBonusData[this.tableCategory]['content'];

        this.add(this.cellContainer = new DailyBonusCellContainer(this.scene, 0, 0, [], this.width, this.height));
    }

    addRewards() {
        const w = this.width;
        const h = this.height;

        this.rewards = dailyBonusData[this.tableCategory]['rewards'];

        let dist = w * -0.315;
        let cnt = 0;

        this.rewards.forEach(item => {
            let isKey = item.includes('key_container');
            let texture = isKey ? 'items_icons' : 'container_icons';
            let size = isKey ? h * 0.06 : h * 0.09;

            let shine = this.scene.add.image(dist, h * 0.36, 'shine').setOrigin(0.5, 0.5).setDisplaySize(size * 1.1, size * 1.1);
            let icon = this.scene.add.image(dist, h * 0.36, texture, `${item}.png`).setOrigin(0.5, 0.5).setDisplaySize(size, size);
            this.add(shine);
            this.add(icon);

            dist += (cnt == 0) ? w * 0.055 : w * 0.0637;
            cnt++;
        });
    }
}

const barrierData = {
    "barraks": "Казармы",
    "genetic": "Зона генетики",
    "research": "Зона исследований",
    "Krychon_cave": "Зона биологии",
    "south_barrier": "Южная граница",
    "east_barrier": "Восточная граница",
    "north_barrier": "Северная граница",
    "titan_mine": "Шахта титана",
    "zazulic_nest": "Гнездо зазюликов",
}

export default barrierData;
import UIController from '../../../../../managers/UIController.js';
import BattleResultTable from './BattleResultTable.js';


export default class BattleWinTable extends BattleResultTable {

    async update(category) {
        await super.update(category);
        const data = this.parentContainer.getData('data');
        const extraRewards = data['extra_rewards'];

        console.log(extraRewards);
        this.addRewards(extraRewards);
    }

    addRewards(rewards) {
        const w = this.width;
        const h = this.height;

        UIController.arrangeItems({
            container: this, 
            itemsData: rewards, 
            x: w * 0.05, 
            y: h * -0.13, 
            iconSize: h * 0.04,
            fontSize: h * 0.02,
            space: w * 0.055,
        });
    }

    addMainRewards(rewards) {
        super.addMainRewards(rewards);
        const w = this.width;
        const h = this.height;

        UIController.arrangeItems({
            container: this, 
            itemsData: rewards, 
            x: w * -0.32, 
            y: h * -0.13, 
            iconSize: h * 0.04,
            fontSize: h * 0.02,
            space: w * 0.055,
        });
    }
}




import { DailyBonusCell } from "./DailyBonusCell";

export default class DailyBonusCellContainer extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.tw = tw;
        this.th = th;

        this.cells = [
            // Центр
            new DailyBonusCell(scene, 0, th * -0.280, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, 0, th * -0.203, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, 0, th * -0.125, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, 0, th * -0.048, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, 0, th * 0.03, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, 0, th * 0.107, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, 0, th * 0.185, [], 'UI', tw, th, 'steel'),

            // Справа 2
            new DailyBonusCell(scene, tw * 0.076, th * -0.280, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * 0.076, th * -0.203, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * 0.076, th * -0.125, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * 0.076, th * -0.048, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * 0.076, th * 0.03, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * 0.076, th * 0.107, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * 0.076, th * 0.185, [], 'UI', tw, th, 'steel'),

            // Справа 4
            new DailyBonusCell(scene, tw * 0.152, th * -0.280, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * 0.152, th * -0.203, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * 0.152, th * -0.125, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * 0.152, th * -0.048, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * 0.152, th * 0.03, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * 0.152, th * 0.107, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * 0.152, th * 0.185, [], 'UI', tw, th, 'titan'),

            // Справа 6
            new DailyBonusCell(scene, tw * 0.228, th * -0.280, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * 0.228, th * -0.203, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * 0.228, th * -0.125, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * 0.228, th * -0.048, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * 0.228, th * 0.03, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * 0.228, th * 0.107, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * 0.228, th * 0.185, [], 'UI', tw, th, 'silicon'),

            // Справа 8
            new DailyBonusCell(scene, tw * 0.304, th * -0.048, [], 'UI', tw, th, 'steel'),



            // Справа 1
            new DailyBonusCell(scene, tw * 0.038, th * -0.242, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * 0.038, th * -0.165, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * 0.038, th * -0.087, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * 0.038, th * -0.010, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * 0.038, th * 0.068, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * 0.038, th * 0.146, [], 'UI', tw, th, 'steel'),

            // Справа 3
            new DailyBonusCell(scene, tw * 0.114, th * -0.242, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * 0.114, th * -0.165, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * 0.114, th * -0.087, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * 0.114, th * -0.010, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * 0.114, th * 0.068, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * 0.114, th * 0.146, [], 'UI', tw, th, 'steel'),

            // Справа 5
            new DailyBonusCell(scene, tw * 0.190, th * -0.318, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * 0.190, th * -0.242, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * 0.190, th * -0.165, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * 0.190, th * -0.087, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * 0.190, th * -0.010, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * 0.190, th * 0.068, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * 0.190, th * 0.146, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * 0.190, th * 0.224, [], 'UI', tw, th, 'silicon'),

            // Справа 7
            new DailyBonusCell(scene, tw * 0.266, th * -0.165, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * 0.266, th * -0.087, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * 0.266, th * -0.010, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * 0.266, th * 0.068, [], 'UI', tw, th, 'steel'),


            // СЛЕВА -----------------------------------------------------------/
            // Слева 2
            new DailyBonusCell(scene, tw * -0.076, th * -0.280, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * -0.076, th * -0.203, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * -0.076, th * -0.125, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * -0.076, th * -0.048, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * -0.076, th * 0.03, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * -0.076, th * 0.107, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * -0.076, th * 0.185, [], 'UI', tw, th, 'titan'),

            // Слева 4
            new DailyBonusCell(scene, tw * -0.152, th * -0.280, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * -0.152, th * -0.203, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * -0.152, th * -0.125, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * -0.152, th * -0.048, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * -0.152, th * 0.03, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * -0.152, th * 0.107, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * -0.152, th * 0.185, [], 'UI', tw, th, 'silicon'),

            // Слева 6
            new DailyBonusCell(scene, tw * -0.228, th * -0.280, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * -0.228, th * -0.203, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * -0.228, th * -0.125, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * -0.228, th * -0.048, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * -0.228, th * 0.03, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * -0.228, th * 0.107, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * -0.228, th * 0.185, [], 'UI', tw, th, 'steel'),

            // Слева 8
            new DailyBonusCell(scene, tw * -0.304, th * -0.048, [], 'UI', tw, th, 'titan'),


            // Слева 1
            new DailyBonusCell(scene, tw * -0.038, th * -0.242, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * -0.038, th * -0.165, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * -0.038, th * -0.087, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * -0.038, th * -0.010, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * -0.038, th * 0.068, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * -0.038, th * 0.146, [], 'UI', tw, th, 'titan'),

            // Слева 3
            new DailyBonusCell(scene, tw * -0.114, th * -0.242, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * -0.114, th * -0.165, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * -0.114, th * -0.087, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * -0.114, th * -0.010, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * -0.114, th * 0.068, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * -0.114, th * 0.146, [], 'UI', tw, th, 'titan'),

            // Слева 5
            new DailyBonusCell(scene, tw * -0.190, th * -0.318, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * -0.190, th * -0.242, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * -0.190, th * -0.165, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * -0.190, th * -0.087, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * -0.190, th * -0.010, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * -0.190, th * 0.068, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * -0.190, th * 0.146, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * -0.190, th * 0.224, [], 'UI', tw, th, 'steel'),

            // Слева 7
            new DailyBonusCell(scene, tw * -0.266, th * -0.165, [], 'UI', tw, th, 'titan'),
            new DailyBonusCell(scene, tw * -0.266, th * -0.087, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * -0.266, th * -0.010, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * -0.266, th * 0.068, [], 'UI', tw, th, 'titan'),


            // За морион
            new DailyBonusCell(scene, tw * -0.304, th * -0.280, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * -0.304, th * 0.185, [], 'UI', tw, th, 'steel'),

            // Слева 9
            new DailyBonusCell(scene, tw * -0.342, th * -0.165, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * -0.342, th * 0.068, [], 'UI', tw, th, 'steel'),

            new DailyBonusCell(scene, tw * 0.304, th * -0.280, [], 'UI', tw, th, 'silicon'),
            new DailyBonusCell(scene, tw * 0.304, th * 0.185, [], 'UI', tw, th, 'silicon'),

            // Справа 9
            new DailyBonusCell(scene, tw * 0.342, th * -0.165, [], 'UI', tw, th, 'steel'),
            new DailyBonusCell(scene, tw * 0.342, th * 0.068, [], 'UI', tw, th, 'steel'),
        ];

        this.add(this.cells);
        this.cells[3].makeInteractive();

        // this.checkOpenedCells();
        // this.cellClick();

        this.add(this.bar = scene.add.image(tw * -0.31615, th * 0.4, 'progress_bar').setDisplaySize(tw * 0.63177, th * 0.027778).setOrigin(0, 0));
        this.bar.setTintFill(0x00e2ff);

    }

    cellClick() {
        this.cells.forEach((cell, index) => {
            cell.off('click').on('click', () => {
                cell.openCell(index + 1, 'resources', cell.itemToAdd['item']);

                this.cells.forEach(c => {
                    if (dist(cell.x, cell.y, c.x, c.y) <= this.th * 0.1 && !c.isAvailable) {
                        c.makeAvailable();
                    }
                });

                this.openedCells++;
                this.updateBar();
            });
        });
    }

    checkOpenedCells() {
        let table = this;
        this.openedCells = 0;

        getOpenedCellsDailyBonus('resources').done(data => {
            let cells = $.parseJSON(data);

            for (let key in cells) {
                let cell = table.cells.find((c, index) => index + 1 == key.replace(/^\D+/g, '') && !['0', '1', ''].includes(cells[key]));

                if (cell) {
                    cell.open(cells[key]);

                    table.cells.forEach(c => {
                        if (dist(cell.x, cell.y, c.x, c.y) <= table.th * 0.1 && !c.isAvailable) {
                            c.makeAvailable();
                        }
                    });

                    this.openedCells++;
                }
            }

            table.updateBar();

        });
    }

    // Отображаем нужное количество делений шкалы
    updateBar() {
        this.bar.setCrop(0, 0, this.bar.width * this.openedCells / 100, this.bar.height);
    }

}
import BuildingTableContainer from './common/BuildingTableContainer.js';
import CharacterTable from '../tables/units/characters/CharacterTable.js';
import GeneticCenterTable from '../tables/buildings/GeneticCenterTable.js';


export default class GeneticCenterTableContainer extends BuildingTableContainer {
    
    addInfoTables() {
        this.tableLeft = null;
        // this.tableLeft = new TableSkillsMainHero(this.scene, 0, 0, [], this.w, this.h, 'Chlapidol_skills');
        this.tableCenter = new CharacterTable(this.scene, 0, 0, [], this.w, this.h, 'Chlapidol');
        this.tableRight = new GeneticCenterTable(this.scene, 0, 0, [], this.w, this.h, 'genetic_center');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}

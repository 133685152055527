export function divideUnitsByCategory(unitArray) {
    let result = {};

    for (let id in unitArray) {
        let category = unitArray[id]['category'];

        if (!result.hasOwnProperty(category)) {
            result[category] = [];
        }
        result[category].push(unitArray[id]);
    }

    return result;
}
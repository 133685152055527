import BattleCharacter from "./BattleCharacter";

export default class BattleShtryzik extends BattleCharacter {

    setUnitData(unitData, linkedIcon) {
        super.setUnitData(unitData, linkedIcon);
        this.getData('config')['target'] = 'extra';
        this.getData('config')['priority'] = 'crystall';
    }

    shoot(x, y, dir, target) { }

    onTargetDie(target) {
        super.onTargetDie(target);
        this.obtainCrystal(target);
    }

    obtainCrystal(target) {
        const data = target.getData('unit');

        if (data['category'] != 'crystall') {
            return;
        }

        const crystall = data['name'];
        setTimeout(this.createPopText(` + 1`, crystall), 700);

        const extraRewards = this.scene.data.get('extra_rewards');

        if (!extraRewards[crystall]) {
            extraRewards[crystall] = 1;
        } else {
            extraRewards[crystall]++;
        }
    }
}
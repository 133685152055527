
export default class EvolutionTree extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tableW, tableH) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.tableW = tableW;
        this.tableH = tableH;

        this.create(tableW, tableH);
    }

    create(w, h) {
        this.add(this.image = this.scene.add.image(0, 0, 'evolution_soldier'));
    }

    addContent(w, h) { }

    update() {
        if (!this.contentAdded) {
            this.addContent(this.image.displayWidth, this.image.displayHeight);
            this.contentAdded = true;
        }
    }

    updateContent(unitData) { }
}
export default class TimeManager {
    static timers = new Map();
    static textTimers = new Map();

    static createTimer(scene, key, duration, mainCallback, updateCallback) {
        this.removeTimer(key);

        const timer = scene.time.addEvent({
            delay: duration * 1000,
            callback: () => {
                this.removeTimer(key);
                mainCallback();
            }
        });

        this.timers.set(key, timer);

        const textTimer = scene.time.addEvent({
            delay: 1000,
            loop: true,
            callback: () => {
                const remainingTime = Math.ceil((timer.delay - timer.elapsed) / 1000);
                updateCallback(remainingTime);
            }
        });
        
        this.textTimers.set(key, textTimer);
    }

    static removeTimer(key) {
        const timer = this.timers.get(key);
        const textTimer = this.textTimers.get(key);

        if (timer) {
            textTimer.remove();
            timer.remove();
            this.timers.delete(key);
        }
    }

    static formatFullTime(duration) {
        const seconds = Math.floor(duration % 60);
        const minutes = Math.floor((duration / 60) % 60);
        const hours = Math.floor((duration / (60 * 60)) % 24);
        const days = Math.floor(duration / (60 * 60 * 24));

        const timeComponents = [];

        if (days > 0) {
            timeComponents.push(days.toString());
        }

        if (hours > 0) {
            if (days > 0) {
                timeComponents.push(hours.toString().padStart(2, '0'));
            } else {
                timeComponents.push(hours.toString());
            }
        } 

        timeComponents.push(minutes.toString().padStart(2, '0'));
        timeComponents.push(seconds.toString().padStart(2, '0'));

        return timeComponents.join(':');
    }

    static formatShortTime(seconds) {
        let time = '';
    
        if (seconds >= 86400) {
            const days = Math.floor(seconds / 86400);
            time = days + 'д';
        } else if (seconds >= 3600) {
            const hours = Math.floor(seconds / 3600);
            time = hours + 'ч';
        } else if (seconds >= 60) {
            const minutes = Math.floor(seconds / 60);
            time = minutes + 'м';
        } else {
            time = seconds + 'с';
        }
    
        return time;
    }

    static getRemaining(timerName) {
        const timer = this.timers.get(timerName);
        if (!timer) return 0;
        return this.timers.get(timerName).getRemaining();
    }
}


import heroData from "../../../../../../../config/units/heroData";
import InfoTables from "../../../../../../../scenes/ui/InfoTables";
import apiClient from "../../../../../../../services/apiClient";
import EvolutionTree from "./EvolutionTree";
import EvolutionTreeIcon from "./common/EvolutionTreeIcon";

export default class SoldierEvolutionTree extends EvolutionTree {

    addContent(w, h) {
        super.addContent(w, h);
        const scene = this.scene;
        const iw = w * 0.046; // icon width
        const ih = iw / 1.16; // icon height
        const biw = w * 0.062; // big icon width
        const bih = biw / 1.16; // big icon height

        this.icons = [
            new EvolutionTreeIcon(scene, w * -0.392, 0, [], 'UI', iw, ih, 1, [1]), // 0,   1
            new EvolutionTreeIcon(scene, w * -0.307, 0, [], 'UI', iw, ih, 2, [2]), // 1,   2
            new EvolutionTreeIcon(scene, w * -0.222, 0, [], 'UI', iw, ih, 3, [3, 16, 5]), // 2,   3
            new EvolutionTreeIcon(scene, w * -0.176, h * -0.209, [], 'UI', iw, ih, 3.1, [4], 'speed'), // 3,   3.1
            new EvolutionTreeIcon(scene, w * -0.222, h * -0.419, [], 'UI', iw, ih, 3.2, [17, 18], 'speed'), // 4,   3.2
            new EvolutionTreeIcon(scene, w * -0.137, 0, [], 'UI', iw, ih, 4, [6]), // 5,   4
            new EvolutionTreeIcon(scene, w * -0.053, 0, [], 'UI', iw, ih, 5, [7, 9, 19]), // 6,   5
            new EvolutionTreeIcon(scene, w * -0.1, h * 0.209, [], 'UI', iw, ih, 5.1, [8], 'moderate'), // 7,   5.1
            new EvolutionTreeIcon(scene, w * -0.053, h * 0.419, [], 'UI', iw, ih, 5.2, [20, 21], 'moderate'), // 8,   5.2
            new EvolutionTreeIcon(scene, w * 0.032, 0, [], 'UI', iw, ih, 6, [10]), // 9,   6
            new EvolutionTreeIcon(scene, w * 0.117, 0, [], 'UI', iw, ih, 7, [11, 13, 22]), // 10,   7
            new EvolutionTreeIcon(scene, w * 0.164, h * -0.209, [], 'UI', iw, ih, 7.1, [12], 'heavy'), // 11,   7.1
            new EvolutionTreeIcon(scene, w * 0.117, h * -0.419, [], 'UI', iw, ih, 7.2, [23, 24], 'heavy'), // 12,   7.2
            new EvolutionTreeIcon(scene, w * 0.201, 0, [], 'UI', iw, ih, 8, [14]), // 13,   8
            new EvolutionTreeIcon(scene, w * 0.286, 0, [], 'UI', iw, ih, 9, [15]), // 14,   9
            new EvolutionTreeIcon(scene, w * 0.371, 0, [], 'UI', iw, ih, 10, [25, 26, 27]), // 15,   10      

            // Герои
            new EvolutionTreeIcon(scene, w * -0.269, h * 0.209, [], 'UI', biw, bih, 100, [], 'hero', 'berserk'), // 16,   1 снизу
            new EvolutionTreeIcon(scene, w * -0.315, h * -0.419, [], 'UI', biw, bih, 100, [], 'hero', 'stalker'), // 17,    1 слева
            new EvolutionTreeIcon(scene, w * -0.129, h * -0.419, [], 'UI', biw, bih, 100, [], 'hero', 'ghost'), // 18,   1 справа

            new EvolutionTreeIcon(scene, w * -0.006, h * -0.209, [], 'UI', biw, bih, 100, [], 'hero', 'ripper'), // 19,   2 сверху
            new EvolutionTreeIcon(scene, w * -0.145, h * 0.419, [], 'UI', biw, bih, 100, [], 'hero', 'chastener'), // 20,   2 слева
            new EvolutionTreeIcon(scene, w * 0.04, h * 0.419, [], 'UI', biw, bih, 100, [], 'hero', 'raven'), // 21,   2 справа

            new EvolutionTreeIcon(scene, w * 0.07, h * 0.209, [], 'UI', biw, bih, 100, [], 'hero', 'hangman'), // 22,   3 снизу
            new EvolutionTreeIcon(scene, w * 0.21, h * -0.419, [], 'UI', biw, bih, 100, [], 'hero', 'reaper'), // 23,   3 справа
            new EvolutionTreeIcon(scene, w * 0.024, h * -0.419, [], 'UI', biw, bih, 100, [], 'hero', 'peacemaker'), // 24,   3 слева

            new EvolutionTreeIcon(scene, w * 0.417, h * -0.209, [], 'UI', biw, bih, 100, [], 'hero', 'hornet'), // 25,   4 сверху
            new EvolutionTreeIcon(scene, w * 0.464, 0, [], 'UI', biw, bih, 100, [], 'hero', 'demon'), // 26,   4 справа
            new EvolutionTreeIcon(scene, w * 0.324, h * 0.209, [], 'UI', biw, bih, 100, [], 'hero', 'typhoon'), // 27,   4 снизу
        ];

        this.add(this.icons);
    }

    update() {
        this.image.setTexture('evolution_soldier');
        this.image.setDisplaySize(this.tableW * 0.87, this.tableW * 0.32);
        this.setSize(this.tableW * 0.87, this.tableW * 0.32);

        super.update();
    }

    updateContent(unitData) {
        super.updateContent(unitData);

        const rank = unitData['rank'];
        const level = unitData['level'];
        const icons = this.icons;
        let curIcon;

        // Блокируем каждую ячейку, затем открываем все до текущего ранга
        icons.forEach(icon => {
            icon.block();
            const reqRank = icon.requiredRank;

            let rankInt = Number.isInteger(rank);
            let reqRankInt = Number.isInteger(reqRank);

            if ((rank != 0) && (reqRank <= rank)) {
                if ((rankInt && reqRankInt) || (reqRankInt || !reqRankInt && (~~reqRank == ~~rank))) {
                    icon.unlock();
                    curIcon = icon;
                }
            }
        });

        let intRank = Number.isInteger(rank) && (~~(level / 5) > rank);
        let floatRank = !Number.isInteger(rank) && (~~(level / 5) > Math.ceil(curIcon.requiredRank));

        // Если ранг не нулевой, открываем ячейки следующих солдат
        if ((rank != 0) && (intRank || floatRank)) {
            curIcon.nextIcons.forEach(i => this.handleIconClick(unitData, icons[i]));
        }

        // Если рекрут достиг 5 уровня, делаем доступной ячейку первого солдата
        if (rank == 0 && level >= 5) {
            this.handleIconClick(unitData, icons[0]);
        }

        this.handleIconPointerOver(unitData);
    }

    handleIconClick(unitData, icon) {
        icon.makeAvailable();

        icon.off('click').on('click', async () => {
            const data = await apiClient.units.soldiers.rankUp({ id: unitData['id'], type: icon.type });
            console.log(data);

            const table = InfoTables.tables.get('barraks').tableCenter;
            table.updateUnitScroll(data['data']['units']);
            InfoTables.showWarningText(data['message']);

            this.parentContainer.unitMiniTable.hide();
            this.parentContainer.heroMiniTable.hide();
        });
    }

    // Возможно буду менять этот метод, но пока работает, поэтому не трогаю
    handleIconPointerOver(unitData) {
        const unitMiniTable = this.parentContainer.unitMiniTable;
        const heroMiniTable = this.parentContainer.heroMiniTable;
        const rank = unitData['rank'];

        this.icons.forEach(icon => {
            icon.off('hover').on('hover', () => {

                const reqRank = icon.requiredRank;

                // Параметры юнита для отображения
                const unit = {
                    health: 0,
                    armor: 0,
                    damage: 0,
                    accuracy: 0,
                    speed: 0,
                    range: 0,
                }

                // Если навели на иконку героя
                if (icon.type == 'hero') {
                    heroMiniTable.show(heroData[icon.name], { key: icon.name });

                } else {
                    let bothInt = Number.isInteger(reqRank) && Number.isInteger(rank);
                    let iconFloat = !Number.isInteger(reqRank) && Number.isInteger(rank) && reqRank > rank;
                    let bothFloat = !Number.isInteger(reqRank) && !Number.isInteger(rank) && ~~reqRank == ~~rank;

                    if (bothInt) {
                        const rankDif = reqRank - rank;

                        unit['health'] = rankDif * 90;
                        unit['armor'] = rankDif * 5;
                        unit['damage'] = rankDif * 9;
                        unit['accuracy'] = rankDif * 6;
                        unit['speed'] = rankDif * 0;
                        unit['range'] = rankDif * 0;

                        unitMiniTable.show(unit, { rank: reqRank });

                    } else if (iconFloat) {
                        const rankDif = ~~reqRank - rank;
                        const sideRankDif = ~~(reqRank * 10 - ~~reqRank * 10);
                        let curDamage = unitData['damage'];

                        switch (icon.type) {
                            case 'speed':
                                unit['health'] = rankDif * 90 + sideRankDif * 90;
                                unit['armor'] = rankDif * 5 + sideRankDif * 1;
                                unit['damage'] = 2 * (rankDif * 9 + sideRankDif * 6) + +curDamage;
                                unit['accuracy'] = rankDif * 6 + sideRankDif * 10;
                                unit['speed'] = rankDif * 0 + sideRankDif * 1;
                                unit['range'] = rankDif * 0 + sideRankDif * 0;
                                break;
                            case 'moderate':
                                unit['health'] = rankDif * 90 + sideRankDif * 90;
                                unit['armor'] = rankDif * 5 + sideRankDif * 2;
                                unit['damage'] = 2 * (rankDif * 9 + sideRankDif * 10) + +curDamage;
                                unit['accuracy'] = rankDif * 6 + sideRankDif * 8;
                                unit['speed'] = rankDif * 0 + sideRankDif * 0;
                                unit['range'] = rankDif * 0 + sideRankDif * 0;
                                break;
                            case 'heavy':
                                unit['health'] = rankDif * 90 + sideRankDif * 90;
                                unit['armor'] = rankDif * 5 + sideRankDif * 3;
                                unit['damage'] = rankDif * 9 + sideRankDif * 8;
                                unit['accuracy'] = rankDif * 6 + sideRankDif * 6;
                                unit['speed'] = rankDif * 0 + sideRankDif * 0;
                                unit['range'] = rankDif * 0 + sideRankDif * 1;
                                break;
                        }

                        unitMiniTable.show(unit, { rank: reqRank });

                    } else if (bothFloat) {
                        const sideRankDiff = ~~((reqRank - rank) * 10);

                        switch (icon.category) {
                            case 'speed':
                                unit['health'] = sideRankDiff * 90;
                                unit['armor'] = sideRankDiff * 1;
                                unit['damage'] = sideRankDiff * 6;
                                unit['accuracy'] = sideRankDiff * 10;
                                unit['speed'] = sideRankDiff * 1;
                                unit['range'] = sideRankDiff * 0;
                                break;
                            case 'moderate':
                                unit['health'] = sideRankDiff * 90;
                                unit['armor'] = sideRankDiff * 2;
                                unit['damage'] = sideRankDiff * 20;
                                unit['accuracy'] = sideRankDiff * 8;
                                unit['speed'] = sideRankDiff * 0;
                                unit['range'] = sideRankDiff * 0;
                                break;
                            case 'heavy':
                                unit['health'] = sideRankDiff * 90;
                                unit['armor'] = sideRankDiff * 3;
                                unit['damage'] = sideRankDiff * 8;
                                unit['accuracy'] = sideRankDiff * 6;
                                unit['speed'] = sideRankDiff * 0;
                                unit['range'] = sideRankDiff * 1;
                                break;
                        }

                        unitMiniTable.show(unit, { rank: reqRank });
                    }
                }

            });

            icon.off('out').on('out', () => {
                unitMiniTable.hide();
                heroMiniTable.hide();
            });
        });
    }
}
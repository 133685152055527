const battleObstacleData = {
    "MainBaseBattle1": {
        "spaceship": {
            "x": 0.4089, 
            "y": 0.5673,
            "w": 0.3721, 
            "h": 0.239, 
        },
        "goraltus": {
            "x": 0.2034, 
            "y": 0.266,
            "w": 0.0826, 
            "h": 0.085, 
        },
        "tree": {
            "x": 0.3995, 
            "y": 0.255,
            "w": 0.0323, 
            "h": 0.0599, 
        },
        "forest": {
            "x": 0.451, 
            "y": 0.2715,
            "w": 0.101, 
            "h": 0.108, 
        },
    },
    "MainBaseBattle2": {
        "tree_1": {
            "x": 0.014, 
            "y": 0.3724,
            "w": 0.021, 
            "h": 0.0388, 
        },
        "tree_2": {
            "x": 0.0205, 
            "y": 0.451,
            "w": 0.0813, 
            "h": 0.0674, 
        },
        "tree_3": {
            "x": 0.1385, 
            "y": 0.475,
            "w": 0.0823, 
            "h": 0.1265, 
        },
        "tree_4": {
            "x": 0.1128, 
            "y": 0.38,
            "w": 0.1333, 
            "h": 0.0836, 
        },
        "tree_5": {
            "x": 0.2536, 
            "y": 0.442,
            "w": 0.0451, 
            "h": 0.0685, 
        },
        "tree_6": {
            "x": 0.287, 
            "y": 0.484,
            "w": 0.0294, 
            "h": 0.051, 
        },
        "tree_7": {
            "x": 0.2365, 
            "y": 0.473,
            "w": 0.021, 
            "h": 0.043, 
        },
        "tree_8": {
            "x": 0.818, 
            "y": 0.426,
            "w": 0.089, 
            "h": 0.079, 
        },
        "tree_9": {
            "x": 0.9122, 
            "y": 0.3953,
            "w": 0.021, 
            "h": 0.0405, 
        },
        "tree_10": {
            "x": 0.9497, 
            "y": 0.419,
            "w": 0.039, 
            "h": 0.0725, 
        },
    },
    "MainBaseBattle3": {
        "tree_1": {
            "x": 0.0865, 
            "y": 0.2898,
            "w": 0.03, 
            "h": 0.0557, 
        },
        "tree_2": {
            "x": 0.0346, 
            "y": 0.3429,
            "w": 0.0214, 
            "h": 0.0401, 
        },
        "tree_3": {
            "x": 0.0526, 
            "y": 0.4283,
            "w": 0.0365, 
            "h": 0.0672, 
        },
        "tree_4": {
            "x": 0.2938, 
            "y": 0.4617,
            "w": 0.0349, 
            "h": 0.0568, 
        },
        "tower_1": {
            "x": 0.5253, 
            "y": 0.3429,
            "w": 0.1219, 
            "h": 0.1323, 
        },
        "tower_2": {
            "x": 0.643, 
            "y": 0.3966,
            "w": 0.0607, 
            "h": 0.0727, 
        },
    },
    "MainBaseBattle4": {
        "tree_1": {
            "x": 0.0177, 
            "y": 0.6773,
            "w": 0.0456, 
            "h": 0.076, 
        },
        "tree_2": {
            "x": 0.1044, 
            "y": 0.6938,
            "w": 0.0323, 
            "h": 0.0596, 
        },
        "tree_3": {
            "x": 0.1945, 
            "y": 0.4484,
            "w": 0.0292, 
            "h": 0.0557, 
        },
        "tree_4": {
            "x": 0.2341, 
            "y": 0.4867,
            "w": 0.0227, 
            "h": 0.0426, 
        },
    },
    "MainBaseBattle5": {
        "wall": {
            "x": 0.0747, 
            "y": 0.472,
            "w": 0.0875, 
            "h": 0.0925, 
        },
    },
};

export default battleObstacleData;
import FactoryTable from "./FactoryTable.js";


export default class SiliconFactoryTable extends FactoryTable {

    create() {
        this.item = 'silicon';
        this.ore = 'silicon_ore';
        this.storeItems = ['silicon', 'silicon_plate', 'glass', 'silicon_glass', 'lense', 'prism'];
        this.targetScene = 'Silicon_Quarry';
        super.create();
    }
}
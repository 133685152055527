import SceneManager from "../../../managers/SceneManager";
import Button from "./common/Button";

export class ChangeSceneButton extends Button {
    constructor(scene, x, y, children, texture, width, height, color, targetScene) {
        super(scene, x, y, children, texture, width, height, color);
        
        this.targetScene = targetScene;
    }

    click() {
        super.click();
        SceneManager.loadScene(this.scene, this.targetScene);
    }
}
import UIController, { ScrollManager } from '../../../../../managers/UIController.js';
import SingleInfoTable from '../common/SingleInfoTable.js';
import apiClient from '../../../../../services/apiClient.js';
import ArmyStatsSection from './components/ArmyStatsSection.js';


export default class ArmyStatsTable extends SingleInfoTable {

    create() {
        super.create();
        const w = this.width;
        const h = this.height;

        // Линия
        this.add(this.scene.add.line(0, 0, 0, h * -0.32, w * 0.64, h * -0.32, 0x87e9ff));
    }

    addInfo(w, h) {
        this.arrangeCells(w, h);

        const items = this.scene.game.registry.get('items');
        const healItemsArray = {
            'morion': items['morion'], 
            'medkit': items['medkit'], 
            'blood': items['blood'], 
            'crystall': items['crystall']
        };

        UIController.arrangeItems({
            container: this,
            itemsData: healItemsArray,
            x: w * -0.3,
            y: h * -0.345,
            iconSize: h * 0.03,
            fontSize: h * 0.02,
            space: w * 0.07,
        });
    }

    async arrangeCells(w, h) {
        const scrollW = w * 0.65;
        const scrollH = h * 0.63;
        const spaceTop = h * 0.03;

        this.scroll = new ScrollManager(this.scene, {
            x: 0,
            y: h * -0.003,
            width: scrollW,
            height: scrollH,

            scrollMode: 0,

            space: {
                panel: {
                    top: spaceTop,
                    bottom: spaceTop,
                }
            }
        }, {
            space: {
                line: spaceTop,
            },
        });

        this.add(this.scroll.scrollPanel);
    }

    async update(category) {
        super.update(category);

        const data = await apiClient.units.getAll();

        if (data['status'] != 'success') {
            return;
        }

        this.armyData = data['units'];
        console.log(data);

        if (!this.scroll) {
            this.addInfo(this.width, this.height);
        }

        if (this.sectionsAdded) {
            this.scroll.removeItems();
        }

        this.addSections(this.width, this.height);
    }

    sortUnits(units) {
        const followRules = {
            'main_hero': ['soldier'],
            'TechNastya': ['robot'],
            'Chlapidol': ['mutant'],
            'Shtryzik': ['irradiated']
        };
    
        const characters = [];
        const unitsByCategory = {};
    
        // Divide units on characters and others
        units.forEach(unit => {
            if (followRules[unit.name]) {
                characters.push(unit);
            } else {
                if (!unitsByCategory[unit.category]) {
                    unitsByCategory[unit.category] = [];
                }
                unitsByCategory[unit.category].push(unit);
            }
        });
    
        const sortedUnits = [];
    
        // Result
        characters.forEach(character => {
            sortedUnits.push(character);
            const categoriesToFollow = followRules[character.name];
            categoriesToFollow.forEach(category => {
                if (unitsByCategory[category]) {
                    sortedUnits.push(...unitsByCategory[category]);
                    delete unitsByCategory[category];
                }
            });
        });
    
        // Добавляем оставшиеся юниты, если они остались
        Object.values(unitsByCategory).forEach(categoryUnits => {
            sortedUnits.push(...categoryUnits);
        });

        return sortedUnits;
    }

    addSections(w, h) {
        const data = this.sortUnits(this.armyData);

        for (let id in data) {
            let section = new ArmyStatsSection(this.scene, 0, 0, [], w, h, data[id]);
            this.scroll.addItem(section);
        }

        this.sectionsAdded = true;
    }

    async updateInfo(data) {
        for (let item of this.scroll.items) {
            item.update(data);
        }
    }
}

import GameScene from '../core/gameScene.js';
import WarningText from '../../modules/ui/common/WarningText.js';
import ComCenterTableContainer from '../../modules/ui/info_tables/containers/ComCenterTableContainer.js';
import TechCenterTableContainer from '../../modules/ui/info_tables/containers/TechCenterTableContainer.js';
import ResearchCenterTableContainer from '../../modules/ui/info_tables/containers/ResearchCenterTableContainer.js';
import GeneticCenterTableContainer from '../../modules/ui/info_tables/containers/GeneticCenterTableContainer.js';
import BarraksTableContainer from '../../modules/ui/info_tables/containers/BarraksTableContainer.js';
import ResearchLabTableContainer from '../../modules/ui/info_tables/containers/ResearchLabTableContainer.js';
import GeneticLabTableContainer from '../../modules/ui/info_tables/containers/GeneticLabTableContainer.js';
import StoreTableContainer from '../../modules/ui/info_tables/containers/StoreTableContainer.js';
import RoboCenterTableContainer from '../../modules/ui/info_tables/containers/RoboCenterTableContainer.js';
import HospitalTableContainer from '../../modules/ui/info_tables/containers/HospitalTableContainer.js';
import MachineFactoryTableContainer from '../../modules/ui/info_tables/containers/MachineFactoryTableContainer.js';
import IronFoundryTableContainer from '../../modules/ui/info_tables/containers/IronFoundryTableContainer.js';
import SiliconFactoryTableContainer from '../../modules/ui/info_tables/containers/SiliconFactoryTableContainer.js';
import TitanFoundryTableContainer from '../../modules/ui/info_tables/containers/TitanFoundryTableContainer.js';
import ArmyTableContainer from '../../modules/ui/info_tables/containers/ArmyTableContainer.js';
import SocialTableContainer from '../../modules/ui/info_tables/containers/SocialTableContainer.js';
import TaskTableContainer from '../../modules/ui/info_tables/containers/TaskTableContainer.js';
import ShopTableContainer from '../../modules/ui/info_tables/containers/ShopTableContainer.js';
import ContainersTableContainer from '../../modules/ui/info_tables/containers/ConainersTableContainer.js';
import DailyBonusTableContainer from '../../modules/ui/info_tables/containers/DailyBonusTableContainer.js';
import ProductionStatsTableContainer from '../../modules/ui/info_tables/containers/ProductionStatsTableContainer.js';
import BattleWinTableContainer from '../../modules/ui/info_tables/containers/BattleWinTableContainer.js';
import BattleDefeatTableContainer from '../../modules/ui/info_tables/containers/BattleDefeatTableContainer.js';
import apiClient from '../../services/apiClient.js';
import ProcessesTableContainer from '../../modules/ui/info_tables/containers/ProcessesTableContainer.js';
import BattlesTableContainer from '../../modules/ui/info_tables/containers/BattlesTableContainer.js';
import { updateUI } from './UI.js';
import HelicopterTableContainer from '../../modules/ui/info_tables/containers/HelicopterTableContainer.js';
import ConfirmationTable from '../../modules/ui/common/ConfirmationTable.js';


export default class InfoTables extends GameScene {
    static tables = new Map();

    constructor() {
        super('InfoTables');
        InfoTables.instance = this;
    }

    create() {
        super.create();
        this.addUI();
        this.updateUI();

        this.scale.on('resize', () => {
            this.updateUI();
        });
    }

    addUI() {
        this.tableW = window.screen.height * 0.8 * 1.78;
        this.tableH = window.screen.height * 0.8;

        this.addExtraUI();

        InfoTables.warningText = new WarningText(InfoTables.instance, 0, 0, []);
        InfoTables.confirmationTable = new ConfirmationTable(InfoTables.instance, 0, 0, []);
        InfoTables.addInfoTables(this);
        this.getFinishedProcessesAndOpenTable();
    }

    updateUI() {
        InfoTables.warningText.update();
        InfoTables.confirmationTable.update();
        InfoTables.tables.forEach((table) => {
            table.setPosition(window.innerWidth / 2, window.innerHeight * 0.52);
        });
        this.updateExtraUI();
    }

    addExtraUI() {
        this.infoTableMainScrollMask = this.add.image(0, 0, 'table_mask').setOrigin(0.5, 0.5)
            .setDisplaySize(this.tableW, this.tableH).setVisible(false);
    }

    updateExtraUI() {
        this.infoTableMainScrollMask.setPosition(window.innerWidth / 2, window.innerHeight * 0.52);
    }

    static addInfoTables(scene) {
        this.tables.set('com_center', new ComCenterTableContainer(scene, 0, 0, [], 'com_center', 'center'));
        this.tables.set('hangar', new HelicopterTableContainer(scene, 0, 0, [], 'helicopter', 'center'));
        this.tables.set('helicopter', new HelicopterTableContainer(scene, 0, 0, [], 'helicopter', 'center'));
        this.tables.set('tech_center', new TechCenterTableContainer(scene, 0, 0, [], 'tech_center', 'center'));
        this.tables.set('robo_center', new RoboCenterTableContainer(scene, 0, 0, [], 'robo_center', 'center'));
        this.tables.set('genetic_center', new GeneticCenterTableContainer(scene, 0, 0, [], 'genetic_center', 'center'));
        this.tables.set('genetic_lab', new GeneticLabTableContainer(scene, 0, 0, [], 'genetic_lab', 'center'));
        this.tables.set('research_center', new ResearchCenterTableContainer(scene, 0, 0, [], 'research_center', 'center'));
        this.tables.set('research_lab', new ResearchLabTableContainer(scene, 0, 0, [], 'research_lab', 'center'));
        this.tables.set('barraks', new BarraksTableContainer(scene, 0, 0, [], 'barraks', 'center'));
        this.tables.set('hospital', new HospitalTableContainer(scene, 0, 0, [], 'hospital', 'center'));
        this.tables.set('store', new StoreTableContainer(scene, 0, 0, [], 'store', 'center'));
        // Заводы
        this.tables.set('machine_factory', new MachineFactoryTableContainer(scene, 0, 0, [], 'machine_factory', 'center'));
        this.tables.set('iron_foundry', new IronFoundryTableContainer(scene, 0, 0, [], 'iron_foundry', 'center'));
        this.tables.set('silicon_factory', new SiliconFactoryTableContainer(scene, 0, 0, [], 'silicon_factory', 'center'));
        this.tables.set('titan_foundry', new TitanFoundryTableContainer(scene, 0, 0, [], 'titan_foundry', 'center'));
        // Армия
        this.tables.set('army', new ArmyTableContainer(scene, 0, 0, [], 'army', 'center'));
        this.tables.set('battle_win', new BattleWinTableContainer(scene, 0, 0, [], 'army', 'center'));
        this.tables.set('battle_defeat', new BattleDefeatTableContainer(scene, 0, 0, [], 'army', 'center'));

        // Интерфейс
        this.tables.set('social', new SocialTableContainer(scene, 0, 0, [], 'social', 'center'));
        this.tables.set('tasks', new TaskTableContainer(scene, 0, 0, [], 'tasks', 'center'));
        this.tables.set('containers', new ContainersTableContainer(scene, 0, 0, [], 'containers', 'center'));
        this.tables.set('shop', new ShopTableContainer(scene, 0, 0, [], 'shop', 'center'));
        this.tables.set('daily_bonus', new DailyBonusTableContainer(scene, 0, 0, [], 'daily_bonus', 'center'));
        this.tables.set('production', new ProductionStatsTableContainer(scene, 0, 0, [], 'production', 'center'));
        this.tables.set('processes', new ProcessesTableContainer(scene, 0, 0, [], 'processes', 'center'));
        this.tables.set('battles', new BattlesTableContainer(scene, 0, 0, [], 'army', 'center'));
    }

    async getFinishedProcessesAndOpenTable() {
        const data = await apiClient.processes.getFinished();

        if (data['status'] != 'success') {
            return;
        }
        console.log(data);

        if (data['processes'].length == 0) {
            return;
        }
        
        InfoTables.openInfoTable('processes', { processes: data['processes'] });
        this.game.registry.set('items', data['items']);
        updateUI(this);
    }

    static openInfoTable(key, options = {}) {
        const table = this.tables.get(key);
        this.curTable = table;
        table.setData('data', options);
        table.open(options['position'] || 'center');
    }

    static showWarningText(text) {
        InfoTables.instance.children.bringToTop(this.warningText);
        this.warningText.show(text);
    }

    static openConfirmationTable(data, callback) {
        InfoTables.instance.children.bringToTop(this.confirmationTable);
        InfoTables.confirmationTable.open(data, callback);
    }
}




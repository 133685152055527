import ItemIcon from "../../../../../main/icons/ItemIcon";

export default class ModuleCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th, table) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.tw = tw;
        this.th = th;
        this.table = table;

        this.free = true;

        this.moduleLevel = null;
        this.create(tw, th);
    }

    create(w, h) {
        this.add(this.frame = this.scene.add.sprite(0, 0, 'UI', 'table_button.png').setOrigin(0.5, 0.5));
        this.frame.setDisplaySize(h * 0.082, h * 0.082);

        this.frame.setTint(0xffcb6a).setAlpha(0.001);
    }

    addModule(key, moduleObject) {
        this.add(this.moduleIcon = new ItemIcon(this.scene, 0, 0, [], 'soldier_module_icons', this.th * 0.057, this.th * 0.057));
        this.moduleIcon.setIconData({ name: key, amount: 0 });
        this.moduleIcon.amountText.destroy();
        this.moduleIcon.obj.setOrigin(0.5, 0.5);

        this.moduleLevel = parseInt(key.replace(/\D/g, ''));
        this.moduleKey = key.replace(/[0-9]/g, '');

        this.frame.setAlpha(1);
        this.moduleObject = moduleObject; // Module in scroll
        this.free = false;

        this.moduleIcon.setClickCallback(this.removeModule.bind(this));
        this.table.newModuleCell.checkModulesIdentity();
    }

    removeModule() {
        this.deleteModule();

        let amount = this.moduleObject.getData('amount');
        this.moduleObject.updateAmount(++amount);
    }

    deleteModule() {
        this.remove(this.moduleIcon);
        this.moduleIcon.destroy();
        this.frame.setAlpha(0.001);
        this.free = true;

        this.moduleIcon = null;
        this.moduleLevel = null;

        this.table.newModuleCell.checkModulesIdentity();
    }
}
import loadAssets from "../../../../managers/AssetManager";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN } from "../../../../utils/textStyles";

export default class JournalCharacterInfo extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children) {
        super(scene, x, y, children);
        this.scene.add.existing(this);

        this.w = window.screen.height * 0.35 * 2.84;
        this.h = window.screen.height * 0.35;
        this.create(this.w, this.h);
        this.category = 'characters';
    }

    create(w, h) {
        this.add(this.photo = this.scene.add.image(w * 0.017, h * 0.024, 'photo_main_hero').setOrigin(0, 0));
        this.add(this.frame = this.scene.add.image(0, 0, 'journal_character_info').setOrigin(0, 0).setDisplaySize(w, h));

        // Размер шрифта
        const nameFS = window.screen.height * 0.02;
        const statFS = window.screen.height * 0.017;

        // Полное имя персонажа
        this.add(this.name = this.scene.add.text(w * 0.65, h * 0.1, '-').setOrigin(0.5, 1).setStyle(TEXT_STYLE_GREEN).setFontSize(nameFS));

        this.add(this.birthday = this.scene.add.text(w * 0.67, h * 0.215, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(statFS));
        this.add(this.height = this.scene.add.text(w * 0.67, h * 0.325, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(statFS));
        this.add(this.weight = this.scene.add.text(w * 0.67, h * 0.435, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(statFS));
        this.add(this.role = this.scene.add.text(w * 0.67, h * 0.545, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(statFS));
        this.add(this.mentalCond = this.scene.add.text(w * 0.67, h * 0.655, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(statFS));
        this.add(this.lifePriorities = this.scene.add.text(w * 0.67, h * 0.765, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(statFS));
    }

    async displayCurrentInfo(name) {
        const data = this.scene.journalData['data']['characters'][name];

        await this.loadData(name);

        this.photo.setTexture(`photo_${name}`).setDisplaySize(this.h * 0.83, this.h * 0.89);
        this.name.setText(data['name']);
        
        this.birthday.setText(data['birthday']);
        this.height.setText(data['height']);
        this.weight.setText(data['weight']);
        this.role.setText(data['role']);
        this.mentalCond.setText(data['mental_cond']);
        this.lifePriorities.setText(data['life_priorities']);
    }

    async loadData(name) {
        const texture = `photo_${name}`;
        await loadAssets(this.scene, 'image', texture, `UI/journal/tabs/${this.category}/images/${name}.png`);
    }
}
import SingleInfoTable from "../common/SingleInfoTable.js";
import { ScrollManager } from "../../../../../managers/UIController.js";
import ProcessCell from "./components/ProcessCell.js";


export default class FinishedProcessesTable extends SingleInfoTable {
    constructor(scene, x, y, children, width, height, texture) {
        super(scene, x, y, children, width, height, texture);
    }

    async update(category) {
        await super.update(category);

        if (this.cellsArranged) return;
        this.arrangeCells(this.width, this.height);
    }

    arrangeCells(w, h) {
        const scrollW = w * 0.65;
        const scrollH = h * 0.69;
        const spaceTop = h * 0.03;

        this.scroll = new ScrollManager(this.scene, {
            x: 0,
            y: h * -0.033,
            width: scrollW,
            height: scrollH,

            scrollMode: 0,

            space: {
                panel: {
                    top: spaceTop,
                    bottom: spaceTop,
                }
            }
        }, {
            space: {
                line: spaceTop,
            },
        });

        this.add(this.scroll.scrollPanel);
        this.scroll.sizer.setOrigin(-0.05, 0);
        this.addContent();
    }

    addContent() {
        const data = this.parentContainer.getData('data')['processes'];

        for (let key in data) {
            let cell = new ProcessCell(this.scene, 0, 0, [], this.width * 0.55, this.width * 0.06226, key, data[key]);
            this.scroll.addItem(cell);
        }
        this.cellsArranged = true;
    }
}
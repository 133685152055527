import SceneManager from '../../../managers/SceneManager.js';
import InfoTables from '../../../scenes/ui/InfoTables.js';
import { TEXT_STYLE_BLUE } from '../../../utils/textStyles.js';
import InteractiveObject from '../../main/InteractiveObject.js';

class JournalButton extends InteractiveObject {
    constructor(scene, x, y, children, texture, width, height, color) {
        super(scene, x, y, children, texture, width, height);

        this.color = (color == 'blue') ? 0xa9e8ff : color;
    }

    create() {
        super.create();
        this.obj.setTexture('journal_UI').setFrame(`${this.texture}.png`);
        this.obj.setDisplaySize(this.width, this.height);
        
        this.glowPlugin = this.scene.plugins.get('rexGlowFilterPipeline');
        
        this.disable();
        this.makeInteractive();
    }

    resize(w, h) {
        this.obj.setDisplaySize(w, h);
    }

    pointerOver() {
        this.obj.setTint(this.color);

        this.glowPlugin.add(this.obj, {
            distance: 10,

            outerStrength: 0.5,
            innerStrength: 0.5,
            glowColor: this.color,
        });
    }

    pointerOut() {
        this.obj.clearTint();
        this.glowPlugin.remove(this.obj);
    }
}


export class ButtonCloseJournal extends JournalButton {

    create() {
        super.create();
        this.obj.setOrigin(1, 0);
    }

    click() {
        super.click();
        SceneManager.loadScene(this.scene, SceneManager.curScene);
    }
}


export class ButtonToggleJournal extends JournalButton {
    click() {
        super.click();
        this.scene.toggleTabs();
    }
}


export class ButtonClaimJournal extends JournalButton {

    create() {
        super.create();
        this.disable();
    }

    setClickCallback(callback) {
        this.off('click').on('click', callback);
    }

    disable() {
        this.glowPlugin.remove(this.obj);
        this.obj.removeInteractive();
        this.obj.setTint(0x777777);
    }

    enable() {
        this.obj.setInteractive();
        this.obj.clearTint();
    }
}


export class ButtonFullScreen extends InteractiveObject {

    create() {
        super.create();
        this.obj.setTexture('UI').setFrame(`${this.texture}.png`);
        this.obj.setDisplaySize(this.width, this.height);
    }

    pointerOver() {
        super.pointerOver();
        this.obj.setTint(0xbdbdbd);
    }

    pointerOut() {
        super.pointerOut()
        this.obj.clearTint();
    }

    click() {
        super.click();

        if (this.scene.scale.isFullscreen) {
            this.scene.scale.stopFullscreen();
        } else {
            this.scene.scale.startFullscreen();
        }
    }

    resize(w, h) {
        this.obj.setDisplaySize(w, h);
    }
}


export class JournalTab extends JournalButton {
    constructor(scene, x, y, children, texture, width, height, color, title) {
        super(scene, x, y, children, texture, width, height, color);
        scene.add.existing(this);
        
        this.title = title;

        this.addTitle();
        this.obj.setTint(this.color);
    }

    resize(w, h) {
        this.obj.setDisplaySize(w, h);
        this.text.setFontSize(h * 0.5).setPosition(w / 2, h * 0.48);
    }

    pointerOut() {
        super.pointerOut();
        this.obj.setTint(this.color);
    }

    click() {
        super.click();
        this.scene.displayCategoryInfo(this.title);
    }

    addTitle() {
        this.add(this.text = this.scene.add.text(0, 0, this.title).setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_BLUE));
    }

}
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN } from "../../../utils/textStyles";
import ActionButton from "../../main/buttons/common/ActionButton";

export default class ConfirmationTable extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.create();
    }

    create() {
        this.addDarkScreen();
        this.setVisible(false);

        this.add(this.bg = this.scene.add.image(0, 0, 'confirm_table').setOrigin(0.5, 0.5));

        const w = window.screen.width * 0.25;
        const h = w * (this.bg.height / this.bg.width);

        this.bg.setDisplaySize(w, h);
        this.addButtons(w, h);
        this.addInfo(w, h);
    }

    addDarkScreen() {
        let w = window.screen.width;
        let h = window.screen.height;

        this.add(this.darkScreen = this.scene.add.rectangle(0, 0, w * 2, h * 2, 0x000000, 0.5));
        this.darkScreen.setInteractive();
    }

    addButtons(w, h) {
        this.add(this.exitButton = new ActionButton(this.scene, w * 0.353, h * -0.405, [], 'table_button', h * 0.21, h * 0.21, 'red', this.close.bind(this)));
        this.add(this.confirmButton = new ActionButton(this.scene, w * 0.002, h * 0.411, [], 'shop_button', w * 0.48, w * 0.48 * 0.216, 'green', this.confirm.bind(this)));
    }

    addInfo(w, h) {
        this.add(this.title = this.scene.add.text(0, h * -0.4, 'Морион').setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.07));
        this.add(this.morionIcon = this.scene.add.sprite(w * -0.353, h * -0.405, 'items_icons', 'morion.png').setDisplaySize(h * 0.12, h * 0.12));

        this.add(this.signText = this.scene.add.text(0, h * -0.2, 'Потратить морион?').setOrigin(0.5, 0)
            .setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.07).setWordWrapWidth(w * 0.7).setAlign('center'));
        this.add(this.buttonText = this.scene.add.text(0, h * 0.411, 'Подтвердить').setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.08));
    }

    update() {
        this.setPosition(window.innerWidth / 2, window.innerHeight / 2);
    }

    updateInfo(data) {
        this.title.setText(data['title'] ?? 'Морион');
        this.signText.setText(data['text'] ?? 'Потратить морион?');
        this.buttonText.setText(data['button'] ?? 'Подтвердить');
    }

    open(data, callback) {
        this.setVisible(true);
        this.updateInfo(data);

        this.confirmCallback = callback;
    }

    close() {
        this.setVisible(false);
    }

    confirm() {
        this.confirmCallback();
        this.close();
    }
}
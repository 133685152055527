import GameScene from '../core/gameScene.js';
import InfoTables from './InfoTables.js';
import { TEXT_STYLE_BLUE, TEXT_STYLE_ORANGE } from '../../utils/textStyles.js';
import apiClient from '../../services/apiClient.js';
import { NoActionButton, OpenInfoTableButton, ToggleAudioButton, ToggleFullScreenButton, ToggleMusicButton } from '../../modules/main/buttons/common/UIButtons.js';
import Button from '../../modules/main/buttons/common/Button.js';
import { ChangeSceneButton } from '../../modules/main/buttons/ChangeSceneButton.js';
import AudioManager from '../../managers/AudioManager.js';
import SceneManager from '../../managers/SceneManager.js';
import { ButtonJournal } from '../../modules/main/buttons/ButtonJournal.js';
import { ButtonBattles } from '../../modules/main/buttons/ButtonBattles.js';
import AnimationManager from '../../managers/AnimationManager.js';
import eventBus from '../../services/eventBus.js';


export default class UI extends GameScene {
    constructor() {
        super({ key: 'UI' });
    }

    async preload() {
        super.preload();

        this.load.setPath('UI/ui/game/');

        this.load.image('game_ui_left', 'left.png');
        this.load.image('game_ui_right', 'right.png');
        this.load.atlas('UI', 'UI.png', 'UI.json');
        // Components
        this.load.image('avatar_mask', 'components/avatar_mask.png');
        this.load.image('progress_bar', 'components/progress_bar.png');
        this.load.image('table_mask', 'components/table_mask.png');
        this.load.image('confirm_table', 'components/confirm_table.png');


        this.load.setPath('UI/info_tables/');
        AnimationManager.load(this, 'ui', 'loading', 1024);

        this.load.image('table_placeholder', 'table_placeholder.png');
        this.load.image('table_fix', 'common/table_fix.png');
        this.load.atlas('character_equipment', 'common/characters/character_equipment.png', 'common/characters/character_equipment.json');

        this.load.image('shine', 'tables/shop/components/shine.png');
        this.load.image('shop_cell', 'tables/shop/components/shop_cell.png');

        this.load.setPath('UI/icons/');

        this.load.atlas('building_icons', 'buildings/buildings.png', 'buildings/buildings.json');
        this.load.atlas('parameters_icons', 'units/parameters.png', 'units/parameters.json');
        this.load.atlas('unit_icons', 'units/icons.png', 'units/icons.json');
        this.load.atlas('animal_icons', 'units/animals.png', 'units/animals.json');
        this.load.atlas('items_icons', 'ui/items/resources.png', 'ui/items/resources.json');
        this.load.atlas('container_icons', 'ui/items/containers.png', 'ui/items/containers.json');
        this.load.atlas('soldier_module_icons', 'ui/items/soldier_modules.png', 'ui/items/soldier_modules.json');
        this.load.atlas('machine_body_icons', 'machines/machine_body.png', 'machines/machine_body.json');
        this.load.atlas('machine_icons', 'machines/machines.png', 'machines/machines.json');
        this.load.atlas('unit_category_icons', 'units/mini/mini_icons.png', 'units/mini/mini_icons.json');


        this.load.setPath('audio/');

        let musicList = ['environment', 'cave', 'battle'];
        musicList.forEach(assetName => {
            this.load.audio(`music_${assetName}`, [`music/${assetName}.mp3`]);
        });

        this.load.audio('button_click', ['sounds/interface/button_click.mp3']);
        this.load.audio('icon_click', ['sounds/interface/icon_click.mp3']);
        this.load.audio('building_click', ['sounds/map_objects/building_click.wav']);
        this.load.audio('portal_click', ['sounds/map_objects/portal_click.mp3']);
        this.load.audio('spaceship_click', ['sounds/map_objects/spaceship_click.mp3']);
        this.load.audio('scene_click', ['sounds/map_objects/scene_click.mp3']);
        this.load.audio('zazulic_click', ['sounds/zazulic_click.wav']);
        // Бой
        this.load.audio('death_zazulic_nest', ['sounds/fight/death_zazulic_nest.mp3']);
        this.load.audio('zazulic_death', ['sounds/fight/zazulic_death.wav']);
        this.load.audio('death_soldier', ['sounds/fight/death_soldier.mp3']);
        this.load.audio('death_MC', ['sounds/fight/death_MC.mp3']);
        this.load.audio('shoot_MC', ['sounds/fight/shoot_MC.mp3']);
        this.load.audio('shoot_soldier', ['sounds/fight/shoot_soldier.mp3']);
        this.load.audio('attack_zazulic', ['sounds/fight/attack_zazulic.mp3']);
        this.load.audio('lightning_sound', ['sounds/fight/lightning.mp3']);
        this.load.audio('burner_sound', ['sounds/fight/burner.mp3']);

        this.load.setPath('');

        this.load.spritesheet('builder', 'anims/machines/builder.png', { frameWidth: 256, frameHeight: 256 });
        this.load.spritesheet('crystall_collector_anims', 'anims/machines/crystall_collector.png', { frameWidth: 256, frameHeight: 256 });
        this.load.spritesheet('miner_anims', 'anims/machines/miner.png', { frameWidth: 256, frameHeight: 256 });
        this.load.spritesheet('truck_anims', 'anims/machines/truck.png', { frameWidth: 256, frameHeight: 256 });

        this.load.setPath('UI/ui/battle/');
        this.load.atlas('battle_ui', 'UI.png', 'UI.json');
        this.load.image('enemy_avatar', 'enemy.png');

        this.load.setPath('');

        const data = await apiClient.users.getUserData();
        this.load.image('user_avatar', data['avatar'], { baseURL: '' });
        this.load.start();
    }

    create() {
        super.create();                        
        AnimationManager.createSingleAnimation(this, 'loading', 'loading', { start: 0, end: 7 }, 5, -1);
        
        this.scene.launch(SceneManager.curScene);
        this.scene.launch('InfoTables');
        this.scene.bringToTop(SceneManager.curScene);

        this.addUI();
        this.addAudio();
        this.setContentPosition();
        updateUI(this, true);

        this.scale.on('resize', () => {
            this.setContentPosition();
        });

        AudioManager.setMusic('environment');
        eventBus.on('updateItems', (items) => {
            this.game.registry.set('items', items);
            updateUI(this);
        });

        eventBus.on('missionCompleted', UI.btnJournal.blink.bind(UI.btnJournal, 0x00e2ff, this));
        eventBus.emit('missionCompleted');
    }

    update() {
        this.soundIcon['on'].setAlpha(AudioManager.soundOn);
        this.soundIcon['off'].setAlpha(!AudioManager.soundOn);

        this.musicIcon['on'].setAlpha(AudioManager.musicOn);
        this.musicIcon['off'].setAlpha(!AudioManager.musicOn);
    }

    addAudio() {
        // Музыка
        this.music = {
            "environment": this.sound.add('music_environment', { loop: true }),
            "cave": this.sound.add('music_cave', { loop: true }),
            "battle": this.sound.add('music_battle', { loop: true })
        }

        // Звуки
        this.audio = {
            "click": {
                "button": this.sound.add('button_click', { loop: false, volume: 0.5 }),
                "icon": this.sound.add('icon_click', { loop: false, volume: 0.5 }),
                "building": this.sound.add('building_click', { loop: false, volume: 0.5 }),
                "spaceship": this.sound.add('spaceship_click', { loop: false, volume: 0.5 }),
                "scene": this.sound.add('scene_click', { loop: false }),
                "portal": this.sound.add('portal_click', { loop: false, volume: 0.5 }),
                "zazulic": this.sound.add('zazulic_click', { loop: false, volume: 0.5 }),
            },
            "death": {
                "zazulic_nest": this.sound.add('death_zazulic_nest', { loop: false, volume: 0.5 }),
                "zazulic": this.sound.add('zazulic_death', { loop: false, volume: 0.5 }),
                "soldier": this.sound.add('death_soldier', { loop: false, volume: 0.5 }),
                "main_hero": this.sound.add('death_MC', { loop: false, volume: 0.5 }),
            },
            "attack": {
                "main_hero": this.sound.add('shoot_MC', { loop: false, volume: 0.5 }),
                "techNastya": this.sound.add('burner_sound', { loop: false, volume: 0.5 }),
                "soldier": this.sound.add('shoot_soldier', { loop: false, volume: 0.5 }),
                "zazulic": this.sound.add('attack_zazulic', { loop: false, volume: 0.5 }),
                "lightning": this.sound.add('lightning_sound', { loop: false, volume: 0.5 }),
            }
        }

        AudioManager.init(this.music, this.audio);
    }

    addUI() {
        // Графика
        this.glowPlugin = this.plugins.get('rexglowfilter2pipelineplugin');
        this.graphics = this.add.graphics();

        this.graphics.setDefaultStyles({
            lineStyle: {
                width: 1,
                color: 0x87e9ff,
                alpha: 1
            },
            fillStyle: {
                color: 0xffffff,
                alpha: 1
            }
        });

        // ИНТЕРФЕЙС ----------------------------------------------------------/
        this.UILeft = this.add.sprite(0, 0, 'game_ui_left').setOrigin(0, 0);
        this.UIRight = this.add.sprite(0, 0, 'game_ui_right').setOrigin(0, 0);
        //текст уровня игрока
        this.playerLvl = this.add.text(0, 0, '0').setOrigin(0.5, 0.5);

        // Аватарка и маска для нее
        this.avatarMask = this.add.image(0, 0, 'avatar_mask').setOrigin(0, 0).setVisible(false);
        this.playerAvatar = this.add.image(0, 0, 'user_avatar').setOrigin(0.5, 0);

        this.playerAvatar.setMask(this.avatarMask.createBitmapMask());

        this.soundIcon = {
            'on': this.add.sprite(0, 0, 'UI', 'sound_on.png').setOrigin(0, 0),
            'off': this.add.sprite(0, 0, 'UI', 'sound_off.png').setOrigin(0, 0).setAlpha(0)
        }
        this.musicIcon = {
            'on': this.add.sprite(0, 0, 'UI', 'music_on.png').setOrigin(0, 0),
            'off': this.add.sprite(0, 0, 'UI', 'music_off.png').setOrigin(0, 0).setAlpha(0)
        }

        // Ресурсы
        this.itemsText = {
            'crystall': this.add.text(0, 0, '-').setOrigin(0, 0.5),
            'battery_units': this.add.text(0, 0, '-').setOrigin(0, 0.5),
            'steel': this.add.text(0, 0, '-').setOrigin(0, 0.5),
            'silicon': this.add.text(0, 0, '-').setOrigin(0, 0.5),
            'titan': this.add.text(0, 0, '-').setOrigin(0, 0.5),
        }
        this.morionText = this.add.text(0, 0, '-').setOrigin(0, 0.5);

        // Кнопки Интерфейса
        this.buttons = [
            // Левый интерфейс
            new ToggleFullScreenButton(this, 0, 0, [], 'button_up', 0, 0),
            new ToggleAudioButton(this, 0, 0, [], 'button_up', 0, 0),
            new ToggleMusicButton(this, 0, 0, [], 'button_up', 0, 0),
            new Button(this, 0, 0, [], 'button_up', 0, 0),

            new OpenInfoTableButton(this, 0, 0, [], 'button_up', 0, 0, '', 'social', 'left'),
            new OpenInfoTableButton(this, 0, 0, [], 'button_up', 0, 0, '', 'social', 'center'),
            new OpenInfoTableButton(this, 0, 0, [], 'button_up', 0, 0, '', 'social', 'right'),

            new OpenInfoTableButton(this, 0, 0, [], 'button_up', 0, 0, '', 'containers', 'left'),
            new OpenInfoTableButton(this, 0, 0, [], 'button_up', 0, 0, '', 'containers', 'left'),
            new OpenInfoTableButton(this, 0, 0, [], 'button_up', 0, 0, '', 'containers', 'left'),
            new OpenInfoTableButton(this, 0, 0, [], 'button_up', 0, 0, '', 'containers', 'left'),
            new OpenInfoTableButton(this, 0, 0, [], 'button_up', 0, 0, '', 'containers', 'left'),
            new OpenInfoTableButton(this, 0, 0, [], 'button_up', 0, 0, '', 'containers', 'center'),

            UI.btnJournal = new ButtonJournal(this, 0, 0, [], 'button_up', 0, 0, '', 'Journal'),

            new NoActionButton(this, 0, 0, [], 'button_up', 0, 0, ''), // Daily bonus
            new NoActionButton(this, 0, 0, [], 'button_up', 0, 0, ''), // Friends
            new NoActionButton(this, 0, 0, [], 'button_up', 0, 0, ''), // Investment

            UI.buttonProduction = new OpenInfoTableButton(this, 0, 0, [], 'button_down', 0, 0, '', 'production', 'center'),
            UI.buttonStore = new OpenInfoTableButton(this, 0, 0, [], 'button_down', 0, 0, '', 'production', 'right'),
            new ChangeSceneButton(this, 0, 0, [], 'button_down', 0, 0, '', 'MainBase'),


            // Правый интерфейс
            new ChangeSceneButton(this, 0, 0, [], 'button_up', 0, 0, '', 'Rocky_Coast'),
            new ChangeSceneButton(this, 0, 0, [], 'button_up', 0, 0, '', 'East_Barrier'),
            new ChangeSceneButton(this, 0, 0, [], 'button_up', 0, 0, '', 'North_Barrier'),

            new NoActionButton(this, 0, 0, [], 'button_up', 0, 0, ''),
            new NoActionButton(this, 0, 0, [], 'button_up', 0, 0, ''),

            new NoActionButton(this, 0, 0, [], 'button_up', 0, 0, ''), // Rating
            new OpenInfoTableButton(this, 0, 0, [], 'button_up', 0, 0, '', 'shop', 'center'),

            new ButtonBattles(this, 0, 0, [], 'button_up', 0, 0, 'red', 'battles', 'center'), 

            new NoActionButton(this, 0, 0, [], 'button_up', 0, 0, '', 'tasks', 'left'),
            new NoActionButton(this, 0, 0, [], 'button_up', 0, 0, '', 'tasks', 'center'),
            new NoActionButton(this, 0, 0, [], 'button_up', 0, 0, '', 'tasks', 'right'),

            new NoActionButton(this, 0, 0, [], 'button_down', 0, 0, ''),
            new NoActionButton(this, 0, 0, [], 'button_down', 0, 0, ''), // Underworld
            new ChangeSceneButton(this, 0, 0, [], 'button_down', 0, 0, '', 'World'),
        ];

        this.intrf = this.add.container(0, 0, this.buttons);
    }

    setContentPosition() {
        let ww = window.innerWidth;
        let wh = window.innerHeight;
        // Размеры и позиция интерфейса
        let Iw = wh / this.UILeft.height * this.UILeft.width; //Ширина картинки интерфейса

        this.UILeft.setDisplaySize(Iw, wh);
        this.UIRight.setDisplaySize(Iw, wh);

        let IRpos = ww - wh / this.UILeft.height * this.UILeft.width;
        this.UIRight.setPosition(IRpos, 0);

        // Уровень игрока
        this.playerLvl.setStyle(TEXT_STYLE_BLUE).setFontSize(wh * 0.016);
        this.playerLvl.setPosition(wh * 0.086, wh * 0.034);

        this.avatarMask.setDisplaySize(wh * 0.123148, wh * 0.123148).setPosition(wh * 0.0287, wh * 0.04074);
        this.playerAvatar.setDisplaySize(wh * 0.123148, wh * 0.123148).setPosition(wh * 0.0287 + this.avatarMask.displayWidth * 0.47, wh * 0.04074);

        // РЕСУРСЫ
        this.setResStyle(wh, IRpos);

        // Кнопки
        for (let s in this.soundIcon) {
            this.soundIcon[s].setDisplaySize(wh * 0.022, wh * 0.022);
            this.soundIcon[s].setPosition(wh * 0.185, wh * 0.0295);
        }
        for (let m in this.musicIcon) {
            this.musicIcon[m].setDisplaySize(wh * 0.02, wh * 0.02);
            this.musicIcon[m].setPosition(wh * 0.23, wh * 0.031);
        }


        // Позиции кнопок
        let buttons_pos = [
            // Настройки
            { x: Iw * 0.0843, y: window.innerHeight * 0.0401 },
            { x: Iw * 0.10985, y: window.innerHeight * 0.0401 },
            { x: Iw * 0.1354, y: window.innerHeight * 0.0401 },
            { x: Iw * 0.16095, y: window.innerHeight * 0.0401 },
            // Кнопки
            { x: Iw * 0.0966, y: window.innerHeight * 0.0725 },
            { x: Iw * 0.12215, y: window.innerHeight * 0.0725 },
            { x: Iw * 0.1477, y: window.innerHeight * 0.0725 },
            // Ресурсы
            { x: Iw * 0.1935, y: window.innerHeight * 0.046 },
            { x: Iw * 0.262, y: window.innerHeight * 0.046 },
            { x: Iw * 0.3307, y: window.innerHeight * 0.046 },
            { x: Iw * 0.3997, y: window.innerHeight * 0.046 },
            { x: Iw * 0.4683, y: window.innerHeight * 0.046 },
            { x: Iw * 0.537, y: window.innerHeight * 0.046 },
            // Журнал
            { x: Iw * 0.0815, y: window.innerHeight * 0.131 },
            // Задания слева
            { x: Iw * 0.0235, y: window.innerHeight * 0.215 },
            { x: Iw * 0.04, y: window.innerHeight * 0.2647 },
            { x: Iw * 0.0235, y: window.innerHeight * 0.3144 },
            // Кнопки внизу слева
            { x: Iw * 0.0256, y: window.innerHeight * 0.858 },
            { x: Iw * 0.0825, y: window.innerHeight * 0.957 },
            { x: Iw * 0.0385, y: window.innerHeight * 0.9355 },

            // Крепости
            { x: IRpos + Iw - Iw * 0.0809, y: window.innerHeight * 0.0402 },
            { x: IRpos + Iw - Iw * 0.10645, y: window.innerHeight * 0.0402 },
            { x: IRpos + Iw - Iw * 0.132, y: window.innerHeight * 0.0402 },
            // Кнопки
            { x: IRpos + Iw - Iw * 0.0935, y: window.innerHeight * 0.0725 },
            { x: IRpos + Iw - Iw * 0.11905, y: window.innerHeight * 0.0725 },
            // Морион
            { x: IRpos + Iw - Iw * 0.1665, y: window.innerHeight * 0.046 },
            { x: IRpos + Iw - Iw * 0.2352, y: window.innerHeight * 0.046 },
            // Магазин снаряжения
            { x: IRpos + Iw - Iw * 0.079, y: window.innerHeight * 0.131 },
            // Задания справа
            { x: IRpos + Iw - Iw * 0.0225, y: window.innerHeight * 0.215 },
            { x: IRpos + Iw - Iw * 0.039, y: window.innerHeight * 0.2647 },
            { x: IRpos + Iw - Iw * 0.0225, y: window.innerHeight * 0.3144 },
            // Кнопки внизу справа
            { x: IRpos + Iw - Iw * 0.027, y: window.innerHeight * 0.858 },
            { x: IRpos + Iw - Iw * 0.0835, y: window.innerHeight * 0.957 },
            { x: IRpos + Iw - Iw * 0.04, y: window.innerHeight * 0.9355 },
        ]
        // Размеры кнопок
        let buttons_size = [
            // Настройки
            { width: Iw * 0.0182, height: window.innerHeight * 0.0372 },
            { width: Iw * 0.0182, height: window.innerHeight * 0.0372 },
            { width: Iw * 0.0182, height: window.innerHeight * 0.0372 },
            { width: Iw * 0.0182, height: window.innerHeight * 0.0372 },
            // Кнопки
            { width: Iw * 0.021, height: window.innerHeight * 0.043 },
            { width: Iw * 0.021, height: window.innerHeight * 0.043 },
            { width: Iw * 0.021, height: window.innerHeight * 0.043 },
            // Ресурсы
            { width: Iw * 0.029, height: window.innerHeight * 0.058 },
            { width: Iw * 0.029, height: window.innerHeight * 0.058 },
            { width: Iw * 0.029, height: window.innerHeight * 0.058 },
            { width: Iw * 0.029, height: window.innerHeight * 0.058 },
            { width: Iw * 0.029, height: window.innerHeight * 0.058 },
            { width: Iw * 0.029, height: window.innerHeight * 0.058 },
            // Журнал
            { width: Iw * 0.029, height: window.innerHeight * 0.058 },
            // Задания слева
            { width: Iw * 0.029, height: window.innerHeight * 0.058 },
            { width: Iw * 0.029, height: window.innerHeight * 0.058 },
            { width: Iw * 0.029, height: window.innerHeight * 0.058 },
            // Кнопки внизу слева
            { width: Iw * 0.037, height: window.innerHeight * 0.065 },
            { width: Iw * 0.037, height: window.innerHeight * 0.065 },
            { width: Iw * 0.053, height: window.innerHeight * 0.093 },

            // Крепости
            { width: Iw * 0.0182, height: window.innerHeight * 0.0372 },
            { width: Iw * 0.0182, height: window.innerHeight * 0.0372 },
            { width: Iw * 0.0182, height: window.innerHeight * 0.0372 },
            // Кнопки
            { width: Iw * 0.021, height: window.innerHeight * 0.043 },
            { width: Iw * 0.021, height: window.innerHeight * 0.043 },
            // Валюта
            { width: Iw * 0.029, height: window.innerHeight * 0.058 },
            { width: Iw * 0.029, height: window.innerHeight * 0.058 },
            // Магазин снаряжения
            { width: Iw * 0.029, height: window.innerHeight * 0.058 },
            // Задания справа
            { width: Iw * 0.029, height: window.innerHeight * 0.058 },
            { width: Iw * 0.029, height: window.innerHeight * 0.058 },
            { width: Iw * 0.029, height: window.innerHeight * 0.058 },
            // Кнопки внизу справа
            { width: Iw * 0.037, height: window.innerHeight * 0.065 },
            { width: Iw * 0.037, height: window.innerHeight * 0.065 },
            { width: Iw * 0.053, height: window.innerHeight * 0.093 },
        ]

        // Обновляем размер и позиции кнопок
        this.buttons.forEach((button, index) => {
            button.resize(buttons_size[index].width, buttons_size[index].height)
            button.setPosition(buttons_pos[index].x, buttons_pos[index].y)

            button.obj.flipX = (index > 30)
        });
    }

    setResStyle(h, UIRightPos) {
        const fontSize = h * 0.015;
        let dist = h * 0.375;

        for (let key in this.itemsText) {
            this.itemsText[key].setStyle(TEXT_STYLE_ORANGE).setFontSize(fontSize);
            this.itemsText[key].setPosition(dist, h * 0.046);
            dist += h * 0.122;
        }
        dist = UIRightPos + h * 1.39;

        this.morionText.setStyle(TEXT_STYLE_ORANGE).setFontSize(fontSize);
        this.morionText.setPosition(dist, h * 0.046);
    }

    async updateUI(updateAll = false) {
        if (updateAll) {
            const data = await apiClient.UI.getData();

            if (this.game.registry.get('new_player') == undefined) {
                this.game.registry.set('new_player', data['new_player']);
                this.game.registry.set('greeting_text', data['greeting_text']);
            }

            if (data['store_available']) {
                UI.buttonStore.makeInteractive();
                UI.buttonProduction.makeInteractive();
            } else {
                UI.buttonStore.disable();
                UI.buttonProduction.disable();
            }
            
            this.game.registry.set('player_level', data['player_level']);
            this.game.registry.set('items', data['items']);
        }

        const playerLevel = this.game.registry.get('player_level');
        this.playerLvl.setText(playerLevel);

        const items = this.game.registry.get('items');
        this.setItemsText(items);
    }

    setItemsText(data) {
        for (let key in this.itemsText) {
            this.itemsText[key].setText(data[key]);
        }
        this.morionText.setText(data['morion']);
    }
}


export function updateUI(scene, updateAll) {
    const UI = scene.scene.get('UI');
    UI.updateUI(updateAll);
}

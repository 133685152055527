import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN, TEXT_STYLE_ORANGE } from "../../../../../../utils/textStyles";
import Button from "../../../../../main/buttons/common/Button";


export default class ProcessCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, width, height, processKey, data) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.width = width;
        this.height = height;

        this.processKey = processKey;
        this.processData = data;
        this.create(width, height);
    }

    create(w, h) {
        this.add(this.cell = this.scene.add.image(0, 0, 'UI', 'factory_cell.png').setDisplaySize(w, h));
        this.add(this.button = new Button(this.scene, w * 0.468, 0, [], 'table_button', h * 0.54, h * 0.54, 'green'));

        this.add(this.name = this.scene.add.text(w * -0.34, h * -0.22, this.processData['title'] ?? 'Название процесса')
            .setOrigin(0, 0.5).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.22));

        this.add(this.name = this.scene.add.text(w * -0.34, h * 0.22, 'Выполнено')
            .setOrigin(0, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.22));

        this.addInfo(w, h);
    }

    addInfo(w, h) {
        let texture = this.processKey; // По умолчанию используем название процесса (для производства)

        this.add(this.icon = this.scene.add.sprite(w * -0.435, 0, 'items_icons', `${texture}.png`)
            .setDisplaySize(h * 0.55, h * 0.55));

        this.add(this.amountText = this.scene.add.text(w * 0.39, h * -0.22, this.processData['item_amount'] ?? '1')
            .setOrigin(1, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.22));
    }
}
export default class BootScene extends Phaser.Scene {
    constructor() {
        super('BootScene');
    }

    init() { }

    preload() {
        this.load.setPath('preloader/images/');

        this.load.image('load_bg_1', 'main/1.jpg');
        this.load.image('load_bg_2', 'main/2.jpg');
        this.load.image('load_bg_3', 'main/3.jpg');
        this.load.image('load_bg_world', 'scenes/world.jpg');
        this.load.image('load_bg_fight', 'battle/1.jpg');

        // Установка пользовательского курсора
        this.input.setDefaultCursor('url(assets/UI/ui/cursors/default.png), pointer');
    }

    create() {
        this.scene.start('UI');
    }
}
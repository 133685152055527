const containerData = {
    "container_batteries_1": [
        { "name": "battery_units", "amount": '2-5' },
        { "name": "battery_air", "amount": '1-3' },
        { "name": "battery_tech", "amount": 1 },
    ],
    "container_batteries_2": [
        { "name": "battery_units", "amount": '3-7' },
        { "name": "battery_air", "amount": '1-3' },
        { "name": "battery_tech", "amount": 1 },
        { "name": "battery_robots", "amount": 1 },
    ],
    "container_batteries_3": [
        { "name": "battery_units", "amount": '5-10' },
        { "name": "battery_air", "amount": '1-5' },
        { "name": "battery_tech", "amount": '1-2' },
        { "name": "battery_robots", "amount": 1 },
        { "name": "battery_military", "amount": 1 },
    ],

    "container_crystalls_1": [
        { "name": "crystall", "amount": '5-10' },
        { "name": "blue_crystall", "amount": '3-5' },
        { "name": "red_crystall", "amount": '1-3' },
    ],
    "container_crystalls_2": [
        { "name": "crystall", "amount": '10-20' },
        { "name": "blue_crystall", "amount": '5-10' },
        { "name": "red_crystall", "amount": '3-5' },
        { "name": "yellow_crystall", "amount": '1-3' },
    ],
    "container_crystalls_3": [
        { "name": "crystall", "amount": '15-30' },
        { "name": "blue_crystall", "amount": '7-15' },
        { "name": "red_crystall", "amount": '3-7' },
        { "name": "yellow_crystall", "amount": '2-5' },
        { "name": "purple_crystall", "amount": 1 },
    ],

    "container_devices_1": [
        { "name": "crystall", "amount": '5-10' },
        { "name": "blue_crystall", "amount": '3-5' },
        { "name": "red_crystall", "amount": '1-3' },
    ],
    "container_devices_2": [
        { "name": "crystall", "amount": '10-20' },
        { "name": "blue_crystall", "amount": '5-10' },
        { "name": "red_crystall", "amount": '3-5' },
        { "name": "yellow_crystall", "amount": '1-3' },
    ],
    "container_devices_3": [
        { "name": "crystall", "amount": '15-30' },
        { "name": "blue_crystall", "amount": '7-15' },
        { "name": "red_crystall", "amount": '3-7' },
        { "name": "yellow_crystall", "amount": '2-5' },
        { "name": "purple_crystall", "amount": 1 },
    ],

    "container_genetic_1": [
        { "name": "crystall", "amount": '5-10' },
        { "name": "blue_crystall", "amount": '3-5' },
        { "name": "red_crystall", "amount": '1-3' },
    ],
    "container_genetic_2": [
        { "name": "crystall", "amount": '10-20' },
        { "name": "blue_crystall", "amount": '5-10' },
        { "name": "red_crystall", "amount": '3-5' },
        { "name": "yellow_crystall", "amount": '1-3' },
    ],
    "container_genetic_3": [
        { "name": "crystall", "amount": '15-30' },
        { "name": "blue_crystall", "amount": '7-15' },
        { "name": "red_crystall", "amount": '3-7' },
        { "name": "yellow_crystall", "amount": '2-5' },
        { "name": "purple_crystall", "amount": 1 },
    ],

    "container_iron_1": [
        { "name": "steel", "amount": '5-10' },
        { "name": "iron_tile", "amount": '3-5' },
        { "name": "iron_pipe", "amount": '1-3' },
    ],
    "container_iron_2": [
        { "name": "steel", "amount": '10-20' },
        { "name": "iron_tile", "amount": '5-10' },
        { "name": "iron_pipe", "amount": '3-5' },
        { "name": "iron_bar", "amount": '1-3' },
    ],
    "container_iron_3": [
        { "name": "steel", "amount": '15-30' },
        { "name": "iron_tile", "amount": '7-15' },
        { "name": "iron_pipe", "amount": '3-7' },
        { "name": "iron_bar", "amount": '2-5' },
        { "name": "door", "amount": 1 },
        { "name": "iron_armor", "amount": 1 },
    ],

    "container_keys_1": [
        { "name": "crystall", "amount": '5-10' },
        { "name": "blue_crystall", "amount": '3-5' },
        { "name": "red_crystall", "amount": '1-3' },
    ],
    "container_keys_2": [
        { "name": "crystall", "amount": '10-20' },
        { "name": "blue_crystall", "amount": '5-10' },
        { "name": "red_crystall", "amount": '3-5' },
        { "name": "yellow_crystall", "amount": '1-3' },
    ],
    "container_keys_3": [
        { "name": "crystall", "amount": '15-30' },
        { "name": "blue_crystall", "amount": '7-15' },
        { "name": "red_crystall", "amount": '3-7' },
        { "name": "yellow_crystall", "amount": '2-5' },
        { "name": "purple_crystall", "amount": 1 },
    ],

    "container_modules_1": [
        { "name": "crystall", "amount": '5-10' },
        { "name": "blue_crystall", "amount": '3-5' },
        { "name": "red_crystall", "amount": '1-3' },
    ],
    "container_modules_2": [
        { "name": "crystall", "amount": '10-20' },
        { "name": "blue_crystall", "amount": '5-10' },
        { "name": "red_crystall", "amount": '3-5' },
        { "name": "yellow_crystall", "amount": '1-3' },
    ],
    "container_modules_3": [
        { "name": "crystall", "amount": '15-30' },
        { "name": "blue_crystall", "amount": '7-15' },
        { "name": "red_crystall", "amount": '3-7' },
        { "name": "yellow_crystall", "amount": '2-5' },
        { "name": "purple_crystall", "amount": 1 },
    ],

    "container_morion_1": [
        { "name": "morion", "amount": 30 },
    ],
    "container_morion_2": [
        { "name": "morion", "amount": 100 },
    ],
    "container_morion_3": [
        { "name": "morion", "amount": 250 },
    ],
    "container_morion_4": [
        { "name": "morion", "amount": 500 },
    ],
    "container_morion_5": [
        { "name": "morion", "amount": 750 },
    ],
    "container_morion_6": [
        { "name": "morion", "amount": 1000 },
    ],

    "container_research_1": [
        { "name": "crystall", "amount": '5-10' },
        { "name": "blue_crystall", "amount": '3-5' },
        { "name": "red_crystall", "amount": '1-3' },
    ],
    "container_research_2": [
        { "name": "crystall", "amount": '10-20' },
        { "name": "blue_crystall", "amount": '5-10' },
        { "name": "red_crystall", "amount": '3-5' },
        { "name": "yellow_crystall", "amount": '1-3' },
    ],
    "container_research_3": [
        { "name": "crystall", "amount": '15-30' },
        { "name": "blue_crystall", "amount": '7-15' },
        { "name": "red_crystall", "amount": '3-7' },
        { "name": "yellow_crystall", "amount": '2-5' },
        { "name": "purple_crystall", "amount": 1 },
    ],

    "container_robots_1": [
        { "name": "chip", "amount": '5-10' },
        { "name": "processor", "amount": '3-5' },
        { "name": "ram", "amount": '1-3' },
    ],
    "container_robots_2": [
        { "name": "chip", "amount": '10-20' },
        { "name": "processor", "amount": '5-10' },
        { "name": "ram", "amount": '3-5' },
        { "name": "robo_eye", "amount": '1-3' },
    ],
    "container_robots_3": [
        { "name": "chip", "amount": '15-30' },
        { "name": "processor", "amount": '7-15' },
        { "name": "ram", "amount": '3-7' },
        { "name": "robo_eye", "amount": '2-5' },
        { "name": "robo_brain", "amount": 1 },
    ],

    "container_silicon_1": [
        { "name": "silicon", "amount": '5-10' },
        { "name": "silicon_plate", "amount": '3-5' },
        { "name": "glass", "amount": '1-3' },
    ],
    "container_silicon_2": [
        { "name": "silicon", "amount": '10-20' },
        { "name": "silicon_plate", "amount": '5-10' },
        { "name": "glass", "amount": '3-5' },
        { "name": "silicon_glass", "amount": '1-3' },
    ],
    "container_silicon_3": [
        { "name": "silicon", "amount": '15-30' },
        { "name": "silicon_plate", "amount": '7-15' },
        { "name": "glass", "amount": '3-7' },
        { "name": "silicon_glass", "amount": '2-5' },
        { "name": "lense", "amount": 1 },
        { "name": "prism", "amount": 1 },
    ],

    "container_titan_1": [
        { "name": "titan", "amount": '5-10' },
        { "name": "titan_tile", "amount": '3-5' },
        { "name": "titan_armor", "amount": '1-3' },
    ],
    "container_titan_2": [
        { "name": "titan", "amount": '10-20' },
        { "name": "titan_tile", "amount": '5-10' },
        { "name": "titan_armor", "amount": '3-5' },
        { "name": "cutter", "amount": '1-3' },
    ],
    "container_titan_3": [
        { "name": "titan", "amount": '15-30' },
        { "name": "titan_tile", "amount": '7-15' },
        { "name": "red_crystall", "amount": '3-7' },
        { "name": "cutter", "amount": '2-5' },
        { "name": "shaft", "amount": 1 },
        { "name": "turbine", "amount": 1 },
    ],
};

export default containerData;
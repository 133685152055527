import TimeManager from "../../../../../../managers/TimeManager";
import InfoTables from "../../../../../../scenes/ui/InfoTables";
import apiClient from "../../../../../../services/apiClient";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN } from "../../../../../../utils/textStyles";
import ActionButton from "../../../../../main/buttons/common/ActionButton";
import UnitIcon from "../../../../../main/icons/UnitIcon";

export default class HealingCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th, available) {
        super(scene, x, y, children, tw, th);
        scene.add.existing(this);

        this.tw = tw;
        this.th = th;

        this.available = available || false; // Доступна ли ячейка
        this.isFree = this.available;

        this.create(tw, th);
    }

    create(w, h) {
        this.add(this.timer = this.scene.add.text(w * 0.077, h * 0.035, '-')
            .setOrigin(0, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.022));

        this.add(this.morionText = this.scene.add.text(w * 0.09, h * -0.019, '-')
            .setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.02));

        this.add(this.finishButton = new ActionButton(this.scene, w * 0.174, h * -0.019, [], 'button_up', h * 0.051, h * 0.058, 'green'));
        this.finishButton.disable();

        // Если ячейка недоступна, добавляем замочек
        if (!this.available) {
            this.addLock(w, h);
        }
    }

    async addUnit(icon) {
        this.add(this.icon = new UnitIcon(this.scene, 0, 0, [], 'button', this.th * 0.18, this.th * 0.18));
        this.icon.setUnitData(icon.unitData, icon.index);

        this.icon.disable();
        this.icon.deselect();

        this.isFree = false;
    }

    addLock(w, h) {
        this.isFree == false;
        this.add(this.lock = this.scene.add.sprite(0, 0, 'UI', 'lock.png').setOrigin(0.7, 0.5).setDisplaySize(h * 0.06, h * 0.06));
    }

    clear() {
        this.isFree = this.available;
        
        if (this.icon) {
            TimeManager.removeTimer(this.icon.unitData['id']);
            this.icon.destroy();
        }

        this.timer.setText('-');
        this.timer.setStyle(TEXT_STYLE_BLUE);

        this.morionText.setText('-');
        this.morionText.setStyle(TEXT_STYLE_BLUE);

        this.finishButton.disable();
    }

    async startHealing(data) {
        this.finishButton.makeInteractive();
        this.timer.setStyle(TEXT_STYLE_GREEN);
        const unitId = this.icon.unitData['id'];

        TimeManager.createTimer(this.scene, unitId, data['duration'],
            this.finishHealing.bind(this),
            (remainingTime) => {
                this.timer.setText(TimeManager.formatFullTime(remainingTime));
            }
        );
    }

    async finishHealing() {
        const data = await apiClient.processes.finish({ category: 'healing', name: 'unit_' + this.icon.unitData['id'] });
        console.log(data);

        this.clear();
        InfoTables.showWarningText('Лечение завершено, ваш юнит полностью здоров');
    }

}
import BuildingTableContainer from './common/BuildingTableContainer.js';
import FactoryProductionTable from '../tables/production/FactoryProductionTable.js';
import SiliconFactoryTable from '../tables/production/SiliconFactoryTable.js';


export default class SiliconFactoryTableContainer extends BuildingTableContainer {

    addInfoTables() {
        this.tableLeft = null;
        this.tableCenter = new FactoryProductionTable(this.scene, 0, 0, [], this.w, this.h, 'silicon_factory_production');
        this.tableRight = new SiliconFactoryTable(this.scene, 0, 0, [], this.w, this.h, 'silicon_factory');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}

import InteractiveObject from "../../../../../main/InteractiveObject";


export class CharacterSkills extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th) {
        super(scene, x, y, children);
        scene.add.existing(this);

        const w = tw * 0.04;
        const h = w / 1.16;

        this.add(new SkillCell(scene, 0.122, 0.226, [], 'UI', w, h));
        this.add(new SkillCell(scene, 0.160, 0.226, [], 'UI', w, h));
        this.add(new SkillCell(scene, 0.198, 0.226, [], 'UI', w, h));
        this.add(new SkillCell(scene, 0.236, 0.226, [], 'UI', w, h));
        this.add(new SkillCell(scene, 0.274, 0.226, [], 'UI', w, h));
        this.add(new SkillCell(scene, 0.312, 0.226, [], 'UI', w, h));

        this.each(skill => skill.setRotation(Math.PI / 2));
        this.each(skill => skill.setPosition(tw * skill.x, th * skill.y));
    }
}

export class SkillCell extends InteractiveObject {
    constructor(scene, x, y, children, texture, width, height, available) {
        super(scene, x, y, children, texture, width, height);

        this.available = available || false;
        this.active = false;
    }

    create() {
        super.create();
        this.obj.setFrame('skill_cell.png')
            .setDisplaySize(this.width, this.height)
            .setOrigin(0.5, 0.5);

        this.disable();
        (this.available) && this.makeInteractive();
    }

    pointerOver() {
        super.pointerOver();
        this.obj.setTint(0xbdbdbd);
    }

    pointerOut() {
        super.pointerOut()
        this.obj.clearTint();
    }

    click() {
        super.click();
        this.active = true;
        this.destroy();
    }
}
import TimeManager from '../../../managers/TimeManager.js';
import InfoTables from '../../../scenes/ui/InfoTables.js';
import { updateUI } from '../../../scenes/ui/UI.js';
import apiClient from '../../../services/apiClient.js';
import Button from './common/Button.js';

export default class ExploreWorldAreaButton extends Button {
    constructor(scene, x, y, children, texture, width, height, color) {
        super(scene, x, y, children, texture, width, height, color);
    }

    async click() {
        super.click();

        const worldArea = this.scene.game.registry.get('selected_world_area');
        
        if (worldArea.getProcessFromStack(worldArea.targetScene)) {
            this.openConfirmationTable();
            return;
        };

        const data = await apiClient.processes.start({
            category: 'exploration', 
            name: worldArea.targetScene, 
        });
        console.log(data);

        if (data['msg'] == '200') {
            updateUI(this.scene);
            worldArea.startExploration(data['data']);
        } else {
            InfoTables.showWarningText(data['msg']);
        }
    }

    openConfirmationTable() {
        const worldArea = this.scene.game.registry.get('selected_world_area');
        const morionAmount = Math.ceil(TimeManager.getRemaining(worldArea.targetScene) / 60000);

        InfoTables.openConfirmationTable({
            title: 'Разведка',
            text: `Завершить разведку за ${morionAmount} мориона?`,
            button: 'Завершить'
        }, this.finishByMorion.bind(this));
    }

    async finishByMorion() {
        const worldArea = this.scene.game.registry.get('selected_world_area');

        const data = await apiClient.processes.finishByMorion({
            category: 'exploration',
            name: worldArea.targetScene,
        });

        if (data['status'] != 'success') {
            return;
        }

        InfoTables.showWarningText(data['message']);
        this.scene.game.registry.set('items', data['items']);
        updateUI(this.scene);

        if (data['message'] == 'Не достаточно мориона') {
            return;
        }

        const processIcon = worldArea.getProcessFromStack(worldArea.targetScene);
        worldArea.removeProcessFromStack(processIcon);
        worldArea.explore();
    }
}
import BuildingTableContainer from './common/BuildingTableContainer.js';
import RobotTable from '../tables/units/robots/RobotTable.js';
import BuildingProductionTable from '../tables/production/BuildingProductionTable.js';


export default class RoboCenterTableContainer extends BuildingTableContainer {

    addInfoTables() {
        this.tableLeft = null;
        this.tableCenter = new RobotTable(this.scene, 0, 0, [], this.w, this.h, 'robot');
        this.tableRight = new BuildingProductionTable(this.scene, 0, 0, [], this.w, this.h, 'robo_center');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}

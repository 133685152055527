import FactoryTable from "./FactoryTable.js";


export default class IronFoundryTable extends FactoryTable {

    create() {
        this.item = 'steel';
        this.ore = 'iron_ore';
        this.storeItems = ['steel', 'iron_tile', 'iron_pipe', 'iron_bar', 'door', 'iron_armor'];
        this.targetScene = 'Iron_Mine';
        super.create();
    }
}
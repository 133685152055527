import { TEXT_STYLE_BLUE } from "../../../utils/textStyles";

export default class ObjectLink extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, name, objectData = {}) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.name = name;
        this.objectData = objectData;
        this.create(window.screen.width);
    }

    create(w) {
        this.add(this.link = this.scene.add.sprite(0, 0, 'UI', `${this.type}_link.png`).setOrigin(0, 1).setDisplaySize(w * 0.24, w * 0.084));
        const lw = this.link.displayWidth;
        const lh = this.link.displayHeight;
        this.add(this.name = this.scene.add.text(lw * 0.6, lh * -0.77, this.name).setOrigin(0.5, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(w * 0.011));

        this.hide();
    }

    place(x, y) {
        this.setPosition(x, y);
    }

    show() {
        this.setAlpha(1);
    }

    hide() {
        this.setAlpha(0);
    }

    setLinkColor(linkColor, textColor) {
        this.link.setTintFill(linkColor);
        this.name.setColor(textColor);
    }

    flip() {  
        this.link.setOrigin(1, 1);
        this.link.flipX = true;
        this.name.x = this.link.displayWidth * -0.6;
    }
};
import InfoTables from "../../../../../../scenes/ui/InfoTables";
import { updateUI } from "../../../../../../scenes/ui/UI";
import apiClient from "../../../../../../services/apiClient";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN, TEXT_STYLE_ORANGE } from "../../../../../../utils/textStyles";
import ActionButton from "../../../../../main/buttons/common/ActionButton";
import UnitIcon from "../../../../../main/icons/UnitIcon";

export default class ArmyStatsSection extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th, unitData) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.width = tw * 0.65;
        this.height = th * 0.1;
        this.tw = tw;
        this.th = th;
        this.unitData = unitData;

        this.create(tw, th);
    }

    create(w, h) {
        const data = this.unitData;
        const y = h * -0.007;
        let x = w * -0.25;
        if (['commander', 'character'].includes(data['category'])) {
            x = w * -0.28;
        }

        this.add(this.unitIcon = new UnitIcon(this.scene, x, 0, [], 'button', h * 0.1, h * 0.1));
        this.unitIcon.setUnitData(data);

        const fontSize = h * 0.02;
        this.add(this.name = this.scene.add.text(x + h * 0.06, y, data['name']).setOrigin(0, 1).setStyle(TEXT_STYLE_ORANGE).setFontSize(fontSize));
        this.add(this.health = this.scene.add.text(x + h * 0.06, -y, data['health'] + '/' + data['max_health']).setOrigin(0, 0).setStyle(TEXT_STYLE_GREEN).setFontSize(fontSize));

        this.add(this.line = this.scene.add.line(0, 0, 0, 0, 0, 0, 0xb0f1ff).setAlpha(0.8));
        this.line.setTo(x + h * 0.06, 0, w * 0.3, 0);

        this.add(this.healAmount = this.scene.add.text(w * 0.18, y, '1').setOrigin(1, 1).setStyle(TEXT_STYLE_GREEN).setFontSize(fontSize));
        this.add(this.scene.add.text(w * 0.18, -y, '+100').setOrigin(1, 0).setStyle(TEXT_STYLE_BLUE).setFontSize(fontSize));
        this.add(this.scene.add.sprite(w * 0.145, -y, 'parameters_icons', 'health.png').setOrigin(1, 0.1).setDisplaySize(h * 0.026, h * 0.026));

        let item = 'medkit';
        switch (data['category']) {
            case 'character':
                switch (data['name']) {
                    case 'Chlapidol':
                        item = 'mutagen_heal';
                        break;
                    case 'Shtryzik':
                        item = 'battery_heal';
                        break;
                }
                break;
            case 'mutant':
                item = 'mutagen_heal';
                break;
            case 'irradiated':
                item = 'battery_heal';
                break;
            case 'robot':
                item = 'battery_robots';
                break;
        }

        this.add(this.frameHeal = this.scene.add.sprite(w * 0.2, 0, 'UI', 'hex.png').setDisplaySize(h * 0.05, h * 0.05));
        this.add(this.buttonHeal = new ActionButton(this.scene, w * 0.2, 0, [], 'table_button', h * 0.05, h * 0.05, 'green', this.heal.bind(this)));
        this.add(this.iconHeal = this.scene.add.sprite(w * 0.2, 0, 'items_icons', `${item}.png`).setDisplaySize(h * 0.03, h * 0.03));

        this.add(this.morionAmount = this.scene.add.text(w * 0.28, y, '25').setOrigin(1, 1).setStyle(TEXT_STYLE_GREEN).setFontSize(fontSize));
        this.add(this.scene.add.text(w * 0.28, -y, '+100').setOrigin(1, 0).setStyle(TEXT_STYLE_BLUE).setFontSize(fontSize));
        this.add(this.scene.add.sprite(w * 0.245, -y, 'parameters_icons', 'health.png').setOrigin(1, 0.1).setDisplaySize(h * 0.026, h * 0.026));

        this.add(this.frameMorion = this.scene.add.sprite(w * 0.3, 0, 'UI', 'hex.png').setDisplaySize(h * 0.05, h * 0.05));
        this.add(this.buttonMorion = new ActionButton(this.scene, w * 0.3, 0, [], 'table_button', h * 0.05, h * 0.05, 'green', () => this.heal(true)));
        this.add(this.iconMorion = this.scene.add.sprite(w * 0.3, 0, 'items_icons', 'morion.png').setDisplaySize(h * 0.03, h * 0.03));
    }

    updateInfo(data) {
        this.unitIcon.setUnitData(data);
        this.health.setText(data['health'] + '/' + data['max_health']);
    }

    async heal(morion = false) {
        const data = await apiClient.units.useMedkit({ id: this.unitData['id'], morion: morion});
        console.log(data);

        if (data['status'] != 'success') {
            return;
        }

        InfoTables.showWarningText(data['message']);
        this.updateInfo(data['unit']);
        updateUI(this.scene);
    }
}
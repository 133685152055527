import loadAssets from '../../../../../managers/AssetManager.js';
import InfoTables from '../../../../../scenes/ui/InfoTables.js';
import apiClient from '../../../../../services/apiClient.js';
import Button from '../../../../main/buttons/common/Button.js';
import UnitCategoryButton from '../../../../main/buttons/army/UnitCategoryButton.js';
import UnitIcon from '../../../../main/icons/UnitIcon.js';
import InfoTableMainScroll from '../../../common/scroll/InfoTableMainScroll.js';
import SingleInfoTable from '../common/SingleInfoTable.js';
import ArmyCharacterContainer from './ArmyCharacterContainer.js';
import ArmyUnitsContainer from './ArmyUnitsContainer.js';
import StartBattleButton from '../../../../main/buttons/army/StartBattleButton.js';
import { TEXT_STYLE_BLUE } from '../../../../../utils/textStyles.js';
import { divideUnitsByCategory } from '../../../../../utils/units.js';


export default class ArmyTable extends SingleInfoTable {

    async create() {
        super.create();

        const w = this.width;
        const h = this.height;

        // Добавляем скроллинг и объявляем колбэк добавления юнитов
        this.add(this.unitScroll = new InfoTableMainScroll(this.scene, 0, 0, [], w, h, (data) => {

            for (let index in data) {
                let unit = new UnitIcon(this.scene, 0, 0, [], 'button', h * 0.1, h * 0.1);
                unit.setUnitData(data[index], index);

                // Колбэк при клике на иконку юнита
                unit.setClickCallback(() => {
                    const category = unit.unitData['category'];
                    let container = this.mainArmyContainer;

                    if (category == 'commander') {
                        container = this.commanderContainer;

                    } else if (category == 'character') {
                        container = this.characterContainer;

                    } else if (this.extraArmyCategories.includes(category)) {
                        container = this.extraArmyContainer;
                    }

                    if (unit.unitData['name'] == 'Vovein') {
                        InfoTables.showWarningText('Этого персонажа пока нельзя брать в бой');
                    } else {
                        // Костыль, чтобы скопировать юнита в контейнер
                        this.selectUnitToArmy(unit, container);
                    }
                });

                this.unitScroll.addItem(unit);
            }

        }));

        this.addContent(w, h);
        this.addRequiredItems(w, h);
    }

    addRequiredItems(w, h) {
        this.add(this.unitBatteryIcon = this.scene.add.image(w * -0.05, h * -0.1, 'items_icons', 'battery_units.png')
            .setOrigin(1, 0.5).setDisplaySize(h * 0.035, h * 0.035))
        this.add(this.requiredItemsText = this.scene.add.text(w * -0.04, h * -0.1, '0')
            .setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.025));
    }

    selectUnitToArmy(unit, container) {
        const category = unit.unitData['category'];
        const unitArray = this.getData('units')[category];
        const id = unitArray.indexOf(unit.unitData);

        if (!container.enoughSpace()) {
            InfoTables.showWarningText('Нельзя выбрать больше юнитов');
            return;
        }

        let selectedUnit = new UnitIcon(this.scene, 0, 0, [], 'button', this.height * 0.095, this.height * 0.095);
        selectedUnit.setUnitData(unit.unitData, unit.index);

        selectedUnit.setClickCallback(() => {
            container.removeUnit(selectedUnit);

            if (category == 'character') {
                const array = this.getData('units')[this.getCategoryByCharacter(unit.unitData['name'])];
                this.extraArmyContainer.getAll().forEach(unit => array.push(unit.unitData));
                this.extraArmyContainer.clear();
            }
            unitArray.push(selectedUnit.unitData);
            this.categoryButtons[category].click();
        });

        if (['commander', 'character'].includes(category)) {
            container.addUnit(selectedUnit, category == 'character');
        } else {
            container.add(selectedUnit);
        }

        if (id != -1) unitArray.splice(id, 1);
        this.updateUnitScroll(unitArray);
        this.updateInfo();
    }

    async update(category) {
        super.update(category);
        this.clear();

        const data = await apiClient.units.getAll();
        console.log(data);

        const dividedUnits = divideUnitsByCategory(data['units']);
        this.setData('units', dividedUnits);

        this.categoryButtons['commander'].click();
        await this.loadData(dividedUnits);

        this.updateInfo();
    }

    async loadData(units) {
        const load = async (name) => {
            await loadAssets(this.scene, 'image', `${name}_icon_large`, `UI/icons/units/large/${name}.png`);
        }

        units['commander'].forEach(unit => load(unit['name']));
        units['character'].forEach(unit => { if (unit['name'] != 'Vovein') load(unit['name']) });

        await loadAssets(this.scene, 'image', `icon_frame_large`, `UI/icons/units/large/frame.png`);
    }

    addContent(w, h) {
        this.setData('army', {
            commander: 0,
            character: 0,
            main_troop: 0,
            extra_troop: 0,
        });

        this.mainArmyCategories = ['commander', 'soldier', 'merc', 'cyborg', 'alien'];
        this.extraArmyCategories = ['character', 'animal', 'mutant', 'irradiated', 'robot'];

        this.categoryButtons = {};
        let dist = w * -0.3095;
        const buttonH = h * 0.057;
        const buttonW = buttonH / 1.16;

        // Колбэк при нажатии на кнопку категории
        const clickCallback = (button, key) => {
            if (key.includes('equipment')) {
                InfoTables.showWarningText('Будет доступно в одном из обновлений');

            } else {
                const units = this.getData(`units`)[key];

                if (!units) {
                    InfoTables.showWarningText('Отряд пуст');
                    return;
                }

                for (let key in this.categoryButtons) {
                    this.categoryButtons[key].deselect();
                }
                button.select();
                this.updateUnitScroll(units);
                this.updateInfo();
            }
        }

        // Добавление кнопки в табличку
        const addButton = (x, key) => {
            let button = new UnitCategoryButton(this.scene, x, h * 0.272, [], 'button_up', buttonW, buttonH, '', () => clickCallback(button, key));
            if (key !== 'character' && !this.mainArmyCategories.includes(key)) {
                button.disable();
            }
            this.categoryButtons[key] = button;
            this.add(button);
        }

        this.mainArmyCategories.forEach(key => {
            addButton(dist, key);
            dist += w * 0.034;
        });

        dist = w * 0.3095;
        this.extraArmyCategories.forEach(key => {
            addButton(dist, key);
            dist -= w * 0.034;
        });

        // Кнопки снаряжения
        addButton(w * -0.058, 'main_army_equipment');
        addButton(w * 0.058, 'extra_army_equipment');

        // Кнопка В БОЙ
        this.add(this.startBattleButton = new StartBattleButton(this.scene, 0, h * 0.05, [], 'button_large', h * 0.145, h * 0.145, 'green', this));

        // Контейнеры армии для боя
        this.add(this.mainArmyContainer = new ArmyUnitsContainer(this.scene, w * -0.316, h * 0.144, [], w, h));
        this.add(this.extraArmyContainer = new ArmyUnitsContainer(this.scene, w * 0.316, h * 0.144, [], w, h, { flip: true }));

        this.add(this.commanderContainer = new ArmyCharacterContainer(this.scene, w * -0.313, h * -0.049, [], w, h));
        this.add(this.characterContainer = new ArmyCharacterContainer(this.scene, w * 0.313, h * -0.049, [], w, h));
    }

    updateInfo() {
        this.requiredItemsText.setText(this.getBattleResources());
        this.extraArmyCategories.forEach(key => {
            if (key !== 'character') {
                this.categoryButtons[key].disable();
            }
        });

        const character = this.characterContainer.unit;
        if (character) {
            const key = this.getCategoryByCharacter(character.unitData['name']);
            this.categoryButtons[key].makeInteractive();
        }
    }

    getCategoryByCharacter(character) {
        let key = '';

        switch (character) {
            case 'TechNastya':
                key = 'robot';
                break;
            case 'Chlapidol':
                key = 'mutant';
                break;
            case 'Shtryzik':
                key = 'irradiated';
                break;
            case 'Krychon':
                key = 'animal';
                break;
        }

        return key;
    }

    updateUnitScroll(data) {
        this.unitScroll.updateContent(data);
    }

    getBattleResources() {
        return this.commanderContainer.list.length * 2 +
            this.characterContainer.list.length * 2 +
            this.mainArmyContainer.list.length +
            this.extraArmyContainer.list.length;
    }

    getSelectedArmy() {
        return {
            commander: this.commanderContainer,
            character: this.characterContainer,
            main_troop: this.mainArmyContainer,
            extra_troop: this.extraArmyContainer,
        };
    }

    getArmyCount() {
        return this.commanderContainer.list.length +
            this.characterContainer.list.length +
            this.mainArmyContainer.list.length +
            this.extraArmyContainer.list.length;
    }

    getBattleData() {
        return {
            'resources': this.getBattleResources(),
            'count': this.getArmyCount(),
            'army': this.getSelectedArmy(),
            'data': this.parentContainer.getData('data'),
        };
    }

    clear() {
        this.mainArmyContainer.clear();
        this.extraArmyContainer.clear();
        this.characterContainer.clear();
        this.commanderContainer.clear();
    }
}




import JournalAnimalInfo from "../info/JournalAnimalInfo";
import JournalScrollAnimals from "../scroll/JournalScrollAnimals";
import JournalPage from "./JournalPage";

export default class JournalAnimalPage extends JournalPage {

    create() {
        super.create();

        this.add(this.info = new JournalAnimalInfo(this.scene, window.screen.width * -0.19, window.screen.height * -0.33, []));
        this.add(this.scroll = new JournalScrollAnimals(this.scene, 0, 0, []));
    }
}
import ObjectLink from "./ObjectLink";

export default class BarrierLink extends ObjectLink {
    constructor(scene, x, y, children, name, objectData) {
        super(scene, x, y, children, name, objectData);
    }

    create(w) {
        this.type = 'building';
        super.create(w);

        const lw = this.link.displayWidth;
        const lh = this.link.displayHeight;
        this.add(this.icon = this.scene.add.sprite(lw * 0.205, lh * -0.68, 'items_icons', this.objectData['type'] + '.png').setDisplaySize(lh * 0.2, lh * 0.2));
    }
};
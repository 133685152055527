import { TILE_HEIGHT, TILE_WIDTH } from "../../../../utils/const";
import { getDistance } from "../../../../utils/utils";
import Bullet from "../common/Bullet";
import BattleUnit from "./BattleUnit";

export default class BattleMutant extends BattleUnit {

    setUnitData(unitData, linkedIcon) {
        super.setUnitData(unitData, linkedIcon);
        const config = this.getData('config');

        config['close_fight_anims'] = ['close_fight'];
        config['attack_frames'] = [6];
        config['weakness'] = [];
    }

    shoot(x, y, dir, target) {
        const w = this.unitSprite.displayWidth;

        let positions = {
            'right': { x: w * 0.4, y: w * -0.2 },
            'left': { x: w * -0.4, y: w * -0.2 },
            'down-r': { x: w * 0.25, y: w * -0.1 },
            'down-l': { x: w * -0.25, y: w * -0.1 },
            'up-r': { x: w * 0.4, y: w * -0.35 },
            'up-l': { x: w * -0.4, y: w * -0.35 },
            'up': { x: w * 0.07, y: w * -0.5 },
            'down': { x: w * 0, y: w * 0.2 },
        };

        const bullet = new Bullet(this.scene, x + positions[dir].x, y + positions[dir].y, 'UI', 'spike.png');
        bullet.create(x, y, target.x + target.unitSprite.displayWidth / 2, target.y + target.unitSprite.displayWidth / 2);
        bullet.move(target.x, target.y);
    }

    checkForThreat() {
        const deadUnits = this.scene.data.get('deadUnits');

        // Текущая позиция юнита
        const x = Math.floor(this.x / TILE_WIDTH);
        const y = Math.floor(this.y / TILE_HEIGHT);

        if (!this.isRegenerationNeeded() || !deadUnits.length) {
            this.getData('unit')['range'] = this.initialRange;
            this.findTarget();
            return;
        }

        let curTarget, minDist = 1000;

        deadUnits.forEach(enemy => {
            const enemyX = Math.floor(enemy.x / TILE_WIDTH);
            const enemyY = Math.floor(enemy.y / TILE_HEIGHT);

            const dist = getDistance(x, y, enemyX, enemyY);
            if (dist < minDist) {
                minDist = dist;
                curTarget = enemy;
            }
        });

        this.target = curTarget;
        this.getData('unit')['range'] = 1;
        this.findPathTo(this.target);
    }

    attack(target) {
        if (this.isRegenerationNeeded() && target.state == 'dead') {
            this.eat();
        } else {
            super.attack(target);
        }
    }

    isRegenerationNeeded() {
        const unit = this.getData('unit');
        return unit['health'] / unit['max_health'] < 0.3;
    }

    eat() {
        this.playAnimation('eat');
        const unit = this.getData('unit');
        unit['health'] += 50;

        this.HPBar.update(unit['health'], unit['max_health']);
        if (this.linkedIcon) {
            this.linkedIcon.updateContent(unit);
        }
    }

    extraPathConditions() {
        return this.isRegenerationNeeded();
    }
}
import InfoTables from "../../../scenes/ui/InfoTables";
import MapObject from "./MapObject";
import apiClient from "../../../services/apiClient";
import AudioManager from "../../../managers/AudioManager";
import OpenBarrierTable from "../link_tables/OpenBarrierTable";

export default class Barrier extends MapObject {
    constructor(scene, x, y, children, texture, width, height, data) {
        super(scene, x, y, children, texture, width, height, data);
    }

    create() {
        this.type = 'barrier';
        super.create();
    }

    async checkAvailable() {
        const data = await apiClient.buildings.checkAvailable({
            scene: this.scene.scene.key,
            name: this.texture
        });

        if (data['status'] != 'success') {
            return;
        }
        return data['available'];
    }

    pointerOver() {
        super.pointerOver();
        this.link.hide();
        if (!this.tableFix) {
            this.link.show();
        }
    }

    async click() {
        const isAvailable = await this.checkAvailable();

        if (!isAvailable) {
            InfoTables.showWarningText('Уничтожьте врагов на пути к строению');
            return;
        }

        super.click();
        this.link.setAlpha(0.001);
        this.scene.game.registry.set('curBuilding', this);

        switch (this.state) {
            case 'update':
                InfoTables.showWarningText('Будет доступно в одном из обновлений');
                break;

            case 'closed':
                if (this.tableFix) {
                    this.destroyFixTable();
                } else {
                    this.openFixTable();
                }

                this.scene.input.on('pointerdown', () => {
                    if (this.tableFix) this.destroyFixTable();
                });
                break;

            case 'opened':
                InfoTables.openInfoTable(this.texture);
                AudioManager.playSound('click', 'building');
                break;
        }
    }

    openFixTable() {
        this.scene.map.add(this.tableFix = new OpenBarrierTable(this.scene, this.x + this.link.x, this.y + this.link.y, []));
    }

    destroyFixTable() {
        this.tableFix && this.tableFix.destroy();
        this.tableFix = null;
        this.scene.map.handleMoves();
    }

    upgrade() {
        this.setState('opened');
        this.setLevel(++this.level);
        this.updateInfo(this.level);
        this.deleteBuilder();
    }

    async updateInfo(data) {
        this.setState(data['opened'] ? 'opened' : 'closed');
    }
}
import containerData from "../../../../../../config/shop/containers";
import TimeManager from "../../../../../../managers/TimeManager";
import UIController from "../../../../../../managers/UIController";
import InfoTables from "../../../../../../scenes/ui/InfoTables";
import { updateUI } from "../../../../../../scenes/ui/UI";
import apiClient from "../../../../../../services/apiClient";
import { HOUR } from "../../../../../../utils/const";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN } from "../../../../../../utils/textStyles";
import Button from "../../../../../main/buttons/common/Button";

export default class ContainerCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, width, height, table, type) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.width = width;
        this.height = height;

        this.table = table;
        this.type = type;

        this.create(width, height);
    }

    create(w, h) {
        const ratio = (this.type == 'hack') ? -1 : 1;

        this.add(this.containerIcon = this.scene.add.image(w * 0.022 * ratio, 0, 'container_icons', 'container_batteries_1.png')
            .setDisplaySize(h * 0.25, h * 0.25).setOrigin(0.5, 0.5).setTintFill(0x000000).setAlpha(0.7));

        // Кнопка "Открыть (взломать)"
        this.add(this.btnCommit = new Button(this.scene, w * 0.178 * ratio, h * 0.045, [], 'shop_button', h * 0.225, h * 0.225 * 0.216, 'green'));
        this.btnCommit.disable();
        // Кнопка "Забрать награду"
        this.add(this.btnClaim = new Button(this.scene, w * 0.189 * ratio, h * 0.223, [], 'shop_button', h * 0.205, h * 0.205 * 0.216, 'green'));
        this.btnClaim.disable();

        this.add(this.keyIcon = this.scene.add.image(w * 0.14 * ratio, h * -0.02, 'items_icons', 'key_container_batteries.png')
            .setDisplaySize(h * 0.05, h * 0.05).setOrigin(0.5, 0.5));
        this.add(this.keyAmount = this.scene.add.text(w * 0.165 * ratio, 0, '').setOrigin(0.5, 1).setFontSize(h * 0.025));

        this.add(this.timer = this.scene.add.text(w * 0.18 * ratio, 0, '').setOrigin(1, 1)
            .setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.025));

        this.keyIcon.setAlpha(0);
        this.hideAwards();
    }

    previewContainer(container) {
        this.containerIcon.setFrame(container.getData('name') + '.png');
        this.enable(container);
    }

    enable(container) {
        this.containerIcon.setInteractive();
        this.containerIcon.input.cursor = 'url(assets/UI/ui/cursors/pointer.png), pointer';

        this.containerIcon.on('pointerover', () => {
            if (this.isPlaced) this.containerIcon.setTint(0x888888);
            else this.containerIcon.alpha = 0.8;
        });
        this.containerIcon.on('pointerout', () => {
            if (this.isPlaced) this.containerIcon.clearTint();
            else this.containerIcon.alpha = 0.7;
        });
        this.containerIcon.off('pointerup').on('pointerup', () => {
            if (this.isPlaced && this.curContainer) {
                this.removeContainer(this.curContainer);
            } else {
                this.addContainer(container);
                // this.enable();
            }
        });
    }

    disable() {
        this.containerIcon.disableInteractive();
    }

    addContainer(container) {
        this.curContainer = container;
        const name = container.getData('name');
        this.curContainerName = name;
        this.containerIcon.setFrame(name + '.png');

        container.deselect();
        let amount = container.getData('amount');
        container.updateAmount(--amount);

        this.isPlaced = true;

        this.containerIcon.clearTint().setAlpha(1);

        if (this.type == 'open') {
            this.displayKeys(name);
            this.btnCommit.makeInteractive();
            this.btnCommit.blink();
        } else {
            this.parentContainer.updateText('hack');
        }
        this.showContent(name);

        this.emit('addcontainer');
    }

    removeContainer(container) {
        let amount = container.getData('amount')
        container && container.updateAmount(++amount);

        this.isPlaced = false;
        this.containerIcon.setTintFill(0x000000).setAlpha(0.7);
        this.disable();
        this.btnCommit.disable();

        this.keyIcon.setAlpha(0.001);
        this.keyAmount.setText('');

        (this.type == 'hack') && this.removeHackTool(this.hackTool);
        this.timer.setText('');
        this.countdown && this.countdown.remove();
        this.hideAwards();

        this.parentContainer.updateText();
    }

    displayKeys(name) {
        const type = name.slice(-1);
        this.keyIcon.setAlpha(1);
        this.keyIcon.setFrame('key_' + name.slice(0, -2) + '.png');
        this.keyAmount.setText(type);

        const items = this.scene.game.registry.get('items');
        UIController.updateItemsText(items, { ['key_' + name.slice(0, -2)]: +type }, [this.keyAmount]);
    }

    selectHackTool(icon) {
        this.hackToolSelected = true;
        this.hackTool = icon;
        const toolName = icon.getData('name');

        const type = this.curContainer.getData('name').slice(-1);
        let amount = icon.getData('amount');
        icon.updateAmount(amount -= +type);

        this.keyAmount.setText(type);
        const items = this.scene.game.registry.get('items');

        if (items[toolName] >= type) {
            this.btnCommit.makeInteractive();
            this.btnCommit.blink();
        }
        UIController.updateItemsText(items, { [toolName]: type }, [this.keyAmount]);

        const time = TimeManager.formatFullTime(this.getHackInfo(toolName)['time']);
        this.timer.setText(time);

        this.keyIcon.setAlpha(1).clearTint().setFrame(toolName + '.png');
        this.keyIcon.setInteractive();
        this.keyIcon.input.cursor = 'url(assets/UI/ui/cursors/pointer.png), pointer';

        this.keyIcon.on('pointerover', () => {
            this.keyIcon.setTint(0x888888);
        });
        this.keyIcon.on('pointerout', () => {
            this.keyIcon.clearTint();
        });
        this.keyIcon.off('pointerup').on('pointerup', () => {
            this.scene.input.off('pointerup');
            this.scene.input.off('pointermove');
            this.removeHackTool(icon);
        });
    }

    removeHackTool(icon) {
        this.hackToolSelected = false;
        this.keyIcon.disableInteractive().setAlpha(0.001);

        if (this.hackTool) {
            const type = this.curContainer.getData('name').slice(-1);
            let amount = icon.getData('amount');
            !this.isHacking && icon.updateAmount(amount += +type);
        }

        this.keyAmount.setText('');
        this.timer.setText('');
        this.btnCommit.disable();
    }

    showContent(containerName) {
        const items = containerData[containerName]['rewards'];
        this.showAwards(items);

        this.btnCommit.off('click').on('click', () => this.commit(containerName));
    }

    showAwards(items) {
        this.awards = items;

        const w = this.width;
        const h = this.height;
        const Xorigin = (this.type == 'hack') ? w * -0.2 : w * -0.06;
        let dist = 0;

        items.forEach(item => {
            let texture = item['name'].includes('soldier_module') ? 'soldier_module_icons' : 'items_icons';

            let icon = this.scene.add.sprite(Xorigin + dist, h * 0.16, texture, `${item['name']}.png`)
                .setDisplaySize(h * 0.045, h * 0.045).setOrigin(1.1, 0.5);
            let text = this.scene.add.text(Xorigin + dist, h * 0.16, '-').setOrigin(0, 0.5)
                .setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.025);

            this.resIcons.push(icon);
            this.resText.push(text);

            dist += w * 0.055;
        });

        this.add(this.resIcons);
        this.add(this.resText);
    }

    hideAwards() {
        if (this.resText) {
            this.resIcons.forEach(i => i.destroy());
            this.resText.forEach(t => t.destroy());
        }
        this.resIcons = [];
        this.resText = [];
    }

    async commit(containerName) {
        if (this.type == 'open') {
            const data = await apiClient.containers.open({ name: containerName });

            if (data['status'] !== 'success') {
                InfoTables.showWarningText(data['message']);
                return;
            }

            if (data['message'] == '300') {
                InfoTables.showWarningText('Не достаточно ключей');
                return;
            }

            this.update(data);
            this.openContainer(containerName, data['rewards']);

        } else {
            if (!this.hackToolSelected) {
                InfoTables.showWarningText('Выберите набор для взлома');
                return;
            }

            const data = await apiClient.processes.start({
                category: 'containerHacking',
                name: containerName,
                tool: this.hackTool.getData('name')
            });

            if (data['msg'] == '200') {
                this.startHacking(data['data'], containerName);
            } else {
                InfoTables.showWarningText(data['msg']);
            }
        }
    }

    getHackInfo(tool) {
        let time, prob;

        switch (tool) {
            case 'hack_tool_1':
                time = HOUR;
                prob = 0.3;
                break;
            case 'hack_tool_2':
                time = HOUR * 2;
                prob = 0.5;
                break;
            case 'hack_tool_3':
                time = HOUR * 3;
                prob = 0.7;
                break;
        }

        return { time: time, prob: prob };
    }

    openContainer(containerName, rewards) {
        this.disable();
        this.containerIcon.setFrame(containerName + '_opened.png');

        this.isPlaced = true;
        this.isOpened = true;
        this.isHacking = false;

        this.keyIcon.setAlpha(0.001);
        this.keyAmount.setText('');
        this.btnCommit.disable();

        this.btnClaim.makeInteractive();
        this.btnClaim.blink();

        this.revealRewards(rewards);
        this.btnClaim.off('click').on('click', this.claimRewards.bind(this));
    }

    revealRewards(rewards) {
        rewards.forEach((reward, index) => {
            this.resText[index].setText(reward['reward_quantity']).setStyle(TEXT_STYLE_GREEN).setFontSize(this.height * 0.02);
        });
    }

    async claimRewards() {
        const containerName = this.curContainerName;

        const data = await apiClient.containers.collectRewards({
            name: containerName,
            action: this.type
        });

        if (data['status'] !== 'success') {
            return;
        }

        InfoTables.showWarningText(data['message']);
        this.update(data);
        this.clear();
    }

    clear() {
        this.containerIcon.setFrame('container_batteries_1.png').setTintFill(0x000000).setAlpha(0.7);
        this.containerIcon.disableInteractive();
        this.hideAwards();
        this.timer.setText('');
        this.btnClaim.disable();
        this.isPlaced = false;
        this.isOpened = false;

        // this.removeHackTool(this.hackTool);
        this.keyIcon.setAlpha(0);
        this.keyAmount.setText('');
    }

    update(data) {
        this.scene.game.registry.set('items', data['items']);
        this.parentContainer.updateScrolls(data['items']);
        updateUI(this.scene);
    }

    async updateInfo() {
        const data = await apiClient.containers.getRewards({ action: this.type });

        if (data['status'] !== 'success') {
            return;
        }

        const rewards = data['rewards'];

        if (rewards.length > 0) {
            this.clear();
            this.containerIcon.clearTint().setAlpha(1);
            this.showAwards(rewards);
            this.openContainer(rewards[0]['container_type'], rewards);
        }
    }

    startHacking(data, containerName) {
        this.clear();
        this.curContainerName = containerName;
        this.containerIcon.clearTint().setAlpha(1).setFrame(containerName + '.png');
        this.keyIcon.setAlpha(1).setTexture('UI', 'lock.png').setDisplaySize(this.height * 0.03, this.height * 0.03).disableInteractive();
        const rewards = containerData[containerName]['rewards'];
        this.showAwards(rewards);

        this.isHacking = true;

        this.btnCommit.disable();
        this.timer.setStyle(TEXT_STYLE_GREEN);

        TimeManager.createTimer(this.scene, this.curContainerName, data['duration'],
            this.finishHacking.bind(this),
            (remainingTime) => {
                this.timer.setText(TimeManager.formatFullTime(remainingTime));
            }
        );
    }

    async finishHacking() {
        this.isHacking = false;
        const data = await apiClient.processes.finish({
            category: 'containerHacking',
            name: this.curContainerName
        });

        console.log(data);
        this.keyIcon.setAlpha(0).setTexture('items_icons').setDisplaySize(this.height * 0.05, this.height * 0.05);
        this.hackToolSelected = false;
        this.updateInfo();
    }

    checkProcessExists(processes) {
        let process = null, containerName = '';

        for (let key in processes) {
            if (processes[key]['category'] == 'containerHacking') {
                process = processes[key];
                containerName = key;
            }
        }

        if (process) {
            if (process['finished'] == true) {
                console.log('process finished by update');
                this.updateInfo();
            } else {
                this.startHacking(process, containerName);
            }
            this.isPlaced = true;
        }
    }
}
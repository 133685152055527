const machineData = {
    "builder": {
        name: 'Строитель',
        fix_materials: {
            battery_tech: 5
        }
    },
    "crystall_collector": {
        name: 'Сборщик кристаллов',
        fix_materials: {
            battery_tech: 5
        }
    },
    "miner": {
        name: 'Шахтер',
        fix_materials: {
            battery_tech: 5
        }
    },
    "truck": {
        name: 'Погрузчик',
        fix_materials: {
            battery_tech: 5
        }
    },
}

export default machineData;

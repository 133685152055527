import ArmyStatsTable from '../tables/army/ArmyStatsTable.js';
import ArmyTable from '../tables/army/ArmyTable.js';
import LookoutTable from '../tables/army/LookoutTable.js';
import InfoTableContainer from './common/InfoTableContainer.js';

export default class ArmyTableContainer extends InfoTableContainer {

    addInfoTables() {
        this.tableLeft = new LookoutTable(this.scene, 0, 0, [], this.w, this.h, 'army_stats');
        this.tableCenter = new ArmyTable(this.scene, 0, 0, [], this.w, this.h, 'army');
        this.tableRight = new ArmyStatsTable(this.scene, 0, 0, [], this.w, this.h, 'army_stats');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}

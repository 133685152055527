import BuildingTable from "../buildings/BuildingTable.js";
import apiClient from "../../../../../services/apiClient.js";
import ProductionContainer from "./ProductionContainer.js";


export default class BuildingProductionTable extends BuildingTable {

    async update(category) {
        super.update(category);

        this.prodData = await apiClient.production.getData({ buildingName: category });

        if (!this.prodContainer) {
            this.add(this.prodContainer = new ProductionContainer(this.scene, 0, 0, [], this.width, this.height, this.prodData));
        }
        this.prodContainer.update(this.prodData);
    }
}
import BuildingTable from "../buildings/BuildingTable.js";
import apiClient from "../../../../../services/apiClient.js";
import BuildingConstructionContainer from "./comCenter/BuildingConstructionContainer.js";


export default class ComCenterTable extends BuildingTable {

    async update(category) {
        super.update(category);

        const data = await apiClient.buildings.getConstruction();

        if (data['status'] != 'success') {
            return;
        }

        this.constructionData = data['construction'];

        if (!this.constructionContainer) {
            this.add(this.constructionContainer = new BuildingConstructionContainer(this.scene, 0, 0, [], this.width, this.height, this.constructionData));
        }
        this.constructionContainer.update(this.constructionData);
    }
}
import { TEXT_STYLE_BLUE } from "../../../../../utils/textStyles";
import SingleInfoTable from "../common/SingleInfoTable";

export default class TaskTable extends SingleInfoTable {

    create() {
        super.create();

        this.tasks = [];
        this.addPlaceholder();
        this.checkTasks();
    }

    checkTasks() {
        if (this.tasks.length == 0) {
            this.showPlaceholder();
        }
    }

    addPlaceholder() {
        const txt = 'На данный момент нет доступных заданий, но скоро они появятся.';

        this.add(this.placeholder = this.scene.add.text(0, 0, txt).setOrigin(0.5, 0.5)
            .setStyle(TEXT_STYLE_BLUE).setFontSize(this.height * 0.03).setVisible(false));
    }

    showPlaceholder() {
        this.placeholder.setVisible(true);
    }
}
import { TEXT_STYLE_ORANGE } from "../../../../../utils/textStyles";
import SingleInfoTable from "../common/SingleInfoTable";

export default class PortalTable extends SingleInfoTable {

    create() {
        super.create();
        const txt = 'Соберите 4 уникальных камня, чтобы открыть портал.';
        this.add(this.description = this.scene.add.text(0, this.height * 0.28, txt)
            .setOrigin(0.5, 1).setStyle(TEXT_STYLE_ORANGE).setFontSize(this.height * 0.025));
    }
}
import bridge from "@vkontakte/vk-bridge";
import InfoTables from "../../../../../../scenes/ui/InfoTables";
import { updateUI } from "../../../../../../scenes/ui/UI";
import apiClient from "../../../../../../services/apiClient";
import { TEXT_STYLE_ORANGE } from "../../../../../../utils/textStyles";
import ActionButton from "../../../../../main/buttons/common/ActionButton";

export default class ShopCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th, data) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.tw = tw;
        this.th = th;
        this.addItemData(data);
    }

    addItemData(data) {
        this.setData('data', data);
        this.create(this.tw, this.th);
    }

    linkTable(table) {
        this.table = table;
    }

    create(w, h) {
        const data = this.getData('data');
        const name = data['item'];

        this.add(this.cell = this.scene.add.image(0, 0, 'shop_cell').setDisplaySize(h * 0.17 * 2.23, h * 0.17));

        const cw = this.cell.displayWidth;
        const ch = this.cell.displayHeight;

        this.add(this.button = new ActionButton(this.scene, cw * 0.238, ch * 0.127, [], 'shop_button', ch * 1.17, ch * 1.17 * 0.216, 'green', this.buy.bind(this)));
        this.add(this.butText = this.scene.add.text(cw * 0.238, ch * 0.127, '-').setOrigin(0.5, 0.5).setStyle({
            fontFamily: 'Game_Font',
            fontSize: ch * 0.13,
            color: '#8fff89',
            shadow: { fill: true, offsetX: 0, offsetY: 2, blur: 1 }
        }));

        let texture = 'items_icons';

        const isContainer = name.includes('container') && !name.includes('key_container');
        isContainer && (texture = 'container_icons');
        const size = isContainer ? ch * 0.8 : ch * 0.5;

        const isModule = name.includes('soldier_module');
        isModule && (texture = 'soldier_module_icons');

        this.add(this.shineFX = this.scene.add.image(cw * -0.22, ch * -0.05, 'shine').setOrigin(0.5, 0.5).setDisplaySize(ch * 0.75, ch * 0.75));
        this.add(this.itemIcon = this.scene.add.image(cw * -0.22, ch * -0.05, texture, `${name}.png`).setDisplaySize(size, size));

        if ((data['currency'] == 'morion') && !isContainer) {
            this.add(this.itemAmount = this.scene.add.text(cw * -0.17, ch * -0.03, data['amount']).setOrigin(0, 0).setStyle(TEXT_STYLE_ORANGE).setFontSize(ch * 0.15));
        }
        
        this.add(this.miniIcon = this.scene.add.image(cw * 0.07, ch * -0.2, 'items_icons', 'morion.png'));
        this.add(this.amountText = this.scene.add.text(cw * 0.15, ch * -0.1, data['amount']).setOrigin(0, 1).setStyle(TEXT_STYLE_ORANGE).setFontSize(ch * 0.13));

        this.setSize(cw, ch);
        this.updateContent(data);
    }

    updateContent(data) {
        const ch = this.cell.displayHeight;
        const name = data['item'];
        const cost = data['cost'];
        let text = 'Купить';

        if (data['currency'] == 'vote') {
            text = `${cost} голос`;
            if (cost % 10 != 1) {
                text += [2, 3, 4].includes(cost % 10) ? 'а' : 'ов';
            }
        }

        this.butText.setText(text);

        let texture = 'items_icons';

        const isContainer = name.includes('container') && !name.includes('key_container');
        const isModule = name.includes('soldier_module');
        isModule && (texture = 'soldier_module_icons');

        const frame = (data['currency'] == 'morion' || isContainer) ? 'morion' : name;
        this.miniIcon.setTexture(texture).setFrame(`${frame}.png`).setDisplaySize(ch * 0.23, ch * 0.23);

        this.amountText.setText((data['currency'] == 'morion') ? cost : data['amount']);
    }

    async buy() {
        const data = this.getData('data');

        if (data['currency'] == 'vote') {
            this.purchase(data);
            return;
        } 

        InfoTables.openConfirmationTable({
            title: 'Магазин',
            text: `Купить контейнер за ${data['cost']} мориона?`,
            button: 'Купить'
        }, this.buyForMorion.bind(this));
    }

    async buyForMorion() {
        const data = this.getData('data');

        const response = await apiClient.shop.buyForMorion({
            name: data['item'],
            amount: data['amount']
        });

        if (response['status'] != 'success') {
            return;
        }

        InfoTables.showWarningText(response['message']);
        this.scene.game.registry.set('items', response['items']);
        updateUI(this.scene);

        if (response['message'] == 'Не достаточно ресурсов') {
            return;
        }

        this.table.updateInfo(response['data']);
    }

    purchase(data) {
        const scene = this.scene;
        const gameData = scene.game.registry;
        const table = this.table;

        bridge.send('VKWebAppShowOrderBox', { 
            type: 'item',
            item: data['item'] + '_' + data['amount']
        })
        .then((response) => {
            if (response.success) {
                InfoTables.showWarningText('Ресурсы добавлены, спасибо за покупку!');
                gameData.set('items', response['items']);
                updateUI(scene);
                table.updateInfo(response['data']);
            } else {
                InfoTables.showWarningText('Возникли ошибки при покупке');
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
}
import CharacterTable from '../tables/units/characters/CharacterTable.js';
import BuildingTableContainer from './common/BuildingTableContainer.js';
import ComCenterTable from '../tables/buildings/ComCenterTable.js';

export default class ComCenterTableContainer extends BuildingTableContainer {

    addInfoTables() {
        this.tableLeft = null;
        // this.tableLeft = new TableSkillsMainHero(this.scene, 0, 0, [], this.w, this.h, 'main_hero_skills');
        this.tableCenter = new CharacterTable(this.scene, 0, 0, [], this.w, this.h, 'main_hero');
        this.tableRight = new ComCenterTable(this.scene, 0, 0, [], this.w, this.h, 'com_center');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}
